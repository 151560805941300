import React from "react"
import {Checkbox} from "antd"
import {isBoolean} from "lodash"
import {withTranslation} from "react-i18next"
import {Model} from "Model"
import {Icon} from "components"
import {handleError, checkPermission, toastError, toastWarning} from "helpers"
import {missedAttendanceSettingService, profileService} from "services"
import {KlassappTableHOC} from "HOC"
import {KlassappTable, KlassappTableHeader, KlassappTableDropdown} from "uiKit"
import {BaseButton, SecondaryButton, AddItemCircleButton} from "components/buttons"
import {KlassappTableProps} from "types/common"
import {BaseDepartmentId} from "types/departments"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions, PermissionUserType} from "types/permission"
import {getPermissionsByDepartment} from "sections/Settings/helper"
import styles from "./MissedAttendance.module.css"

type PageProps = {
    t: Function
    model: Model
}

type Props = KlassappTableProps & PageProps

type State = {
    newItemData: any
    oldItemData: any
    isSubmitting: boolean
    permissions: PermissionUserType
    roles: any[]
}

class MissedAttendanceTab extends React.Component<Props, State> {
    state = {
        newItemData: null,
        oldItemData: null,
        isSubmitting: false,
        permissions: {staff: []},
        roles: []
    }

    componentDidMount() {
        this.getPermissions()
        this.getRoles()
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getSettings},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.getSettings()
    }

    getPermissions = () => {
        const permissions = getPermissionsByDepartment(BaseDepartmentId.Academics)
        this.setState({permissions})
    }

    getPageTitle = () => {
        return this.props.t("settings.missedAttendance")
    }

    getFields = () => {
        const {t} = this.props
        return [t("settings.role"), t("settings.isFirstAssigned")]
    }

    getTableHeaderActions = (isShowDuplicateBtn = false, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const hasLockedItem = (checkedData || []).some((item) => item.isLock)
        const {t, onClickShowConfirmModal} = this.props
        const actions = []
        if (!hasLockedItem) {
            actions.push({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            })
        }
        return actions
    }

    canShowDeleteIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && !data.isLock
    }

    canShowEditIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getRoles = async () => {
        try {
            const data = await profileService.getRoles()
            const roles = data.map((v) => ({
                id: v.roleId,
                name: v.name
            }))
            this.setState({roles})
        } catch (e) {
            handleError(e)
        }
    }

    getSettings = async () => {
        this.props.dispatch({isLoading: true})
        try {
            const {data, total} = await missedAttendanceSettingService.getAll()
            const newData = data.map((item) => {
                item.id = item.roleId
                item.isFirstAssigned = !!item.isFirstAssigned
                item.roleHtml = this.renderRoleHtml(item)
                item.isFirstAssignedHtml = this.renderIsFirstAssignedHtml(item)
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    renderRoleHtml = (item) => {
        return (
            <div className={styles.titleWrap}>
                {item.isLock ? (
                    <div className={styles.lockIcon}>
                        <Icon icon="LOCK_CHECKMARK" />
                    </div>
                ) : (
                    ""
                )}
                <span className={styles.title}>{item.role}</span>
            </div>
        )
    }

    renderIsFirstAssignedHtml = (item = null) => {
        if (item) {
            return (
                <div key={`show-${item.roleId}`} className={styles.visibleCheckboxWrap}>
                    <Checkbox checked={item.isFirstAssigned} />
                </div>
            )
        }
        const newItemData = this.state.newItemData || {}
        return (
            <div key={`edit-${newItemData.roleId}`} className={styles.visibleCheckboxWrap}>
                <Checkbox
                    checked={newItemData.isFirstAssigned}
                    onChange={(event) => this.onChangeNewItemData("isFirstAssigned", event.target.checked)}
                />
            </div>
        )
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {
                title: t("settings.role"),
                field: "roleHtml"
            },
            {
                title: t("settings.isFirstAssigned"),
                field: "isFirstAssignedHtml"
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.roleId)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await missedAttendanceSettingService.delete(payload)
            await this.getSettings()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const {newItemData} = this.state
        if (newItemData) {
            toastWarning(this.props.t("common:validation.saveBeforeEdit"))
            return
        }
        this.setState(
            {
                newItemData: {...editItem},
                oldItemData: {...editItem}
            },
            () => {
                const newData = this.props.data.map((item) => {
                    if (item.roleId === editItem.roleId) {
                        item.roleHtml = this.renderRoleInput()
                        item.isFirstAssignedHtml = this.renderIsFirstAssignedHtml()
                        return item
                    }
                    return item
                })
                this.props.dispatch({data: newData})
            }
        )
    }

    onClickDelete = async (deletedItem) => {
        if (deletedItem.isForm) {
            this.setState({newItemData: null, oldItemData: null})
            await this.getSettings()
            return
        }
        try {
            this.props.dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await missedAttendanceSettingService.delete([deletedItem.roleId])
            await this.getSettings()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onChangeNewItemData = (key: string, value: any) => {
        const {newItemData} = this.state
        newItemData[key] = value
        const newData = this.props.data.map((item) => {
            if (item.id === newItemData.id) {
                if (key === "roleId") {
                    newItemData.roleId = newItemData[key]
                    item.roleHtml = this.renderRoleInput()
                }
                if (key === "isFirstAssigned") {
                    item.isFirstAssignedHtml = this.renderIsFirstAssignedHtml()
                }
                return item
            }
            return item
        })
        this.setState({newItemData})
        this.props.dispatch({data: newData})
    }

    renderRoleInput = () => {
        const newItemData = this.state.newItemData || {}
        const roles = this.state.roles || []
        return (
            <KlassappTableDropdown
                options={roles}
                value={roles.find((v) => v.id === newItemData.roleId)}
                getOptionLabel={(option) => <p style={{marginTop: "14px"}}>{option.name}</p>}
                onChange={(value) => this.onChangeNewItemData("roleId", value.id ? value.id : value)}
                placeholder="Select Role"
            />
        )
    }

    onClickAddItem = () => {
        const newItem = {
            id: 0,
            roleId: 0,
            roleHtml: this.renderRoleInput(),
            isFirstAssigned: false,
            isFirstAssignedHtml: this.renderIsFirstAssignedHtml(),
            isForm: true
        }
        const {data} = this.props
        data.push(newItem)
        this.setState({newItemData: newItem, oldItemData: null})
        this.props.dispatch({data})
    }

    validateDataBeforeSubmit = () => {
        const {roleId, isFirstAssigned} = this.state.newItemData
        const {t} = this.props
        if (!roleId) {
            toastError(
                t("common:validation.cantEmpty", {
                    field: t("settings.role")
                })
            )
            return false
        }
        if (!isBoolean(isFirstAssigned)) {
            toastError(
                t("common:validation.cantEmpty", {
                    field: t("settings.isFirstAssigned")
                })
            )
            return false
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        const {roleId, isFirstAssigned, isForm, id} = this.state.newItemData
        if (isForm) {
            const submitData = {
                roleId,
                isFirstAssigned
            }
            await this.createSetting(submitData)
        } else {
            const submitData = {roleId, isFirstAssigned}
            await this.updateSetting(id, submitData)
        }
        this.setState({newItemData: null, oldItemData: null}, () => {
            this.getSettings()
        })
    }

    createSetting = async (data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await missedAttendanceSettingService.create(data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    updateSetting = async (roleId, data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await missedAttendanceSettingService.update(roleId, data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    onClickCancel = () => {
        const {oldItemData} = this.state
        const newData = this.props.data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.roleId = oldItemData.roleId
                    item.isFirstAssigned = oldItemData.isFirstAssigned
                    item.roleHtml = this.renderRoleHtml(item)
                    item.isFirstAssignedHtml = this.renderIsFirstAssignedHtml(item)
                    return item
                }
                return item
            })
        this.setState({newItemData: null, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    render() {
        const {newItemData, isSubmitting} = this.state
        const {
            t,
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrapper}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <PermissionsRequired permissions={{staff: [Permissions.Staff.Settings.Modules.Academics.Edit]}}>
                    {!newItemData ? (
                        !isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />
                    ) : (
                        <div className={styles.buttonWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                onClick={this.onClickCancel}
                                className={styles.cancelBtn}
                            />
                            <BaseButton
                                title={t("common:action.save").toUpperCase()}
                                onClick={this.onClickSave}
                                loading={isSubmitting}
                            />
                        </div>
                    )}
                </PermissionsRequired>
            </div>
        )
    }
}

export const MissedAttendance = KlassappTableHOC(withTranslation(["academics", "common"])(MissedAttendanceTab))
