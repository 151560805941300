/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useState} from "react"
import {Col, Row} from "antd"
import {StatsChecklist, StatsChecklistTitle} from "sections/shared/stats"
import styles from "./Analytics.module.css"
import {BaseDepartmentId} from "types/departments"
import {AppliedFilter, FilterKey, FilterList} from "types/filter"
import {AcademicAffairsFilter} from "../../../AcademicAffairsFilter"
import {ActiveStudents, Attendance, Drop, Enroll, LeaveOfAbsence, SapActions, SapStatus, SevActions} from "./parts"
import {v4 as uuid} from "uuid"
import moment from "moment"

type Props = {
    departmentId: BaseDepartmentId
    goToStudentTab?: () => void
}

export function Analytics({departmentId, goToStudentTab}: Props) {
    const [loadKey, setLoadKey] = useState<string>()
    const [filters, setFilters] = useState<FilterList>({})

    const chartOptions = useMemo(
        () => ({
            tooltips: {
                enabled: true
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        gridLines: {
                            display: false
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            autoSkip: true,
                            autoSkipPadding: 4
                        }
                    }
                ]
            },
            plugins: {
                datalabels: {
                    display: false
                }
            }
        }),
        []
    )

    const sapChartOptions = useMemo(
        () => ({
            ...chartOptions,
            legend: {
                display: true
            }
        }),
        [chartOptions]
    )

    const onApplyFilter = ({filters: payload}: AppliedFilter) => {
        if (Object.keys(payload).length <= 0) {
            setFilters({})
            setLoadKey(uuid())
            return
        }
        const {profiles, campus, program, term, startDateRange, degreeLevel, onlySEV} = payload

        let filter: FilterList = {}
        if (onlySEV) filter.onlySEV = onlySEV
        if (profiles) {
            filter.profileIds = {
                operator: profiles.operator,
                value: profiles.value.map(({profileId}) => profileId)
            }
        }
        if (campus) {
            filter.campusIds = {
                operator: campus.operator,
                value: campus.value.map(({id}) => id)
            }
        }
        if (program) {
            filter.programIds = {
                operator: program.operator,
                value: program.value.map(({id}) => id)
            }
        }
        if (term) {
            filter.termIds = {
                operator: term.operator,
                value: term.value.map(({id}) => id)
            }
        }
        if (degreeLevel) {
            filter.degreeLevelIds = {
                operator: degreeLevel.operator,
                value: degreeLevel.value.map(({degreeLevelId}) => degreeLevelId)
            }
        }
        if (startDateRange) {
            filter.startDateRange = {
                operator: startDateRange.operator,
                value: Array.isArray(startDateRange.value)
                    ? startDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(startDateRange.value).format("YYYY-MM-DD")
            }
        }

        setFilters(filter)
        setLoadKey(uuid())
    }

    return (
        <div className={styles.container}>
            <AcademicAffairsFilter onApplyFilter={onApplyFilter} filterKey={FilterKey.AcademicsPage_Analytics} />
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <Attendance loadKey={loadKey} filters={filters} chartOptions={chartOptions} />
                </Col>
                <Col span={6}>
                    <SevActions loadKey={loadKey} goToStudentTab={goToStudentTab} filters={filters} />
                </Col>
                <Col span={6}>
                    <SapActions loadKey={loadKey} goToStudentTab={goToStudentTab} filters={filters} />
                </Col>
                <Col span={12}>
                    <LeaveOfAbsence
                        loadKey={loadKey}
                        filters={filters}
                        chartOptions={chartOptions}
                        goToStudentTab={goToStudentTab}
                    />
                </Col>
                <Col span={12}>
                    <ActiveStudents loadKey={loadKey} filters={filters} chartOptions={chartOptions} />
                </Col>
                <Col span={12}>
                    <Enroll loadKey={loadKey} filters={filters} chartOptions={chartOptions} />
                </Col>
                <Col span={12}>
                    <Drop
                        loadKey={loadKey}
                        filters={filters}
                        chartOptions={chartOptions}
                        goToStudentTab={goToStudentTab}
                    />
                </Col>
                <Col span={12}>
                    <SapStatus loadKey={loadKey} filters={filters} chartOptions={sapChartOptions} />
                </Col>
            </Row>
            {/* <SapCheckpoints /> */}
            <StatsChecklist title={StatsChecklistTitle.studentServiceChecklist} departmentId={departmentId} form={{}} />
        </div>
    )
}
