/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect} from "react"
import {useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {Link} from "react-router-dom"
import {get} from "lodash"
import {Icon} from "components/Icon"
import {Auth} from "types/auth"
import {Permissions} from "types/permission"
import {Settings} from "types/settings"
import {useCurrentProfilePermissions, useModel} from "hooks"
import {getFieldLabel} from "helpers"
import {Menu} from "./Menu"
import styles from "./SideBar.module.css"
import {routing} from "helpers/routing"
import {useHistory} from "react-router-dom"
import {FinancialAidStudentTabs} from "sections/FinancialAid/FinancialAidStudent/FinancialAidStudent"
import {RegistrarStudentTab} from "sections/academics/student/AcademicsStudent"
import {Branding} from "types/branding"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"

type MenuType = {
    id: string
    title: string
    icon?: string
    url?: string
    isSubTitle?: boolean
    childs?: MenuType[]
}

const ADMISSION_FINANCIAL_AID_SUB_NIVEL = ["/admissions/student", "/financial-aid/student"]

const ADMISSION_FINANCIAL_AID = ["/admissions/staff", "/financial-aid/staff", "/admissions/aptitude-test/"]

const STUDENT_SERVICES = ["/student-services/staff"]

const ACADEMIC_AFFAIR = ["/academic-affairs/staff"]

const STUDENT_ACCOUNT = ["/student-account/staff"]

const ACADEMICS_REGISTER_SUB_MENUS = [
    "/academics/registrar/staff",
    "/academics/registrar/student",
    "/academics/registrar/users",
    "/academics/registrar/term",
    "/academics/registrar/calendar"
]

function reducer(state, action) {
    return {...state, ...action}
}

export function SideBar(props) {
    const initialState = {
        isOpenChildMenus: false,
        parentMenu: {},
        menus: [],
        backMenu: null
    }

    const {isModuleEnable} = useEdularModulesContext()
    const [{isOpenChildMenus, parentMenu, menus, backMenu}, dispatch] = useReducer(reducer, initialState)
    const model = useModel()
    const location = useLocation()
    const currentProfilePermissions = useCurrentProfilePermissions()
    const {t} = useTranslation(["sidebar"])
    const {pathname} = location
    const locale = model.getLocale()
    const history = useHistory()

    useEffect(() => {
        getMenusByRole()
    }, [model.user, locale, model.myPermissions, pathname, isModuleEnable])

    const ModulesToDisplayTitle = [
        Modules.Admissions,
        Modules.FinancialAid,
        Modules.Academic,
        Modules.StudentServices,
        Modules.StudentAccount
    ]

    const getMenusByRole = () => {
        let menus: MenuType[] = []
        const profile = model.user.profiles.find((profile) => profile.id === model.profileId)
        const userProfileType = get(profile, "type")
        const profileId = get(profile, "id")
        const canShowSettings = currentProfilePermissions.hasPermissions({
            staff: [
                Permissions.Staff.Settings.General.Calendar.AccessStaffList.View,
                Permissions.Staff.Settings.General.Campus.View,
                Permissions.Staff.Settings.General.Courses.View,
                Permissions.Staff.Settings.General.CourseLevels.View,
                Permissions.Staff.Settings.General.DegreeLevel.View,
                Permissions.Staff.Settings.General.Department.View,
                Permissions.Staff.Settings.General.Groups.View,
                Permissions.Staff.Settings.General.Labels.View,
                Permissions.Staff.Settings.General.Majors.View,
                Permissions.Staff.Settings.General.ResourcesTypes.View,
                Permissions.Staff.Settings.General.Tags.View,
                Permissions.Staff.Settings.General.Terms.General.View,
                Permissions.Staff.Settings.General.Workflow.View,
                Permissions.Staff.Settings.General.UserSettings.View,
                Permissions.Staff.Settings.General.Statuses.View,
                Permissions.Staff.Settings.Modules.Academics.View,
                Permissions.Staff.Settings.Modules.Admissions.View,
                Permissions.Staff.Settings.Modules.CareerServices.View,
                Permissions.Staff.Settings.Modules.FinancialAid.View,
                Permissions.Staff.Settings.Modules.StudentAccount.View,
                Permissions.Staff.Settings.SystemSettings.AppStore.View,
                Permissions.Staff.Settings.SystemSettings.Branding.View,
                Permissions.Staff.Settings.SystemSettings.Media.View,
                Permissions.Staff.Settings.SystemSettings.EmailTemplates.View
            ]
        })
        const shouldItDisplayModulesTitle = ModulesToDisplayTitle.some((module) => isModuleEnable(module))
        const canShowSms =
            isModuleEnable(Modules.Sms) &&
            currentProfilePermissions.hasPermissions({
                staff: [Permissions.Staff.Settings.General.Sms.View]
            })
        const canShowReport =
            isModuleEnable(Modules.Reports) &&
            currentProfilePermissions.hasPermissions({
                staff: [
                    Permissions.Staff.Reports.AdminReports.ComplianceReports.View,
                    Permissions.Staff.Reports.AdminReports.ComplianceReports.View,
                    Permissions.Staff.Reports.AdminReports.GeneralReports.View,
                    Permissions.Staff.Reports.AdminReports.IpedsReports.View,
                    Permissions.Staff.Reports.Academics.General.View,
                    Permissions.Staff.Reports.Academics.ClassAttendance.View,
                    Permissions.Staff.Reports.Academics.ClassAttendance.PermissionsToAccessOthers.View,
                    Permissions.Staff.Reports.Admissions.General.View,
                    Permissions.Staff.Reports.FinancialAid.General.View,
                    Permissions.Staff.Reports.StudentAccounts.General.View,
                    Permissions.Staff.Reports.StudentServices.General.View
                ]
            })
        const canShowAcademicExternship =
            isModuleEnable(Modules.AcademicExternship) &&
            currentProfilePermissions.hasPermissions({staff: [Permissions.Staff.Academics.Externship.View]})
        const canShowAcademicInstructional = currentProfilePermissions.hasPermissions({
            staff: [
                Permissions.Staff.Academics.Director.View,
                Permissions.Staff.Academics.Externship.View,
                Permissions.Staff.Academics.Instructional.Students.AcademicsStudentDetails.View,
                Permissions.Staff.Academics.Instructional.Students.AcademicsStudentDetails.View,
                Permissions.Staff.Academics.Instructional.Students.Checklist.View,
                Permissions.Staff.Academics.Instructional.Students.PermissionsToAccessOthers.View,
                Permissions.Staff.Academics.Instructional.Lessons.Attendance.View,
                Permissions.Staff.Academics.Instructional.Lessons.Grades.View,
                Permissions.Staff.Academics.Instructional.AttendanceOverview.View,
                Permissions.Staff.Academics.Instructional.CourseGrades.View
            ]
        })
        const canShowAcademicRegistrar = currentProfilePermissions.hasPermissions({
            staff: [
                Permissions.Staff.Academics.Registrar.Workspace.View,
                Permissions.Staff.Academics.Registrar.Students.WorkspaceStudent.View,
                Permissions.Staff.Academics.Registrar.Students.TermsAndCourses.View,
                Permissions.Staff.Academics.Registrar.Students.AcademicPlanning.View,
                Permissions.Staff.Academics.Registrar.Students.DegreeAudit.View,
                Permissions.Staff.Academics.Registrar.Students.OfficialTranscript.View,
                Permissions.Staff.Academics.Registrar.Students.ProgressReport.View,
                Permissions.Staff.Academics.Registrar.Attendance.View
            ]
        })
        const canShowAcademicRetention = currentProfilePermissions.hasPermissions({
            staff: [Permissions.Staff.Academics.Registrar.Workspace.View]
        })
        const canShowAcademicLegacyTest =
            isModuleEnable(Modules.AcademicLegacyTest) &&
            currentProfilePermissions.hasPermissions({
                staff: [Permissions.Staff.Academics.Registrar.Workspace.View]
            })
        const canShowAcademicPractical = currentProfilePermissions.hasPermissions({
            staff: [
                Permissions.Staff.Academics.Practical.Students.View,
                Permissions.Staff.Academics.Practical.Submissions.View
            ]
        })
        const canShowAcademicDialer =
            isModuleEnable(Modules.AcademicDialer) &&
            currentProfilePermissions.hasPermissions({
                staff: [Permissions.Staff.Academics.Registrar.Workspace.View]
            })
        const canShowAcademic =
            isModuleEnable(Modules.Academic) &&
            (canShowAcademicExternship ||
                canShowAcademicInstructional ||
                canShowAcademicRegistrar ||
                canShowAcademicRetention ||
                canShowAcademicLegacyTest ||
                canShowAcademicPractical ||
                canShowAcademicDialer)

        const isProduction = process.env.REACT_APP_NODE_ENV === "production"

        switch (userProfileType) {
            case Auth.UserProfileType.Admin:
            case Auth.UserProfileType.Staff:
                menus = [
                    {id: "home", title: t("menu.Home"), icon: "HOME", url: "/"},
                    canShowAcademic && {
                        id: "academicsSection",
                        title: t("menu.Academics"),
                        icon: "ACADEMIC",
                        childs: [
                            canShowAcademicExternship && {
                                id: "externship",
                                title: t("menu.Externship"),
                                icon: "",
                                url: "/academics/externship/students"
                            },
                            canShowAcademicInstructional && {
                                id: "instructional",
                                title: t("menu.Instructional"),
                                icon: "",
                                url: "/academics/instructional"
                            },
                            canShowAcademicRegistrar && {
                                id: "registrar",
                                title: t("menu.registrar"),
                                icon: "",
                                url: "/academics/registrar/staff"
                            },
                            canShowAcademicRetention && {
                                id: "retention",
                                title: t("menu.Retention"),
                                icon: "",
                                url: "/retentions"
                            },
                            canShowAcademicLegacyTest && {
                                id: "legacyTest",
                                title: t("menu.legacyTest"),
                                icon: "",
                                url: "/academics/legacy-test"
                            },
                            canShowAcademicPractical && {
                                id: "practical",
                                title: t("menu.practical"),
                                icon: "",
                                url: "/academics/practical"
                            },
                            canShowAcademicDialer && {
                                id: "autoDialer",
                                title: t("menu.autoDialer"),
                                icon: "",
                                url: "/academics/auto-dialer"
                            }
                        ]
                    },

                    isModuleEnable(Modules.Admissions) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.Admissions.AdmissionsStaff.View]
                        }) && {
                            id: "admissionsSection",
                            title: t("menu.Admissions"),
                            icon: "DOOR_OPEN_1",
                            childs: [
                                {
                                    id: "admissions",
                                    title: t("menu.Admissions"),
                                    icon: "",
                                    url: "/admissions/staff"
                                },
                                isModuleEnable(Modules.AdmissionsAptitudeTest) && {
                                    id: "aptitudeTest",
                                    title: t("menu.aptitudeTest"),
                                    icon: "",
                                    url: "/admissions/aptitude-test"
                                },
                                isModuleEnable(Modules.AdmissionsDialer) && {
                                    id: "autoDialer",
                                    title: t("menu.autoDialer"),
                                    icon: "",
                                    url: "/admissions/auto-dialer"
                                },
                                isModuleEnable(Modules.Admissions) && {
                                    id: "leadBucket",
                                    title: t("menu.leadBucket"),
                                    icon: "",
                                    url: "/admissions/lead-bucket"
                                }
                            ]
                        },

                    isModuleEnable(Modules.FinancialAid) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View]
                        }) && {
                            id: "financialAidSection",
                            title: t("menu.Financial Aid"),
                            icon: "FIN_AID_FILL",
                            childs: [
                                {
                                    id: "financialAid",
                                    title: t("menu.Financial Aid"),
                                    icon: "",
                                    url: "/financial-aid/staff"
                                },
                                isModuleEnable(Modules.FinancialAidDialer) && {
                                    id: "autoDialer",
                                    title: t("menu.autoDialer"),
                                    icon: "",
                                    url: "/financial-aid/auto-dialer"
                                },
                                ["orion", "oi"].indexOf(model.slug) >= 0 && {
                                    id: "enrollmentRoster",
                                    title: t("menu.enrollmentRoster"),
                                    icon: "",
                                    url: "/financial-aid/nslds"
                                }
                            ]
                        },
                    isModuleEnable(Modules.StudentAccount) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.StudentAccount.Workspace.View]
                        }) && {
                            id: "studentAccountSection",
                            title: t("menu.Student Account"),
                            icon: "PERSON",
                            childs: [
                                {
                                    id: "studentAccount",
                                    title: t("menu.Student Account"),
                                    icon: "",
                                    url: "/student-account/staff"
                                },
                                isModuleEnable(Modules.StudentAccountDialer) && {
                                    id: "autoDialer",
                                    title: t("menu.autoDialer"),
                                    icon: "",
                                    url: "/student-account/auto-dialer"
                                }
                            ]
                        },
                    isModuleEnable(Modules.AcademicAffair) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.AcademicAffairs.StudentServices.Workspace.View]
                        }) && {
                            id: "academicAffairsSection",
                            title: t("menu.academicAffairs"),
                            icon: "CONTACT",
                            childs: [
                                {
                                    id: "academicAffairs",
                                    title: t("menu.academicAffairs"),
                                    icon: "",
                                    url: "/academic-affairs/staff"
                                },
                                canShowAcademicRetention && {
                                    id: "retention",
                                    title: t("menu.Retention"),
                                    icon: "",
                                    url: "/retentions"
                                },
                                isModuleEnable(Modules.AcademicAffairDialer) && {
                                    id: "autoDialer",
                                    title: t("menu.autoDialer"),
                                    icon: "",
                                    url: "/academic-affairs/auto-dialer"
                                }
                            ]
                        },
                    isModuleEnable(Modules.StudentServices) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Workspace.View]
                        }) && {
                            id: "studentServicesSection",
                            title: t("menu.Student Services"),
                            icon: "CONTACT",
                            childs: [
                                {
                                    id: "studentServices",
                                    title: t("menu.studentServices"),
                                    icon: "",
                                    url: "/student-services/staff"
                                },
                                isModuleEnable(Modules.StudentServicesDialer) && {
                                    id: "autoDialer",
                                    title: t("menu.autoDialer"),
                                    icon: "",
                                    url: "/student-services/auto-dialer"
                                }
                            ]
                        },
                    isModuleEnable(Modules.Academic) && {
                        id: "studentAttendance",
                        title: t("menu.studentAttendance"),
                        icon: "PERSON_DONE",
                        url: `/attendance`
                    },
                    isModuleEnable(Modules.CareerServices) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.CareerServices.View]
                        }) && {
                            id: "careerServicesSection",
                            title: t("menu.Career Services"),
                            icon: "PERSON",
                            childs: [
                                {
                                    id: "careerServices",
                                    title: t("menu.Career Services"),
                                    icon: "",
                                    url: "/career-services"
                                },
                                isModuleEnable(Modules.CareerServicesDialer) && {
                                    id: "autoDialer",
                                    title: t("menu.autoDialer"),
                                    icon: "",
                                    url: "/career-services/auto-dialer"
                                }
                            ]
                        },
                    {
                        id: "documents",
                        title: t("menu.documentHub"),
                        icon: "FILE_TEXT",
                        url: "/document-hub"
                    },
                    {
                        id: "divider",
                        title: "divider"
                    },
                    currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.Resources.MediaLibrary.General.View],
                        student: [Permissions.Student.Resources.MediaLibrary.View],
                        others: [Permissions.Others.Resources.MediaLibrary.View]
                    }) && {
                        id: "mediaLibrary",
                        title: t("menu.Media Library"),
                        icon: "OPEN_BOOK",
                        url: "/media-library"
                    },
                    currentProfilePermissions.hasPermissions({staff: [Permissions.Staff.Users.UsersMerge.Edit]}) && {
                        id: "userMerge",
                        title: t("menu.userMerge"),
                        icon: "PEOPLE",
                        url: "/user-merge"
                    },
                    canShowReport && {
                        id: "reports",
                        title: t("menu.reports"),
                        icon: "FILE_TEXT",
                        url: "/reports"
                    },
                    currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.Resources.Resources.View]
                    }) && {
                        id: "resources",
                        title: t("menu.Resources"),
                        icon: "BLUB",
                        url: "/resources"
                    },
                    // {id: "klasscards", title: "Klass Cards", icon: "CALENDAR_INPUT", url: "/klasscards"},
                    // {id: "isirCorrections", title: "ISIR Corrections", icon: "CALENDAR_INPUT", url: "/isir-corrections"},
                    // {id: "messages", title: "Messages", icon: "CALENDAR_INPUT", url: "/messages"},

                    // {
                    //     id: "development",
                    //     title: "Development",
                    //     icon: "INSTRUCTIONAL",
                    //     childs: [
                    //         {id: "palette", title: "Palette", icon: "INSTRUCTIONAL", url: "/development/palette"},
                    //         {id: "tickets", title: "Tickets", icon: "INSTRUCTIONAL", url: "/development/tickets"}
                    //     ]
                    // },
                    canShowSettings && {
                        id: "settings",
                        title: t("menu.Settings"),
                        icon: "SETTINGS_2",
                        childs: [
                            {id: "generalSubTitle", title: "General", isSubTitle: true},

                            isModuleEnable(Modules.Calendar) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                                }) && {
                                    id: "calendar",
                                    title: t("menu.Calendar"),
                                    icon: "",
                                    url: "/settings/calendar"
                                },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Campus.View]
                            }) && {
                                id: "campuses",
                                title: getFieldLabel(model, Settings.GeneralLabel.Campuses, t("menu.Campuses")),
                                icon: "",
                                url: "/settings/campuses"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Groups.View]
                            }) && {
                                id: "groups",
                                title: t("menu.groups"),
                                icon: "",
                                url: "/settings/groups"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Coa.View]
                            }) && {id: "coa", title: t("menu.COA"), icon: "", url: "/coa/templates"},
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Courses.View]
                            }) && {
                                id: "courses",
                                title: t("menu.Courses"),
                                icon: "",
                                url: "/settings/courses"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.CourseLevels.View]
                            }) && {
                                id: "courseLevel",
                                title: t("menu.courseLevels"),
                                icon: "",
                                url: "/settings/course-levels"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.DegreeLevel.View]
                            }) && {
                                id: "degreeLevels",
                                title: t("menu.Degree Level"),
                                icon: "",
                                url: "/settings/degree-level"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Department.View]
                            }) && {
                                id: "departments",
                                title: t("menu.Departments"),
                                icon: "",
                                url: "/settings/departments"
                            },
                            {
                                id: "documents",
                                title: t("menu.documents"),
                                icon: "",
                                url: "/settings/documents"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Majors.View]
                            }) && {
                                id: "majors",
                                title: getFieldLabel(model, Settings.GeneralLabel.Majors, t("menu.Majors")),
                                icon: "",
                                url: "/settings/majors"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.ResourcesTypes.View]
                            }) && {
                                id: "resources",
                                title: t("menu.ResourcesTypes"),
                                icon: "",
                                url: "/settings/resources-types"
                            },
                            {id: "sap", title: t("menu.Sap"), icon: "", url: "/settings/sap"},
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Statuses.View]
                            }) && {
                                id: "statuses",
                                title: t("menu.statuses"),
                                icon: "",
                                url: "/settings/student-statuses"
                            },
                            canShowSms && {
                                id: "sms",
                                title: t("menu.Sms"),
                                icon: "",
                                url: "/settings/sms"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Tags.View]
                            }) && {
                                id: "tags",
                                title: t("menu.tags"),
                                icon: "",
                                url: "/settings/tags"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Terms.General.View]
                            }) && {
                                id: "terms",
                                title: getFieldLabel(model, Settings.GeneralLabel.Terms, t("menu.Terms")),
                                icon: "",
                                url: "/settings/terms"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.Workflow.View]
                            }) && {
                                id: "workflow",
                                title: t("menu.Workflow"),
                                icon: "",
                                url: routing.settings.workflow.index()
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.UserSettings.View]
                            }) && {
                                id: "userSettings",
                                title: t("menu.userSettings"),
                                icon: "",
                                url: "/settings/user-settings"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.General.UserSettings.View]
                            }) && {
                                id: "university",
                                title: t("menu.externalInstitutions"),
                                icon: "",
                                url: "/settings/external-institutions"
                            },
                            isModuleEnable(Modules.AcademicExternship) && {
                                id: "externship",
                                title: t("menu.Externship"),
                                icon: "",
                                url: "/settings/externship"
                            },

                            shouldItDisplayModulesTitle && {
                                id: "modulesSubTitle",
                                title: t("menu.modules"),
                                isSubTitle: true
                            },
                            isModuleEnable(Modules.Admissions) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.Modules.Admissions.View]
                                }) && {
                                    id: "admissionsSettings",
                                    title: t("menu.Admissions"),
                                    icon: "",
                                    url: "/settings/admissions"
                                },
                            isModuleEnable(Modules.FinancialAid) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.Modules.FinancialAid.View]
                                }) && {
                                    id: "financialAidSettings",
                                    title: t("menu.Financial Aid"),
                                    icon: "",
                                    url: "/settings/financial-aid"
                                },
                            isModuleEnable(Modules.Academic) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                                }) && {
                                    id: "academicsSettings",
                                    title: t("menu.academicsAF"),
                                    icon: "",
                                    url: "/settings/academics"
                                },
                            isModuleEnable(Modules.CareerServices) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.Modules.CareerServices.View]
                                }) && {
                                    id: "careerServicesSettings",
                                    title: t("menu.Career Services"),
                                    icon: "",
                                    url: "/settings/career-services"
                                },
                            isModuleEnable(Modules.StudentServices) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                                }) && {
                                    id: "studentServicesSettings",
                                    title: t("menu.studentServices"),
                                    icon: "",
                                    url: "/settings/student-services"
                                },
                            isModuleEnable(Modules.StudentAccount) &&
                                currentProfilePermissions.hasPermissions({
                                    staff: [Permissions.Staff.Settings.Modules.StudentAccount.View]
                                }) && {
                                    id: "studentAccountSettings",
                                    title: t("menu.studentAccount"),
                                    icon: "",
                                    url: "/settings/student-account"
                                },
                            // {id: "student", title: t("menu.Student"), icon: "", url: "/settings/student-courses"},
                            {id: "SystemSubTitle", title: t("menu.systemSettings"), isSubTitle: true},
                            // currentProfilePermissions.hasPermissions({
                            //     staff: [Permissions.Staff.Settings.SystemSettings.AppStore.View]
                            // }) && {
                            //     id: "app-releases",
                            //     title: t("menu.App Releases"),
                            //     icon: "",
                            //     childs: [
                            //         {
                            //             id: "appReleaseANU",
                            //             title: `${college && college.toUpperCase()}`,
                            //             icon: "",
                            //             url: `/settings/app-releases/${college}`
                            //         },
                            //         {
                            //             id: "appReleaseEdular",
                            //             title: "Edular",
                            //             icon: "",
                            //             url: "/settings/app-releases/edular"
                            //         }
                            //     ]
                            // },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.SystemSettings.Branding.View]
                            }) && {
                                id: "branding",
                                title: t("menu.branding"),
                                url: "/settings/branding"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.SystemSettings.Media.View]
                            }) && {
                                id: "mediaPrivacy",
                                title: t("menu.mediaPrivacy"),
                                url: "/settings/media-privacy"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.View]
                            }) && {
                                id: "templates",
                                title: t("menu.templates"),
                                icon: "",
                                url: "/settings/templates"
                            },
                            currentProfilePermissions.hasPermissions({
                                staff: [Permissions.Staff.Settings.SystemSettings.Fields.View]
                            }) && {
                                id: "fields",
                                title: t("menu.fields"),
                                icon: "",
                                url: "/settings/fields"
                            },
                            {
                                id: "forDevelopers",
                                title: t("menu.forDevelopers"),
                                icon: "",
                                url: "/settings/for-developers"
                            }
                        ]
                    },
                    isModuleEnable(Modules.Tasks) && {
                        id: "tasks",
                        title: t("menu.Tasks"),
                        icon: "TASK_FILL",
                        url: "/tasks"
                    },
                    {id: "users", title: t("menu.Users"), icon: "PEOPLE", url: "/users"}
                ].filter((item) => item)
                break
            case Auth.UserProfileType.Student:
                menus = [
                    {id: "home", title: t("menu.Home"), icon: "HOME", url: "/"},

                    isModuleEnable(Modules.Academic) && {
                        id: "academicsSection",
                        title: t("menu.Academics"),
                        icon: "ACADEMIC",
                        childs: [
                            {
                                id: "instructional",
                                title: t("menu.Academics"),
                                icon: "",
                                url: `/academics/registrar/student/${profileId}?tab=${RegistrarStudentTab.Academic}`
                            },
                            {
                                id: "practical",
                                title: t("menu.practical"),
                                icon: "",
                                url: `/academics/practical/${profileId}`
                            }
                        ]
                    },
                    isModuleEnable(Modules.Admissions) &&
                        currentProfilePermissions.hasPermissions({
                            staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.View],
                            student: [Permissions.Student.Admissions.View],
                            others: [Permissions.Student.Admissions.View]
                        }) && {
                            id: "admissionsSection",
                            title: t("menu.Admissions"),
                            icon: "DOOR_OPEN_1",
                            childs: [
                                {
                                    id: "admissions",
                                    title: t("menu.Admissions"),
                                    icon: "",
                                    url: `/admissions/student/${profileId}`
                                }
                            ]
                        },
                    currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.View],
                        student: [Permissions.Student.FinancialAid.View],
                        others: [Permissions.Others.FinancialAid.View]
                    }) && {
                        id: "financialAidSection",
                        title: t("menu.Financial Aid"),
                        icon: "FIN_AID_FILL",
                        childs: [
                            {
                                id: "financialAid",
                                title: t("menu.Financial Aid"),
                                icon: "",
                                url: routing.finAid.student.index(profileId, FinancialAidStudentTabs.FinancialAid)
                            }
                        ]
                    },
                    isModuleEnable(Modules.StudentAccount) &&
                        currentProfilePermissions.hasPermissions({
                            student: [Permissions.Student.StudentAccount.StudentAccount.View]
                        }) && {
                            id: "studentAccountSection",
                            title: t("menu.Student Account"),
                            icon: "PERSON",
                            childs: [
                                {
                                    id: "studentAccount",
                                    title: t("menu.Student Account"),
                                    icon: "",
                                    url: `/student-account/student/${profileId}`
                                }
                            ]
                        },
                    isModuleEnable(Modules.StudentServices) && {
                        id: "studentServicesSection",
                        title: t("menu.Student Services"),
                        icon: "CONTACT",
                        childs: [
                            {
                                id: "studentServices",
                                title: t("menu.studentServices"),
                                icon: "",
                                url: `/student-services/student/${profileId}`
                            }
                        ]
                    },
                    isModuleEnable(Modules.CareerServices) &&
                        currentProfilePermissions.hasPermissions({
                            student: [Permissions.Student.CareerServices.View]
                        }) && {
                            id: "careerServicesSection",
                            title: t("menu.Career Services"),
                            icon: "PERSON",
                            childs: [
                                {
                                    id: "careerServices",
                                    title: t("menu.Career Services"),
                                    icon: "",
                                    url: `/career-services/student/${profileId}`
                                }
                            ]
                        },
                    {
                        id: "documents",
                        title: t("menu.documentHub"),
                        icon: "FILE_TEXT",
                        url: "/document-hub"
                    },
                    {
                        id: "divider",
                        title: "divider"
                    },
                    currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.Resources.MediaLibrary.General.View],
                        student: [Permissions.Student.Resources.MediaLibrary.View],
                        others: [Permissions.Others.Resources.MediaLibrary.View]
                    }) && {
                        id: "mediaLibrary",
                        title: t("menu.Media Library"),
                        icon: "OPEN_BOOK",
                        url: "/media-library"
                    },
                    currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.Resources.Resources.View]
                    }) && {
                        id: "resources",
                        title: t("menu.Resources"),
                        icon: "BLUB",
                        url: "/resources"
                    },
                    isModuleEnable(Modules.Tasks) && {
                        id: "tasks",
                        title: t("menu.Tasks"),
                        icon: "TASK_FILL",
                        url: "/tasks"
                    }
                ].filter((item) => item)
                break
            case Auth.UserProfileType.Others:
                menus = [
                    {id: "chats", title: t("menu.Messages"), icon: "MESSAGE_CIRCLE", url: "/communication"},
                    currentProfilePermissions.hasPermissions({
                        others: [Permissions.Others.Resources.MediaLibrary.View]
                    }) && {
                        id: "mediaLibrary",
                        title: t("menu.Media Library"),
                        icon: "OPEN_BOOK",
                        url: "/media-library"
                    },
                    isModuleEnable(Modules.Tasks) &&
                        currentProfilePermissions.hasPermissions({
                            others: [Permissions.Others.Tasks.View]
                        }) && {
                            id: "tasks",
                            title: t("menu.Tasks"),
                            icon: "TASK_FILL",
                            url: "/tasks"
                        },
                    isModuleEnable(Modules.CareerServices) &&
                        currentProfilePermissions.hasPermissions({
                            others: [Permissions.Others.CareerServices.View]
                        }) && {
                            id: "careerServicesSection",
                            title: t("menu.Career Services"),
                            icon: "PERSON",
                            childs: [
                                {
                                    id: "careerServices",
                                    title: t("menu.Career Services"),
                                    icon: "",
                                    url: `/career-services/employer/${profileId}`
                                }
                            ]
                        }
                ].filter((item) => item)
                break
            default:
                break
        }
        menus.forEach((menu) => {
            if (menu.childs?.length) {
                const childs = menu.childs.filter((child) => child)
                menu.childs = childs
            }
        })
        dispatch({menus})
        getActiveChildMenus(menus)
    }

    const getActiveChildMenus = (menus) => {
        const parentMenus = [
            {pathname: "settings", id: "settings"},
            {pathname: "academics", id: "academicsSection"},
            {pathname: "academic-affairs", id: "academicAffairsSection"},
            {pathname: "admissions", id: "admissionsSection"},
            {pathname: "financial-aid", id: "financialAidSection"},
            {pathname: "student-account", id: "studentAccountSection"},
            {pathname: "student-services", id: "studentServicesSection"},
            {pathname: "career-services", id: "careerServicesSection"}
        ]
        const isShowParentMenu = parentMenus.some((parentMenu) => {
            if (pathname.includes(`/${parentMenu.pathname}/`)) {
                const activeParentMenu = menus.find((menu) => menu && menu.id === parentMenu.id)
                if (activeParentMenu) {
                    dispatch({isOpenChildMenus: true, parentMenu: activeParentMenu})
                }
                return true
            }
            return false
        })
        if (!isShowParentMenu) {
            dispatch({isOpenChildMenus: false, parentMenu: {}})
        }
    }

    const onOpenChildMenus = (menu) => {
        let backMenu = null
        if (menu.id === "app-releases") {
            backMenu = parentMenu
        } else {
            backMenu = null
        }
        sendDefaultUrl(menu)
        dispatch({isOpenChildMenus: true, parentMenu: menu, backMenu})
    }

    const onClickBack = () => {
        if (backMenu) {
            dispatch({isOpenChildMenus: true, parentMenu: backMenu, backMenu: null})
        } else {
            history.push(menus[0].url)
            dispatch({isOpenChildMenus: false, parentMenu: {}})
        }
    }

    const sendDefaultUrl = (menu) => {
        const defaultUrl = menu.childs.find((child) => !child.isSubTitle)
        history.push(defaultUrl.url)
    }

    const getIsActive = (menuUrl) => {
        if (menuUrl === "/") {
            return pathname === "/"
        }
        if (pathname.slice(-1) === "/") {
            return menuUrl === pathname
        }
        if (ADMISSION_FINANCIAL_AID.includes(menuUrl)) {
            return (
                pathname.includes(menuUrl) ||
                ADMISSION_FINANCIAL_AID_SUB_NIVEL.some((option) => pathname.includes(option))
            )
        }
        if (pathname.includes("/financial-aid/student") && menuUrl) {
            return menuUrl.includes("/financial-aid/student")
        }

        if (pathname.includes("/student-services/student") && menuUrl) {
            return STUDENT_SERVICES.includes(menuUrl) || menuUrl.includes("/student-services/student")
        }

        if (pathname.includes("/academic-affairs/student/") && menuUrl) {
            return ACADEMIC_AFFAIR.includes(menuUrl) || menuUrl.includes("/academic-affairs/student/")
        }
        if (pathname.includes("/student-account/student/") && menuUrl) {
            return STUDENT_ACCOUNT.includes(menuUrl) || menuUrl.includes("/student-account/student/")
        }

        if (pathname.includes("/academics/registrar") && menuUrl) {
            return ACADEMICS_REGISTER_SUB_MENUS.includes(menuUrl) || menuUrl.includes("/academics/registrar/student")
        }
        if (pathname.includes("/settings")) {
            return !!menuUrl && menuUrl.includes("/settings") && pathname.includes(menuUrl)
        }
        return !!menuUrl && pathname.includes(menuUrl)
    }

    const onClickLogo = () => {
        dispatch({isOpenChildMenus: false, parentMenu: {}})
        history.push("/")
    }

    const getBrandingImg = () => {
        const rectangularImg = get(
            model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoRectangular],
            null
        )
        const squareImg = get(model, ["clientSetting", "branding", Branding.BrandingType.OfficialLogoSquare], null)
        return squareImg || rectangularImg || DefaultLogo
    }

    return (
        <>
            <div className={styles.logoWrap}>
                <Link to="/">
                    <img src={getBrandingImg()} className={styles.logoImg} alt="" onClick={onClickLogo} />
                </Link>
            </div>
            <div className={styles.wrap}>
                {!isOpenChildMenus ? (
                    menus.map((menu) =>
                        menu && menu.id === "divider" ? (
                            <div key="divider" className={styles.dividerMainMenu}></div>
                        ) : menu ? (
                            <Menu
                                key={menu.id}
                                menu={menu}
                                onOpenChildMenus={onOpenChildMenus}
                                isActive={getIsActive(menu.url)}
                            />
                        ) : null
                    )
                ) : (
                    <>
                        <div className={styles.mainMenuWrap} onClick={onClickBack}>
                            <span className={styles.backIcon}>
                                <Icon icon="BACK" />
                            </span>
                            <p className={styles.mainMenu}>{parentMenu.title}</p>
                        </div>
                        <div className={styles.divider}></div>
                        <div>
                            {(parentMenu.childs || []).map((menu) =>
                                menu && menu.isSubTitle ? (
                                    <p key={menu.id} className={styles.subTitle}>
                                        {menu.title}
                                    </p>
                                ) : menu ? (
                                    <Menu
                                        key={menu.id}
                                        menu={menu}
                                        onOpenChildMenus={onOpenChildMenus}
                                        isActive={getIsActive(menu.url)}
                                    />
                                ) : null
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
