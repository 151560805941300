import React from "react"
import moment from "moment"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {Icon} from "components/Icon"
import {KlassappTableProps} from "types/common"

import {GradeTabHeader} from "./parts"
import styles from "./GradeTab.module.css"
import {KlassappTableHOC} from "HOC"
import {TFunction, withTranslation} from "react-i18next"
import {academicCourseGradesService, academicGradingSystem, courseService} from "services"
import {get, head} from "lodash"
import {formatDateOnly, getFullName, handleError} from "helpers"
import {Course} from "types/courses"
import {Model} from "Model"
import AddCourseGrade from "sections/academics/instructional/InstructionalCourse/parts/CourseGradesTab/AddCourseGrade"

type State = {
    dataGradingItems: any[]
    students: Array<{profileId: number}>
    isAddGrade: boolean
    gradingElementId: {id: number; gradingItem: string; colorLabel: string}
}
type GradeTabProps = KlassappTableProps & {
    studentProfileIds: number[]
    courseIds: number[]
    scheduleId?: number
    termIds: number[]
    courseInfo: Course.InstructionalCourse
    history?: any
    model: Model
    t?: TFunction
}

class GradeTab extends React.Component<GradeTabProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            dataGradingItems: [
                {
                    id: -1,
                    gradingItem: "All",
                    colorLabel: "transparent"
                }
            ],
            isAddGrade: false,
            students: [],
            gradingElementId: {id: -1, gradingItem: "All", colorLabel: "transparent"}
        }
    }

    cancelAddGrade = () => {
        this.getListGrades()
        this.setState({
            ...this.state,
            isAddGrade: false
        })
    }

    onAddGrade = () => {
        this.setState({
            ...this.state,
            isAddGrade: true
        })
    }

    getListStudent = async () => {
        const {courseIds, termIds, studentProfileIds, scheduleId} = this.props
        try {
            const params: any = {
                filter: {courseIds, studentProfileIds},
                range: {page: 1, pageSize: 1}
            }
            if (termIds?.length) {
                params.filter.termIds = termIds
            }
            if (scheduleId) {
                params.filter.scheduleId = scheduleId
            }
            const {data: studentStats} = await courseService.listInstructionalStudents(params)

            const data = studentStats.map((item: any) => {
                item.id = item.student.profileId
                item.profileId = item.student.profileId
                item.fullname = getFullName(item.student)
                item.isMakeup = !!item.student.isMakeup
                item.isDropped = !!item.student.isDropped
                item.droppedDate = item.student.droppedDate
                item.addDropDate = item.student.addDropDate
                item.addDropHours = item.student.addDropHours
                item.addDropVariant = item.student.addDropVariant
                return item
            })
            this.setState({
                ...this.state,
                students: data
            })
        } catch (e) {
            console.log("e")
        }
    }

    getPageTitle = () => {
        return this.props.t("user.users")
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    getFields = () => {
        return ["Title", "Date", "Time", "Type", "Grade", "Max Point Value", "Notes"]
    }

    getGradingItems = async () => {
        try {
            const {courseInfo} = this.props
            if (courseInfo?.gradingTemplateId) {
                const {data} = await academicGradingSystem.getDetailGradingSystem(courseInfo?.gradingTemplateId)
                const {gradingElements = []} = data || {}
                let gradingItems = gradingElements.map((item) => ({
                    id: item.id,
                    gradingItem: item.gradingItem,
                    colorLabel: item.colorLabel
                }))
                gradingItems = [{id: -1, gradingItem: "All", colorLabel: "transparent"}, ...gradingItems]
                this.setState({
                    ...this.state,
                    dataGradingItems: gradingItems
                })
            }
        } catch (e) {
            handleError(e)
        }
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getListGrades},
            {key: "getFields", func: this.getFields},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getColumns", func: this.getTableColumn},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.getListGrades()
        this.getGradingItems()
        this.getListStudent()
    }

    getTableColumn = () => {
        return [
            {
                title: "Title",
                field: "title",
                style: {minWidth: "200px"}
            },
            {
                title: "Date",
                titleHtml: this.renderDateHeader(),
                field: "dateHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Time",
                titleHtml: this.renderTimeHeader(),
                field: "timeHtml",
                style: {minWidth: "150px"}
            },
            {
                title: "Type",
                field: "typeHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Grade",
                field: "grade",
                style: {minWidth: "150px"}
            },
            {
                title: "Max Point Value",
                field: "maxPointValue",
                style: {minWidth: "150px"}
            },
            {
                title: "Notes",
                field: "notes",
                style: {minWidth: "300px"}
            }
        ]
    }

    renderDateHeader = () => {
        return (
            <div className={styles.tableHeaderTitleWrap} style={{paddingLeft: 8}}>
                <Icon className={styles.dateIcon} icon="CALENDAR" />
                <p className={styles.tableHeaderTitle}>Date</p>
            </div>
        )
    }

    renderTimeHeader = () => {
        return (
            <div className={styles.tableHeaderTitleWrap}>
                <Icon className={styles.timeIcon} icon="CLOCK" />
                <p className={styles.tableHeaderTitle}>Time</p>
            </div>
        )
    }

    getListGrades = async (gradingElementId?: number) => {
        const {studentProfileIds = [], courseIds = [], termIds, dispatch} = this.props
        dispatch({isLoading: true})
        try {
            const courseId = head(courseIds)
            let filter: any = {
                studentProfileIds,
                courseIds: courseId ? [courseId] : []
            }
            if (termIds?.length) {
                filter.termIds = termIds
            }
            if (gradingElementId !== -1) {
                filter.gradingElementId = gradingElementId
            }
            let response: any = {}
            if (studentProfileIds.length) {
                try {
                    response = await academicCourseGradesService.getCourseGradeStudents({
                        filter: filter,
                        range: {
                            page: 1,
                            pageSize: 5000
                        }
                    })
                } catch (err) {}
            }

            const courseGrades = get(response, ["courseGrades", 0, "courseGrade"], [])
            const listData = []
            for (let i = 0; i < courseGrades.length; i++) {
                if (courseGrades[i].date) {
                    listData.push({
                        ...courseGrades[i],
                        id: courseGrades[i].id + i,
                        dateHtml: this.renderDate(courseGrades[i]),
                        timeHtml: this.renderTime(courseGrades[i]),
                        typeHtml: this.renderType(courseGrades[i])
                    })
                }
            }
            dispatch({data: listData, total: listData.length})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderDate = (data) => {
        return <div style={{paddingLeft: 10}}>{formatDateOnly(data.date, this.props.model.getUserDateFormat())}</div>
    }

    renderTime = (data) => {
        return (
            <div>
                {data.startTime && data.endTime
                    ? `${data.startTime} - ${data.endTime}`
                    : moment(data.date).format(this.props.model.getUserTimeFormat())}
            </div>
        )
    }

    renderType = (data) => {
        return (
            <div className={styles.gradeTypeWrap}>
                <span className={styles.gradeTypeColor} style={{background: data?.colorLabel}}></span>
                <p className={styles.gradeType}>{data?.gradingItem ?? ""}</p>
            </div>
        )
    }

    onClickRowItem = (data) => {}
    tableHeaderActions = () => {
        return []
    }

    onUpdateRowData = (updatedData) => {}
    onChangeElementData = (key, value) => {
        this.setState({
            ...this.state,
            gradingElementId: value
        })
        this.getListGrades(value.id)
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            scheduleId,
            isLoading,
            orderField,
            termIds,
            courseInfo,
            courseIds
        } = this.props

        if (this.state.isAddGrade) {
            return (
                <AddCourseGrade
                    courseInfo={courseInfo}
                    students={this.state.students}
                    courseId={head(courseIds)}
                    scheduleId={scheduleId}
                    termId={head(termIds)}
                    cancelAddGrade={this.cancelAddGrade}
                />
            )
        }

        return (
            <div>
                <GradeTabHeader
                    onChangeElementData={this.onChangeElementData}
                    gradingElementId={this.state.gradingElementId}
                    onAddGrade={this.onAddGrade}
                    dataGradingItems={this.state.dataGradingItems}
                />
                <div className={styles.tableWrap}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        isShowAction={!!data.find((item) => item.isChecked)}
                        actions={this.tableHeaderActions()}
                        defaultPageSize={pageSize}
                        onChangePage={this.props.onChangePage}
                        onChangeRowPerPage={this.props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={this.props.onChangeFields}
                        onChangeAllFields={this.props.onChangeAllFields}
                        onDraggableColumn={this.props.onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        menuActions={[]}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        orderField={orderField}
                        isShowCheckedColumn={false}
                        onUpdateRowData={this.onUpdateRowData}
                        isShowCheckedColumnHeader={false}
                        onClickRowItem={this.onClickRowItem}
                        onChangeFields={this.props.onChangeFields}
                        onChangeAllFields={this.props.onChangeAllFields}
                        onDraggableColumn={this.props.onDraggableColumn}
                        onUpdateTableData={this.props.onUpdateTableData}
                        onClickSortColumn={this.props.onClickSortColumn}
                    />
                </div>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["user"])(GradeTab))
