/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useCallback} from "react"
import {Row, Col, Checkbox} from "antd"
import {useTranslation} from "react-i18next"
import {KlassappTable, KlassappTableHeader, BaseNewFilter} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassDropAsyncPaginate} from "components/Select"
import {Icon} from "components/Icon"
import {attendanceService, campusesService} from "services"
import {convertTZ, formatCodeName, formatDateOnly, getFullName, handleError, roundTwoDigits} from "helpers"
import styles from "./../common.module.css"
import dailyAttendanceStyles from "./DailyAttendance.module.css"
import {cloneDeep, isEmpty} from "lodash"
import {useModel, useVisible} from "hooks"
import {AttendanceEventType, AttendanceStatus} from "types/attendance"
import {BaseButton, BaseLoading} from "components"
import moment from "moment"
import {DailyChart} from "./DailyChart"
import classroomAttendanceStyles from "../ClassroomAttendance/ClassroomAttendance.module.css"

import {DailyAttendancePopup} from "../DailyAttendancePopup"
import {useDebounce} from "react-use"
import PopupVerifyAttendance from "sections/academics/instructional/InstructionalLessonDetail/parts/PopupVerifyAttendance"
import {Course} from "types/courses"
import {useFilterStorage} from "hooks/useFilterStorage"
import {FilterKey} from "types/filter"
import {YesNo} from "types/common"
import {exportCsv, exportExcel} from "helpers/export-table"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {ExportButton, ExportFileType} from "components/ui"
import {BaseRangePicker} from "components/DateTimePicker"
import AddManualDailyAttendance from "./AddManualDailyAttendance/AddManualDailyAttendance"

export enum FilterEventType {
    Active = "active",
    InSchool = "inSchool",
    Break = "break",
    Checkout = "checkOut",
    Missed = "missed"
}
const defaultFilter = {
    majorVersionIds: [],
    filterEventType: undefined,
    campuses: [],
    programs: [],
    instructors: [],
    dateRange: []
}

function DailyAttendanceTab(props) {
    const [showAll, setShowAll] = useState(false)
    const {t} = useTranslation(["studentServices"])
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.DailyAttendance,
        defaultFilter
    )
    const popupAddManualDailyAttendance = useVisible()
    const [exporting, setExporting] = useState(false)
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        getCurrentData,
        allFields,
        isLoading,
        dispatch,
        studentProfileId
    } = props

    const [selectedAttendance, setSelectedAttendance] = useState<{
        profileId: number
    }>()
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [searchDebounce, setSearchDebounce] = useState(searchValue)
    const [isLoadingStats, setIsLoadingStats] = useState(false)
    const [statsInfo, setStatsInfo] = useState([])

    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getDailyAttendance},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        getDailyAttendanceStats()
    }, [])

    const onRefreshData = useCallback(() => {
        getDailyAttendanceStats()
        getDailyAttendance()
    }, [])

    const getDailyAttendanceStats = async (loading: boolean = true) => {
        try {
            loading && setIsLoadingStats(true)
            const data = await attendanceService.getDailyAttendanceStatsNew()
            setStatsInfo(data)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingStats(false)
        }
    }

    const getFilterParams = () => {
        const currentMajorVersionIds = statsInfo.map((info) => info.id)
        const majorVersionIds =
            currentFilter.majorVersionIds?.filter((majorVersionId) =>
                currentMajorVersionIds.includes(majorVersionId)
            ) ?? []
        const params: any = {
            campusIds: !isEmpty(currentFilter.campuses) ? currentFilter.campuses.map((campus) => campus.id) : undefined,
            instructorProfileIds: !isEmpty(currentFilter.instructors)
                ? currentFilter.instructors.map((instructor) => instructor.profileId)
                : undefined,
            majorVersionIds: majorVersionIds,
            eventTypes: undefined
        }

        if (currentFilter?.dateRange?.length) {
            const startDate = moment(currentFilter.dateRange[0])
            startDate.startOf("day")
            const endDate = moment(currentFilter.dateRange[1])
            endDate.endOf("day")
            params.fromDate = startDate.format("YYYY-MM-DD")
            params.toDate = endDate.format("YYYY-MM-DD")
        }

        if (searchDebounce?.length) {
            params.search = searchDebounce
        }

        if (majorVersionIds?.length && currentFilter.filterEventType) {
            if (currentFilter.filterEventType === FilterEventType.Active) {
                params.eventTypes = []
            } else if (currentFilter.filterEventType === FilterEventType.Break) {
                params.eventTypes = [AttendanceEventType.BreakStart]
            } else if (currentFilter.filterEventType === FilterEventType.Checkout) {
                params.eventTypes = [AttendanceEventType.Checkout]
            } else if (currentFilter.filterEventType === FilterEventType.InSchool) {
                params.eventTypes = [AttendanceEventType.Checkin, AttendanceEventType.BreakEnd]
            } else if (currentFilter.filterEventType === FilterEventType.Missed) {
                params.isMissed = true
            }
        }

        const profileId = model.profileId
        if (!model.isStaffOrAdmin() && profileId) {
            params.studentProfileIds = [profileId]
        } else if (studentProfileId) {
            params.studentProfileIds = [parseInt(studentProfileId)]
        }
        return params
    }

    const renderIconAttendance = (eventType: string) => {
        if (eventType === "check-out") {
            return <Icon icon="CHECKOUT_FILL" color={"#1E90FF"} className={styles.checkOutIcon} />
        } else if (eventType === "check-in") {
            return <Icon icon="CHECKIN_FILL" color={"#1E90FF"} className={styles.rightIcon} />
        } else if (eventType === "break-start") {
            return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.rightIcon} />
        } else if (eventType === "break-end") {
            return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.rightIcon} />
        } else {
            return null
        }
    }

    async function getDailyAttendance() {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data, total} = await attendanceService.getDailyAttendanceList({
                range: {
                    page,
                    pageSize
                },
                ...params
            })
            const dailyAttendance = (data || []).map((daily) => {
                if (daily.isVerified === "yes" || !model.isStaffOrAdmin()) {
                    daily.isDisableCheckBox = true
                }
                const events = daily.events ?? []
                const latestEventType = events.length ? events[events.length - 1].eventType : undefined
                const checkIn = events.find((event) => event.eventType === AttendanceEventType.Checkin)
                const checkOut = events.find((event) => event.eventType === AttendanceEventType.Checkout)
                return {
                    ...daily,
                    checkInHtml: checkIn
                        ? moment(convertTZ(new Date(checkIn.createdAt), daily.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    checkOutHtml: checkOut
                        ? moment(convertTZ(new Date(checkOut.createdAt), daily.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    statusHtml: renderIconAttendance(latestEventType),
                    attendanceId: daily.id,
                    majorVersionName: daily.majorVersion?.versionName ?? "",
                    majorVersionCode: daily.majorVersion?.versionCode ?? "",
                    missedHours: `${roundTwoDigits((daily.missedHours ?? 0) * 60)}`,
                    makeUpHours: `${roundTwoDigits(daily.makeUpHours ?? 0) * 60}`,
                    attendedHours: `${roundTwoDigits((daily.attendedHours ?? 0) * 60)}`,
                    dateHtml: formatDateOnly(daily.date, dateFormat),
                    customProfileId: daily.student?.customProfileId ?? "",
                    instructorNames: (daily.instructors ?? [])
                        .map(function (instructor) {
                            return instructor.name
                        })
                        .join(","),
                    majorName: daily.major?.name ?? "",
                    majorCode: daily.major?.code ?? "",
                    studentName: getFullName(daily.student),
                    attendHtml: renderAttendHtml(daily.status, daily.isVerified)
                }
            })
            props.dispatch({data: dailyAttendance, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function renderAttendHtml(status, isVerified) {
        if (isVerified === "yes" && status === AttendanceStatus.Rejected) {
            return (
                <div className={styles.attClose}>
                    <Icon icon="CROSS_CIRCLE" />
                </div>
            )
        }
        if (isVerified === "yes") {
            return (
                <div className={classroomAttendanceStyles.attCheckVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        switch (status) {
            case AttendanceStatus.Approved:
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case AttendanceStatus.Irregular:
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case AttendanceStatus.Rejected:
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            case AttendanceStatus.InClass:
            default:
                return null
        }
    }

    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "dateHtml",
                fieldType: "date",
                format: dateFormat,
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Sis Attn Id",
                field: "sisid",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Student Id",
                field: "customProfileId",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: t("attendance.table.student"),
                field: "studentName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Code",
                field: "majorCode",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Name",
                field: "majorName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Version",
                field: "majorVersionName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Version Code",
                field: "majorVersionCode",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check In",
                field: "checkInHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check Out",
                field: "checkOutHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.attended"),
                field: "attendedHours",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.missed"),
                field: "missedHours",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "attn",
                field: "attendHtml",
                headerStyle: {minWidth: "150px"}
            }
        ]
    }

    function getFields() {
        return [
            t("attendance.table.date"),
            "Sis Attn Id",
            "Student Id",
            t("attendance.table.student"),
            "Major Code",
            "Major Name",
            "Major Version",
            "Major Version Code",
            "Check In",
            "Check Out",
            "Status",
            t("attendance.table.attended"),
            t("attendance.table.missed"),
            "attn"
        ]
    }

    const onClickRow = async (data) => {
        setIsShowPopup(true)
        try {
            setSelectedAttendance(data)
        } catch (err) {
            handleError(err)
        }
    }

    const onClosePopup = useCallback(() => {
        setIsShowPopup(false)
        setSelectedAttendance(undefined)
    }, [])

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    useEffect(() => {
        onApplyFilter()
    }, [currentFilter, page, pageSize, searchDebounce])

    const onClickDailyChartItem = (item, type) => {
        if (currentFilter.majorVersionIds.includes(item.id) && currentFilter.filterEventType === type) {
            const newFilter = {...currentFilter}
            newFilter.majorVersionIds = []
            newFilter.filterEventType = undefined
            setCurrentFilter(newFilter)
        } else {
            const newFilter = {...currentFilter}
            newFilter.majorVersionIds = [item.id]
            newFilter.filterEventType = type
            setCurrentFilter(newFilter)
        }
    }

    const onApplyFilter = () => {
        applyFilter()
        getDailyAttendance()
    }

    const handleExportFile = async (type: ExportFileType) => {
        setExporting(true)
        try {
            const params = getFilterParams()
            const {data, total} = await attendanceService.getDailyAttendanceList({
                range: {
                    page,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                ...params
            })
            const dailyAttendance = (data || []).map((daily) => {
                if (daily.isVerified === "yes" || !model.isStaffOrAdmin()) {
                    daily.isDisableCheckBox = true
                }
                const events = daily.events ?? []
                const latestEventType = events.length ? events[events.length - 1].eventType : undefined
                const checkIn = events.find((event) => event.eventType === AttendanceEventType.Checkin)
                const checkOut = events.find((event) => event.eventType === AttendanceEventType.Checkout)
                return {
                    ...daily,
                    checkInHtml: checkIn
                        ? moment(convertTZ(new Date(checkIn.createdAt), daily.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    checkOutHtml: checkOut
                        ? moment(convertTZ(new Date(checkOut.createdAt), daily.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    statusHtml: latestEventType,
                    attendanceId: daily.id,
                    majorVersionName: daily.majorVersion?.versionName ?? "",
                    majorVersionCode: daily.majorVersion?.versionCode ?? "",
                    missedHours: `${roundTwoDigits((daily.missedHours ?? 0) * 60)}`,
                    makeUpHours: `${roundTwoDigits(daily.makeUpHours ?? 0) * 60}`,
                    attendedHours: `${roundTwoDigits((daily.attendedHours ?? 0) * 60)}`,
                    dateHtml: formatDateOnly(daily.date, dateFormat),
                    customProfileId: daily.student?.customProfileId ?? "",
                    instructorNames: (daily.instructors ?? [])
                        .map(function (instructor) {
                            return instructor.name
                        })
                        .join(","),
                    majorName: daily.major?.name ?? "",
                    majorCode: daily.major?.code ?? "",
                    studentName: getFullName(daily.student),
                    attendHtml:
                        daily.status === AttendanceStatus.Approved
                            ? `${YesNo.Yes} (Verified: ${daily.isVerified ? YesNo.Yes : YesNo.No})`
                            : `${YesNo.No} (Verified: ${daily.isVerified ? YesNo.Yes : YesNo.No})`
                }
            })
            const filename = `Daily_Attendance_${moment().format("MM_DD_YYYY")}`
            const payload = {
                filename,
                columns: columns.map((col) => col.title.toUpperCase()),
                rows: dailyAttendance.map((item) =>
                    columns.map((col) => {
                        return item[col.field]?.toString() ?? ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setExporting(false)
        }
    }

    const renderStats = () => {
        if (isLoadingStats) {
            return <BaseLoading isShow />
        }
        const statsInfoData = !showAll ? statsInfo.filter((stat) => stat.totalActiveStudent > 0) : statsInfo
        return (
            <Row gutter={[40, 40]}>
                {statsInfoData.map((item) => (
                    <Col key={item.id} span={8}>
                        <DailyChart data={item} onClickItem={onClickDailyChartItem} filters={currentFilter} />
                    </Col>
                ))}
            </Row>
        )
    }

    const onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onCancelConfirm = () => {
        setIsShowConfirmVerify(false)
    }

    const onShowConfirm = () => {
        setIsShowConfirmVerify(true)
    }

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dispatch({data: newData})
    }

    const headerActions = () => {
        const actions: any = [
            {
                title: (
                    <div className={dailyAttendanceStyles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={dailyAttendanceStyles.verifybtn}
                            onClick={onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
        return actions
    }

    return (
        <div style={{paddingBottom: 150}}>
            <div className={dailyAttendanceStyles.checkbox}>
                <Checkbox checked={showAll} onChange={() => setShowAll(!showAll)}>
                    Show All
                </Checkbox>
            </div>
            {renderStats()}
            <BaseNewFilter
                searchValue={searchValue}
                onSearchInput={changeSearch}
                onClick={onApplyFilter}
                onClickClear={clearFilter}
                filter={currentFilter}
                renderRightFilter={() => (
                    <div className={dailyAttendanceStyles.rightFilter}>
                        <BaseButton title="Add Manual" onClick={popupAddManualDailyAttendance.open} />
                        <ExportButton
                            label="EXPORT"
                            isLoading={exporting}
                            onSelect={handleExportFile}
                            availableFileTypes={["csv", "excel"]}
                        />
                    </div>
                )}>
                <Row gutter={[40, 32]}>
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Date From", "Date To"]}
                            value={currentFilter.dateRange}
                            onChange={(newValue) => {
                                onChangeFilter("dateRange", newValue)
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={currentFilter.campuses}
                            onChange={(newValue) => onChangeFilter("campuses", newValue)}
                            loadOptions={onCampusSearchChange}
                            isMulti
                            placeholder="Campus"
                            getOptionLabel={formatCodeName}
                        />
                    </Col>
                    <Col span={12}></Col>
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                isShowAction={!!data.find((item) => item.isChecked)}
                actions={headerActions()}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                isShowCheckedColumn
                isShowCheckedColumnHeader={false}
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                onClickRowItem={onClickRow}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <DailyAttendancePopup
                studentProfileIds={selectedAttendance?.profileId ? [selectedAttendance.profileId] : []}
                getListAttendances={() => {
                    getDailyAttendanceStats(false)
                    getDailyAttendance()
                }}
                selectedAttendance={selectedAttendance}
                isShow={isShowPopup}
                onClose={onClosePopup}
            />
            <PopupVerifyAttendance
                data={data}
                type={Course.ProgramAttendanceTrackingType.Daily}
                isShowConfirmVerify={isShowConfirmVerify}
                onCancelConfirm={onCancelConfirm}
                refreshData={() => {
                    getDailyAttendance()
                }}
            />
            <AddManualDailyAttendance
                onRefreshData={onRefreshData}
                onClose={() => {
                    popupAddManualDailyAttendance.close()
                    onRefreshData()
                }}
                isShow={popupAddManualDailyAttendance.isVisible}
            />
        </div>
    )
}
export const DailyAttendance = KlassappTableHOC(DailyAttendanceTab)
