/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useMemo, useState} from "react"
import {Auth} from "types/auth"
import {profileService, studentService} from "services"
import {head, isEmpty} from "lodash"
import {AdvisorsPopup, AdvisorTitle} from "uiKit"
import {BaseDepartmentId} from "types/departments"
import {useVisible, UseVisibleArgs} from "hooks"
import produce from "immer"
import {Campus} from "types/campus"
import {StudentDepartmentStatus} from "types/workflow"
import {AutoDialer} from "types/auto-dialer"
import {useAllStudentDepartmentStatuses} from "hooks/student-statuses/useAllStudentDepartmentStatuses"

type ContextProps = {
    departmentId: number
    setDepartmentId: React.Dispatch<React.SetStateAction<number>>
    student?: Auth.DepartmentStudent
    setStudent?: React.Dispatch<React.SetStateAction<Auth.DepartmentStudent | undefined>>
    statusChange?: Auth.StudentDepartmentStatusDetails
    setStatusChange?: React.Dispatch<React.SetStateAction<Auth.StudentDepartmentStatusDetails | undefined>>
    studentStatusChange?: {profileId: number; statusId: number}
    setStudentStatusChange?: React.Dispatch<React.SetStateAction<{profileId: number; statusId: number} | undefined>>
    financialAidStatusChange?: {profileId: number; statusId: number}
    setFinancialAidStatusChange?: React.Dispatch<
        React.SetStateAction<{profileId: number; statusId: number} | undefined>
    >
    studentAdvisors: Array<Auth.Advisor & Auth.UserShortDetails>
    advisorsPopup: UseVisibleArgs
    studentDetailVisible: UseVisibleArgs
    reloadStudentAdvisors: (profileId?: number) => Promise<void>
    reloadStudent: (profileId?: number) => Promise<void>
    payingMethods: Auth.PayingMethod[]
    studentRoles: Array<Auth.Role & {roleName: string}>
    departmentStatuses: StudentDepartmentStatus[]
    enrollmentProfileIdChange?: Auth.StudentDepartmentEnrollmentChangeProfileId
    setEnrollmentProfileIdChange?: React.Dispatch<
        React.SetStateAction<Auth.StudentDepartmentEnrollmentChangeProfileId | undefined>
    >
    dialerScreen?: AutoDialer.DialerScreen
    setDialerScreen?: React.Dispatch<React.SetStateAction<AutoDialer.DialerScreen | undefined>>
}

export const ActiveStudentContext = createContext<ContextProps>({} as ContextProps)

export const ActiveStudentProvider: React.FC<React.PropsWithChildren<{departmentId?: number}>> = ({
    children,
    departmentId: initialDepartmentId = 0
}) => {
    const [departmentId, setDepartmentId] = React.useState(initialDepartmentId)
    const [student, setStudent] = React.useState<Auth.DepartmentStudent | undefined>()
    const [statusChange, setStatusChange] = useState<Auth.StudentDepartmentStatusDetails | undefined>()
    const [studentStatusChange, setStudentStatusChange] = useState<
        | {
              profileId: number
              statusId: number
          }
        | undefined
    >()
    const [financialAidStatusChange, setFinancialAidStatusChange] = useState<
        {profileId: number; statusId: number} | undefined
    >()
    const [studentAdvisors, setStudentAdvisors] = useState<Array<Auth.Advisor & Auth.UserShortDetails>>([])
    const [studentRoles, setStudentRoles] = useState<Array<Auth.Role & {roleName: string}>>([])
    const [payingMethods, setPayingMethods] = useState<Auth.PayingMethod[]>([])
    const {statuses} = useAllStudentDepartmentStatuses()
    const departmentStatuses = useMemo(
        () => statuses.filter((status) => status.stage.departmentId === departmentId),
        [statuses, departmentId]
    )
    const advisorsPopup = useVisible(false)
    const studentDetailVisible = useVisible(false)
    const [enrollmentProfileIdChange, setEnrollmentProfileIdChange] = useState<
        Auth.StudentDepartmentEnrollmentChangeProfileId | undefined
    >()
    const [dialerScreen, setDialerScreen] = useState<AutoDialer.DialerScreen | undefined>({
        isAuto: false,
        status: AutoDialer.DialerStatus.NotStart,
        isPaused: false
    })

    const reloadStudentAdvisors = React.useCallback(
        async (profileId?: number) => {
            if (!departmentId || (!student?.profileId && !profileId)) {
                setStudentAdvisors([])
                return
            }
            try {
                const {data: studentAdvisors} = await studentService.getStudentAdvisors({
                    filter: {
                        departmentId,
                        profileIds: [profileId || student.profileId]
                    }
                })
                if (!isEmpty(head(studentAdvisors))) {
                    setStudentAdvisors(head(Object.values(head(studentAdvisors))))
                }
            } catch (error) {
                setStudentAdvisors([])
            }
        },
        [departmentId, student?.profileId]
    )

    const loadingSeqRef = React.useRef(Math.random())

    const reloadStudent = React.useCallback(
        async (profileId?: number) => {
            if (!departmentId || (!student?.profileId && !profileId)) return

            const loadingSeq = Math.random()
            loadingSeqRef.current = loadingSeq
            const {
                data: [newStudent]
            } = await studentService.getDepartmentStudents({
                filter: {
                    includeArchive: true,
                    profileIds: [profileId || student.profileId],
                    departmentId
                }
            })
            if (loadingSeq === loadingSeqRef.current) {
                setStudent(newStudent)
            }
        },
        [departmentId, student?.profileId]
    )

    React.useEffect(() => {
        reloadStudentAdvisors()
    }, [reloadStudentAdvisors])

    const handleAdvisorsPopupClose = React.useCallback(
        (reload = false) => {
            advisorsPopup.close()
            if (reload) reloadStudentAdvisors()
        },
        [reloadStudentAdvisors]
    )

    React.useEffect(() => {
        setStudent((student) =>
            !student
                ? undefined
                : produce(student, (draft) => {
                      if (Array.isArray(draft.campuses)) {
                          draft.campuses.forEach((campus, index) => {
                              if (typeof campus === "string") {
                                  draft.campuses[index] = {
                                      id: draft.campusIds[index],
                                      name: campus
                                  } as Campus.Campus
                              }
                          })
                      }
                      if (!draft.currentMajor && draft.programId) {
                          draft.currentMajor = {
                              id: draft.programId,
                              name: draft.programName
                          } as Auth.CurrentMajor
                      }
                      if (
                          (!draft.currentMajorVersionId || typeof draft.currentMajorVersionId === "number") &&
                          draft.programVersionId
                      ) {
                          draft.currentMajorVersionId = {
                              id: draft.programVersionId,
                              versionName: draft.programVersionName
                          } as Auth.CurrentMajorVersion
                      }
                      if (!draft.startingTerm && draft.startingTermId) {
                          draft.startingTerm = {
                              id: draft.startingTermId,
                              name: draft.startingTermName,
                              start_date: draft.startingTermStartDate,
                              end_date: draft.startingTermEndDate
                          }
                      }
                      if (!draft.currentTerm && draft.termId) {
                          draft.currentTerm = {
                              id: draft.termId,
                              name: draft.termName,
                              start_date: draft.termStartDate,
                              end_date: draft.termEndDate
                          }
                      }
                  })
        )
    }, [student])

    React.useEffect(() => {
        loadingSeqRef.current = Math.random()
    }, [student?.profileId])

    React.useEffect(() => {
        ;(async function getStudentRoles() {
            try {
                const roles = await profileService.getRoles({
                    sort: {orderBy: "name", orderDir: "asc"},
                    filter: {userType: Auth.UserProfileType.Student}
                })
                setStudentRoles(roles.map((role) => ({...role, roleName: role.name})))
            } catch (error) {
                console.error(error)
                setStudentRoles([])
            }
        })()
        ;(async function getPayingMethods() {
            try {
                const payingMethods = await profileService.getPayingMethods({sort: {orderBy: "name", orderDir: "asc"}})
                setPayingMethods(payingMethods)
            } catch (error) {
                console.error(error)
                setPayingMethods([])
            }
        })()
    }, [])

    return (
        <ActiveStudentContext.Provider
            value={{
                departmentId,
                setDepartmentId,
                student,
                setStudent,
                statusChange,
                setStatusChange,
                studentStatusChange,
                setStudentStatusChange,
                financialAidStatusChange,
                setFinancialAidStatusChange,
                studentAdvisors,
                advisorsPopup,
                studentDetailVisible,
                reloadStudentAdvisors,
                reloadStudent,
                studentRoles,
                payingMethods,
                departmentStatuses,
                enrollmentProfileIdChange,
                setEnrollmentProfileIdChange,
                dialerScreen,
                setDialerScreen
            }}>
            {children}
            {advisorsPopup.isVisible && (
                <AdvisorsPopup
                    isVisible={advisorsPopup.isVisible}
                    close={handleAdvisorsPopupClose}
                    profileIds={[student?.profileId]}
                    departmentId={departmentId}
                    campusIds={student?.campusIds ?? []}
                    advisorTitle={
                        departmentId === BaseDepartmentId.Admissions
                            ? AdvisorTitle.admissionAdvisors
                            : departmentId === BaseDepartmentId.FinancialAid
                            ? AdvisorTitle.finAidAdvisors
                            : AdvisorTitle.advisors
                    }
                />
            )}
        </ActiveStudentContext.Provider>
    )
}
