import {BasePopup} from "uiKit"
import {DocumentForm} from "./parts"
import {useUser} from "hooks"

type Props = {
    isShow: boolean
    onCancel: () => void
    userId?: number
    onDocumentsCreated: () => void
}

export function DocumentUpload(props: Props) {
    const {isShow, onCancel, userId, onDocumentsCreated} = props

    const {user} = useUser({userId})

    return (
        <BasePopup isShow={isShow} onCancel={onCancel} width="95vw" leftIcon="FILE_TEXT" destroyOnClose>
            <DocumentForm profileId={user?.profileId} onCancel={onCancel} onDocumentsCreated={onDocumentsCreated} />
        </BasePopup>
    )
}
