/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import moment, {Moment} from "moment-timezone"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {cloneDeep, concat, forEach, groupBy, head, isEmpty, keys, last, map, range, uniq} from "lodash"
import {Icon} from "components/Icon"
import {useModel, useVisible} from "hooks"
import {Major} from "types/major"
import {getPositionMoveInObject, handleError, randomPositionFirstItem, toastError} from "helpers"
import {CourseItem, DaysInfo, DotContent, MultiCourseItems, TimeContent} from "../CourseItem"
import {AddCoursesPopup, AddMultiCoursesPopup} from "../AddCoursePopup"
import styles from "./TermItem.module.css"
import {academicPlansService, majorService, termsService} from "services"
import {Switch, Tooltip} from "antd"
import {BaseButton, BaseLoading, KlassDropdown} from "components"
import {ConfirmPopup} from "uiKit"
import {generateCourseEvents, generateCalendarEventsFromScheduleEvents} from "utils/calendarBase"
import {Auth} from "types/auth"

type Props = {
    term: Major.TermSequence
    times: string[]
    index: number
    termDetail: any
    workdays: Moment[]
    versionData?: Major.MajorVersion
    isInMajorVersion: boolean
    isInTermSettingPreview: boolean
    isInAcademicPlan: boolean
    campusId: number
    majorVersionId?: number
    termCourseIds: number[]
    selectableTerms: Major.SelectableTerm[]
    studentProfileId?: number
    studentId?: number
    deleteTerm: (id: number) => void
    reloadView: () => void
    updateTerm: (newTerm: Major.TermSequence) => void
}

export function TermItem(props: Props) {
    const model = useModel()
    const [eventData, setEventData] = useState(null)
    const [addCourseBtnWidth, setAddCourseBtnWidth] = useState(150)
    const [totalCourseDay, setTotalCourseDay] = useState(0)
    const [addCourseDayRange, setAddCourseDayRange] = useState(0)
    const [isRegistrationHold, setIsRegistrationHold] = useState(false)
    const [selectableTerm, setSelectableTerm] = useState(null)
    const [isShowBody, setIsShowBody] = useState(true)
    const [isRegistering, setIsRegistering] = useState(false)
    const [isUnRegistering, setIsUnRegistering] = useState(false)
    const [isSelecting, setIsSelecting] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [isCalendarBasedMultiCourse, setIsCalendarBasedMultiCourse] = useState(false)
    const {
        term,
        times,
        index,
        termDetail,
        workdays,
        versionData,
        isInMajorVersion,
        isInTermSettingPreview,
        isInAcademicPlan,
        majorVersionId,
        termCourseIds,
        selectableTerms,
        studentProfileId,
        studentId,
        campusId,
        deleteTerm,
        reloadView
    } = props
    const addCoursePopup = useVisible()
    const addMultiCoursesPopup = useVisible()
    const deleteTermPopup = useVisible(false)
    const hasTermDetail = !isEmpty(termDetail)
    const CELL_WIDTH = 78

    useEffect(() => {
        const eventData = {}
        let totalCourseDay = 0
        const isCalendarBasedMultiCourse = isInMajorVersion
            ? !!versionData.isCalendarBasedMultiCourse
            : term.courses.some((course) => (isInTermSettingPreview ? !!course.groupId : !!course.calendarBasedGroupId))
        if (isInMajorVersion) {
            if (isCalendarBasedMultiCourse) {
                const coursesByGroupId = groupBy(term.courses, "groupId")
                term.groupCourses = keys(coursesByGroupId).map((groupId) => {
                    const firstCourse = head(coursesByGroupId[groupId])
                    return {
                        groupId,
                        position: firstCourse.position,
                        numberOfDays: firstCourse.numberOfDays,
                        color: firstCourse.color,
                        courses: coursesByGroupId[groupId]
                    }
                })
                term.groupCourses.forEach((groupCourse) => {
                    let events = []
                    groupCourse.courses.forEach((course) => {
                        const newEvents = course.events.map((event) => ({
                            ...event,
                            instructors: course.instructors,
                            courseName: course.name,
                            courseCode: course.code
                        }))
                        events = concat(events, newEvents)
                    })
                    generateCourseEvents(events, eventData, totalCourseDay, null)
                    totalCourseDay += groupCourse.numberOfDays
                })
            } else {
                term.courses = term.courses.map((course: Major.CourseSequence) => {
                    generateCourseEvents(course.events, eventData, totalCourseDay, null, {
                        instructors: course.instructors,
                        courseName: course.name,
                        courseCode: course.code
                    })
                    totalCourseDay += course.numberOfDays
                    return course
                })
            }
        } else if (isCalendarBasedMultiCourse) {
            const coursesByGroupId = isInTermSettingPreview
                ? groupBy(term.courses, "groupId")
                : groupBy(term.courses, "calendarBasedGroupId")
            term.groupCourses = keys(coursesByGroupId).map((groupId) => {
                const firstCourse = head(coursesByGroupId[groupId])
                return {
                    groupId,
                    position: firstCourse.position,
                    numberOfDays: firstCourse.numberOfDays,
                    color: firstCourse.color,
                    courses: coursesByGroupId[groupId]
                }
            })
            term.groupCourses.forEach((groupCourse) => {
                let events = []
                groupCourse.courses.forEach((course) => {
                    if (isInAcademicPlan) {
                        course.groupId = course.calendarBasedGroupId
                    }
                    const scheduleEvents = course.schedule
                        ? generateCalendarEventsFromScheduleEvents(course.schedule?.schedule_events || [], {
                              instructors: course.schedule.instructors,
                              capacity: +course.schedule.capacity,
                              courseId: course.courseId,
                              courseCode: course.code,
                              courseName: course.name
                          })
                        : []
                    events = concat(events, scheduleEvents)
                })
                generateCourseEvents(events, eventData, totalCourseDay, workdays)
                totalCourseDay += groupCourse.numberOfDays
            })
        } else {
            term.courses = term.courses.map((course) => {
                const events = course.schedule
                    ? generateCalendarEventsFromScheduleEvents(course.schedule?.schedule_events || [], {
                          instructors: course.schedule.instructors,
                          capacity: +course.schedule.capacity,
                          courseId: course.courseId,
                          courseCode: course.code,
                          courseName: course.name
                      })
                    : []
                generateCourseEvents(events, eventData, totalCourseDay, workdays)
                totalCourseDay += course.numberOfDays
                return course
            })
        }
        if (hasTermDetail) {
            const {startDate, endDate} = termDetail
            const dayOfTerm = moment(endDate).diff(moment(startDate), "day") + 1
            if (dayOfTerm > totalCourseDay) {
                const width = (dayOfTerm - totalCourseDay) * CELL_WIDTH
                setAddCourseDayRange(dayOfTerm - totalCourseDay)
                setAddCourseBtnWidth(width)
            }
            if (index !== 0 && term.scheduledTermId) {
                setSelectableTerm({termId: term.id, name: `Term ${term.name}`})
            }
        }
        setEventData(eventData)
        setTotalCourseDay(totalCourseDay)
        setIsCalendarBasedMultiCourse(isCalendarBasedMultiCourse)
        if (isInTermSettingPreview) {
            setIsRegistrationHold(!!term.isRegistrationHold)
        }
    }, [])

    const getPrevCourseDay = (term: Major.TermSequence, index: number) => {
        let prevCourseDay = 0
        if (index === 0) {
            return 0
        }
        if (isCalendarBasedMultiCourse) {
            for (let i = 1; i <= index; i += 1) {
                prevCourseDay += term.groupCourses[i - 1].numberOfDays
            }
        } else {
            for (let i = 1; i <= index; i += 1) {
                prevCourseDay += term.courses[i - 1].numberOfDays
            }
        }
        return prevCourseDay
    }

    const reorder = async (courses: Major.CourseSequence[], startIndex: number, endIndex: number) => {
        const result = cloneDeep(courses)
        const newPosition = getPositionMoveInObject(result, "position", startIndex, endIndex, true)
        const removedItems: Major.CourseSequence[] = result.splice(startIndex, 1)
        const [removed] = removedItems
        removed.position = newPosition
        await updateCoursePosition(removed.id, newPosition)
        result.splice(endIndex, 0, removed)
        return result
    }

    const reorderGroup = async (groupCourses: Major.GroupCourseSequence[], startIndex: number, endIndex: number) => {
        const result = cloneDeep(groupCourses)
        const newPosition = getPositionMoveInObject(result, "position", startIndex, endIndex, true)
        const removedItems: Major.GroupCourseSequence[] = result.splice(startIndex, 1)
        const [removed] = removedItems
        removed.position = newPosition
        await updateCoursePosition(removed.groupId, newPosition)
        result.splice(endIndex, 0, removed)
        return result
    }

    const updateCoursePosition = async (id, newPosition) => {
        try {
            const payload = isCalendarBasedMultiCourse
                ? {groupId: id, position: newPosition}
                : {id, position: newPosition}
            if (isInMajorVersion) {
                await majorService.updateCourseCalendarBased(payload)
            } else if (isInTermSettingPreview) {
                await termsService.updateCourseCalendarBased(payload)
            } else if (isInAcademicPlan) {
                await academicPlansService.updateCourseCalendarBased(payload)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const onDragEnd = async (result) => {
        const {source, destination} = result
        if (!source || !destination) {
            return
        }
        setIsDragging(true)
        if (isCalendarBasedMultiCourse) {
            await reorderGroup(term.groupCourses, source.index, destination.index)
        } else {
            await reorder(term.courses, source.index, destination.index)
        }
        setIsDragging(false)
        reloadView()
    }

    const onClickAddCourse = () => {
        if (isCalendarBasedMultiCourse) {
            addMultiCoursesPopup.open()
        } else {
            addCoursePopup.open()
        }
    }

    const onClickAddEventInAdditionalDay = () => {
        toastError("Please add the new course first")
    }

    const renderCourseItem = (course, index, extraData) => {
        const {provided, isDraggable} = extraData
        let providedProps = {}
        if (isDraggable) {
            providedProps = {...provided.draggableProps}
        }
        const courseDisabled = isInTermSettingPreview && !termCourseIds.includes(course.courseId)
        return (
            <div
                key={course.id}
                ref={isDraggable ? provided.innerRef : null}
                {...providedProps}
                className={cx({
                    [styles.courseDisabled]: courseDisabled
                })}>
                <CourseItem
                    key={course.id}
                    course={course}
                    times={times}
                    eventData={eventData}
                    prevCourseDay={getPrevCourseDay(term, index)}
                    extraData={extraData}
                    termDetail={termDetail}
                    workdays={workdays}
                    versionData={versionData}
                    isInMajorVersion={isInMajorVersion}
                    isInTermSettingPreview={isInTermSettingPreview}
                    isInAcademicPlan={isInAcademicPlan}
                    courseDisabled={courseDisabled}
                    studentProfileId={studentProfileId}
                    majorVersionId={majorVersionId}
                    campusId={campusId}
                    reloadView={reloadView}
                />
            </div>
        )
    }

    const renderMultiCourseItem = (group: Major.GroupCourseSequence, index, extraData) => {
        const {provided, isDraggable} = extraData
        let providedProps = {}
        if (isDraggable) {
            providedProps = {...provided.draggableProps}
        }
        return (
            <div key={group.groupId} ref={isDraggable ? provided.innerRef : null} {...providedProps}>
                <MultiCourseItems
                    key={group.groupId}
                    group={group}
                    times={times}
                    eventData={eventData}
                    prevCourseDay={getPrevCourseDay(term, index)}
                    extraData={extraData}
                    termDetail={termDetail}
                    workdays={workdays}
                    versionData={versionData}
                    isInMajorVersion={isInMajorVersion}
                    isInTermSettingPreview={isInTermSettingPreview}
                    isInAcademicPlan={isInAcademicPlan}
                    courseDisabled={false}
                    studentProfileId={studentProfileId}
                    majorVersionId={majorVersionId}
                    campusId={campusId}
                    reloadView={reloadView}
                />
            </div>
        )
    }

    if (!eventData) {
        return null
    }

    const getNextCoursePosition = () => {
        const lastCourse = last(term.courses)
        if (lastCourse) {
            return lastCourse.position + randomPositionFirstItem()
        }
        return randomPositionFirstItem()
    }

    const updateRegistrationHold = async (checked) => {
        setIsRegistrationHold(checked)
        try {
            await termsService.updateTermsCalendarBased({id: term.id, isRegistrationHold: checked ? 1 : 0})
        } catch (error) {
            handleError(error)
        }
    }

    const onClickRegisterTerm = async () => {
        try {
            setIsRegistering(true)
            const calendarIds = uniq(
                term.courses.map((course) => course.schedule?.calendar_id).filter((item) => !!item)
            )
            await academicPlansService.registerTerm({
                academicPlanTermId: term.id,
                calendarIds,
                userId: studentId
            })
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsRegistering(false)
        }
    }

    const onToggleShowBody = () => {
        setIsShowBody(!isShowBody)
    }

    const onChangeSelectableTerm = async (value) => {
        try {
            setIsSelecting(true)
            setSelectableTerm(value)
            await termsService.updateTermsCalendarBased({id: term.id, scheduledTermId: value.termId})
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSelecting(false)
        }
    }

    const onUnRegister = async () => {
        try {
            setIsUnRegistering(true)
            await academicPlansService.unregisterTerm({academicPlanTermId: term.id})
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsUnRegistering(false)
        }
    }

    const renderDotInfo = () => {
        const dates = (workdays || []).slice(totalCourseDay, totalCourseDay + addCourseDayRange)

        return (
            <>
                <DaysInfo dates={dates} />
                <DotContent prevCourseDay={totalCourseDay} dateRange={addCourseDayRange + 1} />
                <TimeContent
                    times={times}
                    dateRange={addCourseDayRange + 1}
                    eventData={eventData}
                    onClickAddEvent={onClickAddEventInAdditionalDay}
                />
            </>
        )
    }

    const renderLeftHeader = () => {
        if (isInAcademicPlan) {
            const hasRegistered = !!term.registeredDate
            const canRegister =
                !hasRegistered &&
                !!term.termId &&
                moment(term.registration_start_date).isBefore(moment()) &&
                moment(term.registration_end_date).isAfter(moment())
            return (
                <div className={styles.leftHeader}>
                    <div className={styles.termHeader}>
                        <span className={styles.termTitle}>Term</span>
                        <span className={styles.termInfo}>{term.name}</span>
                    </div>
                    {hasRegistered && (
                        <>
                            <div className={styles.registeredWrap}>
                                <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.registeredIcon} />
                                <span className={styles.registeredTitle}>Registered</span>
                            </div>
                            <BaseButton
                                title="Unregister"
                                variant="secondary"
                                onClick={onUnRegister}
                                loading={isUnRegistering}
                            />
                        </>
                    )}
                    {canRegister && (
                        <BaseButton title="Register for Term" onClick={onClickRegisterTerm} loading={isRegistering} />
                    )}
                </div>
            )
        }
        const options = (selectableTerms || []).filter((item) => item.termId !== term.scheduledTermId)
        const title = isInTermSettingPreview && term.scheduledTermId ? "Term Start Cohort" : "Term"
        return (
            <div className={styles.leftHeader}>
                <div className={styles.termHeader}>
                    <span className={styles.termTitle}>{title}</span>
                    <span className={styles.termInfo}>{term.scheduledTermId ? term.name : index + 1}</span>
                </div>
                {isInTermSettingPreview && index !== 0 && (
                    <div className={styles.selectableTerm}>
                        <KlassDropdown
                            options={options}
                            valueKey="termId"
                            value={selectableTerm}
                            onChange={onChangeSelectableTerm}
                        />
                        {isSelecting && <BaseLoading isShow />}
                    </div>
                )}
            </div>
        )
    }

    const renderDragContent = () => {
        if (isCalendarBasedMultiCourse) {
            return term.groupCourses.map((group, index) => (
                <Draggable
                    draggableId={`${group.groupId}`}
                    index={index}
                    key={group.groupId}
                    isDragDisabled={!isInMajorVersion}>
                    {(provided, snapshot) =>
                        renderMultiCourseItem(group, index, {
                            provided,
                            snapshot,
                            isDraggable: true
                        })
                    }
                </Draggable>
            ))
        }
        return term.courses.map((course, index) => (
            <Draggable draggableId={`${course.id}`} index={index} key={course.id} isDragDisabled={!isInMajorVersion}>
                {(provided, snapshot) =>
                    renderCourseItem(course, index, {
                        provided,
                        snapshot,
                        isDraggable: true
                    })
                }
            </Draggable>
        ))
    }

    const renderBody = () => {
        if (!isShowBody) {
            return null
        }
        if (isDragging) {
            return <BaseLoading isShow />
        }
        return (
            <div className={styles.termBody}>
                <div className={styles.termBody__timeInfo}>
                    {hasTermDetail && <div className={styles.dateTitle}>Date</div>}
                    <div className={styles.termBody__dot}>DOT</div>
                    {times.map((time, index) => {
                        const timeStr =
                            model.user?.timeFormat === Auth.TimeFormat.Time_12
                                ? moment(time).format("hh:mm A")
                                : moment(time).format("HH:mm")
                        return (
                            <div key={index} className={styles.timeWrap}>
                                <span
                                    className={cx(styles.timeTitle, {
                                        [styles.timeTitle__large]: model.user?.timeFormat === Auth.TimeFormat.Time_12
                                    })}>
                                    {timeStr}
                                </span>
                            </div>
                        )
                    })}
                </div>
                <PerfectScrollbar className={cx("custom-scroll", styles.coursesWrap)}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="courseSequence" direction="horizontal">
                            {(droppableProvided) => (
                                <div
                                    ref={droppableProvided.innerRef}
                                    className={styles.coursesBody}
                                    {...droppableProvided.droppableProps}>
                                    {renderDragContent()}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div>
                        {!isInAcademicPlan && (
                            <div
                                className={styles.addCourseBtnWrap}
                                style={{minWidth: `${addCourseBtnWidth}px`, height: hasTermDetail ? "32px" : "64px"}}
                                onClick={onClickAddCourse}>
                                <Icon icon="PLUS" />
                                <span className={styles.addCourseBtn}>Add Course</span>
                            </div>
                        )}
                        {addCourseDayRange > 0 && renderDotInfo()}
                    </div>
                </PerfectScrollbar>
            </div>
        )
    }

    const renderRegistrationHold = () => {
        return (
            <div className={styles.registrationHoldWrap}>
                <span className={styles.registrationHold__title}>Registration Hold</span>
                <div className={styles.switchWrap}>
                    <span
                        className={cx(styles.switchItem, {
                            [styles.switchItem__active]: !term.isRegistrationHold
                        })}>
                        Off
                    </span>
                    <Switch checked={isRegistrationHold} onChange={(checked) => updateRegistrationHold(checked)} />
                    <span
                        className={cx(styles.switchItem, {
                            [styles.switchItem__active]: !!term.isRegistrationHold
                        })}>
                        On
                    </span>
                </div>
            </div>
        )
    }

    const onDeleteTerm = () => {
        deleteTermPopup.open()
    }

    const renderRightHeader = () => {
        if (isInTermSettingPreview) {
            return (
                <div className={styles.rightHeader}>
                    {renderRegistrationHold()}
                    <Tooltip title="Delete Term">
                        <div className={styles.deleteIconWrap} onClick={onDeleteTerm}>
                            <Icon icon="DELETE" className={styles.deleteIcon} />
                        </div>
                    </Tooltip>
                    <div
                        className={cx(styles.iconWrap, {[styles.iconCollapse]: isShowBody})}
                        onClick={onToggleShowBody}>
                        <Icon icon="CHEVRON_DOWN" color="#666" />
                    </div>
                </div>
            )
        } else if (isInMajorVersion) {
            return (
                <Tooltip title="Delete Term">
                    <div className={styles.deleteIconWrap} onClick={onDeleteTerm}>
                        <Icon icon="DELETE" className={styles.deleteIcon} />
                    </div>
                </Tooltip>
            )
        }
        return null
    }

    return (
        <div key={term.id} className={styles.termItemWrap}>
            <div className={styles.header}>
                {renderLeftHeader()}
                {renderRightHeader()}
            </div>
            {renderBody()}
            {addCoursePopup.isVisible && (
                <AddCoursesPopup
                    isShow={addCoursePopup.isVisible}
                    onClose={addCoursePopup.close}
                    versionData={versionData}
                    programTermTemplateId={term.id}
                    termCalendarBaseId={term.id}
                    isInMajorVersion={isInMajorVersion}
                    isInAcademicPlan={isInAcademicPlan}
                    isInTermSettingPreview={isInTermSettingPreview}
                    majorVersionId={majorVersionId}
                    studentProfileId={studentProfileId}
                    position={getNextCoursePosition()}
                    reloadView={reloadView}
                />
            )}
            {addMultiCoursesPopup.isVisible && (
                <AddMultiCoursesPopup
                    isShow={addMultiCoursesPopup.isVisible}
                    onClose={addMultiCoursesPopup.close}
                    versionData={versionData}
                    programTermTemplateId={term.id}
                    termCalendarBaseId={term.id}
                    isInMajorVersion={isInMajorVersion}
                    isInAcademicPlan={isInAcademicPlan}
                    isInTermSettingPreview={isInTermSettingPreview}
                    majorVersionId={majorVersionId}
                    studentProfileId={studentProfileId}
                    position={getNextCoursePosition()}
                    reloadView={reloadView}
                />
            )}
            <ConfirmPopup
                isVisible={deleteTermPopup.isVisible}
                title="Are you sure want to delete this term?"
                onClose={deleteTermPopup.close}
                onConfirm={() => deleteTerm(term.id)}
            />
        </div>
    )
}
