/* eslint-disable react-hooks/exhaustive-deps */
import {Communication} from "types/communication"
import cx from "classnames"
import styles from "./Message.module.css"
import {
    copyTextToClipboard,
    displaySince,
    displaySinceWithDayInWeek,
    embedVideoLink,
    formatAmOrPM,
    handleErrorChat,
    replaceVideoLinksWithIframes,
    toastInfo,
    toastSuccess
} from "helpers"
import {useModel, useVisible} from "hooks"
import {UserAvatar} from "../UserAvatar"
import {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {ReactComponent as MenuIcon} from "./assets/menu.svg"
import {ReactComponent as DateIcon} from "./assets/date.svg"
import {ReactComponent as TimeIcon} from "./assets/time.svg"
import {ReactComponent as SmsIcon} from "./assets/sms.svg"
import {ReactComponent as EmailIcon} from "./assets/email.svg"
import {ReactComponent as PinIcon} from "./assets/pin.svg"
import {ReactComponent as LinkIcon} from "./assets/link.svg"
import {ReactComponent as RemindIcon} from "./assets/remind.svg"
import {ReactComponent as DoneIcon} from "./assets/done.svg"
import {MessageOptionItem, MessageOptions} from "../MessageOptions"
import {ReplyMessage} from "../ReplyMessage"
import {EmojiPicker} from "components/Messenger/Chat/parts/EmojiPicker"
import {chatServiceV2} from "services"
import {BaseLoading, Icon} from "components"
import {MediaViewer, PollV2} from "./parts"
import {Dropdown, Menu, Tooltip} from "antd"
import moment from "moment"
import {CommunicationContext} from "context/CommunicationContext"
import {ChatRoomContext} from "context/ChatRoomContext"
import PreviewPane from "components/HTMLPreviewEditor/PreviewPane"
import ChatWsV2 from "sections/NewCommunication/chatWsV2"
import {ReactComponent as ReplyEmailIcon} from "./assets/replyEmail.svg"
import {ReactComponent as ReplySmsIcon} from "./assets/replySms.svg"
import {LinkifyContent} from "components/ui"
import linkifyStr from "linkify-string"
import * as DOMPurify from "dompurify"
import linkifyHtml from "linkify-html"

export type MessageProps = {
    message: Communication.Message
    showTimestampSection?: boolean
    prevMessageTime?: string
    containerStyle?: string
    showOptions?: boolean
    compactMode?: boolean
    showScheduled?: boolean
    showFollowUp?: boolean
    followUpMessage?: Communication.FollowUpMessage
    navigate?: (_message: Communication.Message) => void
    onEditMessage?: (_message: Communication.Message) => void
    onReplyMessage?: (_message: Communication.Message) => void
    onDeleteMessage?: (messageId: number) => void
    onDoneFollowUp?: (followUpId: number) => void
    onUpdateFollowUp?: (_followUpMessage: Communication.FollowUpMessage, _time: number) => void
    onPublishMessage?: (messageId: number) => void
    highlightStudentMessage?: boolean
    onClickReplyMessage?: (_msg: Communication.ReplyMessage) => void
}

const MESSAGE_TYPES = {
    room: "New Thread",
    message: "Comment"
}

export function Message(props: MessageProps) {
    const {
        message: initialMessage,
        containerStyle,
        showOptions = false,
        compactMode = true,
        showScheduled = false,
        showFollowUp = false,
        showTimestampSection = false,
        prevMessageTime,
        followUpMessage,
        highlightStudentMessage,
        navigate,
        onEditMessage,
        onReplyMessage,
        onDeleteMessage,
        onDoneFollowUp,
        onUpdateFollowUp,
        onPublishMessage,
        onClickReplyMessage
    } = props
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const currentUserId = model.user.id
    const contextMenu = useVisible(false)
    const emojiPopup = useVisible(false)
    const mediaViewerPopup = useVisible(false)
    const [message, setMessage] = useState<Communication.Message>(initialMessage)
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const {changeRoom, activeRoom, viewAs, foundMessageId} = useContext(CommunicationContext)
    const {activePageId} = useContext(ChatRoomContext)

    useEffect(() => {
        const unSubscribeMessagePinStatus = ChatWsV2.registerOnMessagePinStatus(message.messageId, (data) => {
            handlerMessagePinStatus(data)
        })

        return () => {
            unSubscribeMessagePinStatus()
        }
    }, [message.messageId])

    const handlerMessagePinStatus = async (data: any) => {
        if (message.messageId === data.messageId) {
            setMessage((prev) => ({...prev, isPinned: data.isPinned}))
        }
    }

    useEffect(() => {
        setMessage(initialMessage)
    }, [initialMessage])

    const handleCopyTextClick = (text?: string) => {
        copyTextToClipboard(text || "").then(() => toastInfo(`Copied: ${text}`))
    }

    const isMine = model.user.id === message.author?.userId

    const showLinkChannel = showScheduled || (showFollowUp && followUpMessage && !!followUpMessage.room)

    const isThreadCommentOrMessage =
        activeRoom.type !== Communication.RoomTypes.Channel || activePageId === Communication.ChannelPages.ThreadDetails

    const isThread =
        activeRoom.type === Communication.RoomTypes.Channel && activePageId === Communication.ChannelPages.Home

    const isDirectMessage = activeRoom.type === Communication.RoomTypes.DirectMessage

    const messageOptionItems = useMemo(() => {
        const items: MessageOptionItem[] = []
        const isUneditable = message.withEmail || message.withSms || message.type === "poll"
        const isSupportRoom = activeRoom.type === Communication.RoomTypes.OpenedSupportHub
        const isHost = activeRoom.role === Communication.RoomRole.Admin

        if (isThreadCommentOrMessage) {
            items.push({
                icon: "EMOJI_2",
                title: "Send reaction",
                visible: true,
                onClick: emojiPopup.open
            })
        }

        if (!isSupportRoom) {
            items.push({
                icon: "FOLLOW_UP",
                title: "Follow up",
                visible: true,
                onClick: (data) => onFollowUpMessage(data)
            })
        }

        if (isMine && !!onEditMessage && !isUneditable) {
            items.push({
                icon: "EDIT_LINE",
                title: "Edit",
                visible: true,
                onClick: () => onEditMessage?.(message)
            })
        }

        if (!!onReplyMessage) {
            items.push({
                icon: "REPLY",
                title: "Reply to a message",
                visible: true,
                onClick: () => onReplyMessage?.(message)
            })
        }

        if (isThreadCommentOrMessage && !isSupportRoom) {
            items.push({
                icon: "PUSH_PIN",
                title: `${message.isPinned ? "Unpin" : "Pin"} message`,
                visible: true,
                onClick: () => onPinMessage()
            })
            if (!isMine) {
                items.push({
                    icon: "EYE_CROSSED_LINE",
                    title: "Mark unread",
                    visible: true,
                    onClick: () => onUnreadMessage()
                })
            }
        }

        if (isThread) {
            items.push({
                icon: "COPY",
                title: "Copy link",
                visible: true,
                onClick: () => {
                    handleCopyTextClick(
                        `${window.origin}/communication?roomId=${activeRoom.roomId}&threadId=${message.messageId}`
                    )
                }
            })
        }

        if (isMine || (isThread && isHost)) {
            items.push({
                icon: "TRASH_LINE",
                title: "Delete",
                visible: true,
                onClick: () => onDeleteMessage?.(message.messageId)
            })
        }

        if (message.type === "poll" && message.poll) {
            const poll = message.poll!
            if (!poll.isClosed && poll.ownerId === currentUserId) {
                items.push({
                    icon: "CROSS_CIRCLE_FILL",
                    title: "Stop Poll",
                    visible: true,
                    onClick: () => onStopPoll()
                })
            }

            if (poll.hasVoted && poll.type !== "quiz" && !poll.isClosed) {
                items.push({
                    icon: "REPEAT",
                    title: "Retract Vote",
                    visible: true,
                    onClick: () => onRetractVote()
                })
            }
        }

        return items
    }, [message])

    const shouldNavigate = useMemo(() => {
        return !!navigate
    }, [navigate])

    const scheduleThreadMenu = (
        <Menu className={styles.dropdownMenu}>
            {message.type !== "poll" && (
                <Menu.Item
                    icon={<Icon icon="EDIT_LINE" className={styles.menuIcon} />}
                    onClick={() => onEditMessage?.(message)}>
                    Edit thread
                </Menu.Item>
            )}
            <Menu.Item
                icon={<Icon icon="FOLLOW_UP" className={styles.menuIcon} />}
                onClick={() => onPublishMessage?.(message.messageId)}>
                Post now
            </Menu.Item>
            <Menu.Item
                icon={<Icon icon="TRASH_LINE" className={styles.menuIcon} />}
                onClick={() => onDeleteMessage?.(message.messageId)}>
                Delete thread
            </Menu.Item>
        </Menu>
    )

    const followUpRemindMenu = useMemo(() => {
        return (
            <Menu className={styles.dropdownMenu}>
                {Communication.FOLLOW_UP_TYPES.map((x) => (
                    <Menu.Item
                        key={x.label}
                        onClick={() =>
                            onUpdateFollowUp(followUpMessage, x.value)
                        }>{`Remind me in ${x.label}`}</Menu.Item>
                ))}
            </Menu>
        )
    }, [followUpMessage])

    const onAddReaction = async (e) => {
        onToggleReaction(e.native)
        emojiPopup.close()
    }

    const onToggleReaction = useCallback(async (reaction: string) => {
        if (viewAs) return
        try {
            setIsUpdating(true)
            await chatServiceV2.reactMessage({
                messageId: message.messageId,
                reaction
            })
            setMessage((prev) => {
                const _reactions = [...(prev.reactions || [])]
                const existReactionIdx = _reactions.findIndex((x) => x.reaction === reaction)
                if (existReactionIdx >= 0) {
                    const _existReaction = _reactions[existReactionIdx]
                    const _newCount = _existReaction.count + (_existReaction.me ? -1 : 1)
                    if (_newCount > 0) {
                        _reactions[existReactionIdx] = {
                            reaction,
                            count: _existReaction.count + (_existReaction.me ? -1 : 1),
                            me: !_existReaction.me
                        }
                    } else {
                        _reactions.splice(existReactionIdx, 1)
                    }
                } else {
                    _reactions.push({
                        reaction,
                        count: 1,
                        me: true
                    })
                }
                return {...prev, reactions: _reactions}
            })
        } catch (err) {
            handleErrorChat(err)
        } finally {
            setIsUpdating(false)
        }
    }, [])

    const onPinMessage = async () => {
        try {
            setIsUpdating(true)
            await chatServiceV2.pinMessage({
                messageId: message.messageId
            })
            const isPinned = !message.isPinned
            setMessage((prev) => ({...prev, isPinned}))
            toastSuccess(`You have ${isPinned ? "pinned" : "unpinned"} a message`)
        } catch (err) {
            handleErrorChat(err)
        } finally {
            setIsUpdating(false)
        }
    }

    const onUnreadMessage = async () => {
        try {
            setIsUpdating(true)
            await chatServiceV2.unreadMessage({
                messageId: message.messageId
            })
        } catch (err) {
            handleErrorChat(err)
        } finally {
            setIsUpdating(false)
        }
    }

    const onFollowUpMessage = async (time: number) => {
        try {
            setIsUpdating(true)
            await chatServiceV2.followUp({
                sourceId: message.messageId,
                sourceType: isThread ? "room" : "message",
                time
            })
            toastSuccess("Success")
        } catch (e) {
            handleErrorChat(e)
        } finally {
            setIsUpdating(false)
        }
    }

    const onRetractVote = async () => {
        try {
            if (message.type === "poll") {
                ChatWsV2.sendPacket({
                    command: Communication.SocketCommand.RetractPollVote,
                    payload: {
                        pollId: message.poll.pollId,
                        messageId: message.messageId
                    }
                })
            }
        } catch (e) {
            handleErrorChat(e)
        }
    }

    const onStopPoll = async () => {
        try {
            if (message.type === "poll") {
                await chatServiceV2.stopPoll({
                    pollId: message.poll.pollId,
                    messageId: message.messageId
                })
            }
        } catch (e) {
            handleErrorChat(e)
        }
    }

    const onDownloadFile = (file) => {
        const url = file.url
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.target = "_blank"
        a.download = file.filename
        a.click()
    }

    const goToChannel = (e) => {
        e.preventDefault()
        changeRoom(showScheduled ? message.room : followUpMessage.room)
    }

    const renderScheduledInfo = () => {
        if (!showScheduled) return null
        const userDateFormat = model.getUserDateFormat()
        const userTimeFormat = model.getUserTimeFormat()

        return (
            <div className={styles.scheduledInfo}>
                <div className={styles.scheduledInfoContainer}>
                    <span className={styles.scheduledTag}>Scheduled</span>
                    <div className={styles.scheduledValueTag}>
                        <div className={styles.scheduledValueWrap}>
                            <DateIcon className={styles.scheduledIcon} />
                            <span className={styles.scheduledValue}>
                                {moment(message.scheduledAt).format(userDateFormat)}
                            </span>
                        </div>
                        <div className={styles.scheduledValueWrap}>
                            <TimeIcon className={styles.scheduledIcon} />
                            <span className={styles.scheduledValue}>
                                {moment(message.scheduledAt).format(userTimeFormat)}
                            </span>
                        </div>
                    </div>
                </div>
                {!viewAs && (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown overlay={scheduleThreadMenu} trigger={["hover"]} className={styles.iconWrap}>
                            <MenuIcon className={styles.icon} />
                        </Dropdown>
                    </div>
                )}
            </div>
        )
    }

    const renderInfo = () => {
        if (compactMode) {
            return (
                <div className={styles.infoContainer}>
                    {renderScheduledInfo()}
                    <div className={styles.info}>
                        {showFollowUp && (
                            <span className={styles.tag}>{MESSAGE_TYPES[followUpMessage?.sourceType]}</span>
                        )}
                        {message.author && (
                            <>
                                <UserAvatar user={message.author} className={styles.avatar} />
                                <span className={styles.fullName}>{message.author.fullName}</span>
                            </>
                        )}
                        {showLinkChannel && (
                            <>
                                <div className={styles.dot} />
                                <div className={styles.channelWrap} onClick={goToChannel}>
                                    <span className={styles.channelName}>
                                        {showScheduled ? message.room.name : followUpMessage.room.name}
                                    </span>
                                    <LinkIcon className={styles.channelIcon} />
                                </div>
                            </>
                        )}
                        <div className={styles.dot} />
                        <span className={styles.timestamp}>
                            {showTimestampSection
                                ? formatAmOrPM(new Date(message.createdAt))
                                : displaySince(new Date(message.createdAt), userDateTimeFormat)}
                        </span>
                        {message.isThread && (
                            <>
                                <div className={styles.dot} />
                                <span className={styles.timestamp}>{`${message.totalComments} ${
                                    message.totalComments > 1 ? "comments" : "comment"
                                }`}</span>
                                {message.unread > 0 && <span className={styles.unreadCount}>{message.unread}</span>}
                            </>
                        )}
                        {message.withSms && (
                            <div className={styles.attContainer}>
                                <SmsIcon className={styles.attIcon} />
                            </div>
                        )}
                        {message.withEmail && (
                            <div className={styles.attContainer}>
                                <EmailIcon className={styles.attIcon} />
                            </div>
                        )}
                        {message.jsonData?.replyFromEmail && (
                            <div className={styles.attContainer}>
                                <ReplyEmailIcon className={styles.attIcon} />
                            </div>
                        )}
                        {message.jsonData?.replyFromSms && (
                            <div className={styles.attContainer}>
                                <ReplySmsIcon className={styles.attIcon} />
                            </div>
                        )}
                        {message.author.userId === currentUserId && message.isRead && (
                            <Icon icon="MESSAGE_READ" className={styles.tagIcon} />
                        )}
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.expandedInfo}>
                {message.author && <UserAvatar user={message.author} className={styles.expandedAvatar} />}
                <div className={styles.expandedAuthor}>
                    <span className={styles.fullName}>{message.author?.fullName}</span>
                    <span className={styles.timestamp}>
                        {displaySince(new Date(message.createdAt), userDateTimeFormat)}
                    </span>
                </div>
            </div>
        )
    }

    const renderAttachment = () => {
        const {file} = message
        if (!file || !file.url) return null

        if (file.type === Communication.AttachedFileType.Image) {
            return (
                <div
                    className={styles.imgInner}
                    onClick={(e) => {
                        e.stopPropagation()
                        mediaViewerPopup.open()
                    }}>
                    <img className={styles.image} src={file.url} alt="" />
                </div>
            )
        }

        if (file.type === Communication.AttachedFileType.Video) {
            const embedLink: string | null = embedVideoLink(file.url)
            return embedLink ? (
                <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(replaceVideoLinksWithIframes(embedLink), {
                            ADD_TAGS: ["iframe"],
                            ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"]
                        })
                    }}
                />
            ) : (
                <div
                    className={styles.videoInner}
                    onClick={(e) => {
                        e.stopPropagation()
                        mediaViewerPopup.open()
                    }}>
                    <span className={styles.playVideoIconContainer}>
                        <Icon icon="PLAY_VIDEO" className={styles.playVideoIcon} color="#FAFAFA" />
                    </span>
                    <div className={styles.videoOverlay} />
                    <video
                        className={styles.image}
                        src={file.url}
                        autoPlay={false}
                        controls={false}
                        poster={file.poster}
                    />
                </div>
            )
        }

        return (
            <div className={styles.fileInner}>
                <Icon icon="FILE" color="#939393" />
                <div>
                    <p title={file.filename}>{file.filename}</p>
                </div>
                <span
                    className={styles.downloadWrapper}
                    onClick={(e) => {
                        e.stopPropagation()
                        onDownloadFile(file)
                    }}>
                    <Icon icon="DOWNLOAD" color="#939393" />
                </span>
            </div>
        )
    }

    const renderReactions = () => {
        return (
            <div className={styles.reactionsContainer}>
                {(message.reactions || []).map((x, index) => {
                    return (
                        <div
                            className={cx(styles.reactionWrap, {[styles.myReaction]: x.me})}
                            key={index}
                            onClick={() => onToggleReaction(x.reaction)}>
                            <span className={styles.reaction}>{x.reaction}</span>
                            <span className={styles.reactionCount}>{x.count}</span>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderTimestampHeader = () => {
        if (showTimestampSection) {
            let showTimestamp: boolean = true
            if (prevMessageTime) {
                showTimestamp = moment(message.createdAt).isAfter(prevMessageTime, "day")
            }
            if (showTimestamp) {
                return (
                    <div className={styles.separator}>
                        {displaySinceWithDayInWeek(message.createdAt, model.getUserDateFormat())}
                    </div>
                )
            }
        }

        return null
    }

    const renderSystemMessage = () => {
        const {type: systemType} = message.jsonData
        switch (systemType) {
            case "roomNameChanged":
                return (
                    <span>
                        <b>{message.author.fullName}</b>
                        {` changed the chat name to `}
                        <b>{message.jsonData?.name}</b>
                    </span>
                )
            case "roomMemberAdded": {
                const names = (message.jsonData?.users || []).map((u) => `<b>${u.fullName}</b>`).join(", ")
                return (
                    <span>
                        <b>{message.author.fullName}</b>
                        {` added `}
                        <span dangerouslySetInnerHTML={{__html: names}} />
                        {` to the group.`}
                    </span>
                )
            }
            case "roomMemberRemoved": {
                const names = (message.jsonData?.users || []).map((u) => `<b>${u.fullName}</b>`).join(", ")
                return (
                    <span>
                        <b>{message.author.fullName}</b>
                        {` removed `}
                        <span dangerouslySetInnerHTML={{__html: names}} />
                        {` from the group.`}
                    </span>
                )
            }
            case "pinMessage":
                return (
                    <span>
                        <b>{message.author.fullName}</b>
                        {` pinned `}
                        <b>a message</b>
                    </span>
                )
            case "unpinMessage":
                return (
                    <span>
                        <b>{message.author.fullName}</b>
                        {` unpinned `}
                        <b>a message</b>
                    </span>
                )
            case "outOfOfficeReply":
                return (
                    <span className={styles.systemMessageOutOfOffice}>
                        <b>{message.author.fullName}</b>
                        {" is out of office "}
                        {message.jsonData?.outOfOfficeEndAt
                            ? ` - back on ${moment(message.jsonData?.outOfOfficeEndAt).format("llll")}`
                            : null}
                        <br />
                        {message.content}
                    </span>
                )
            default:
                return <span>{message.content}</span>
        }
    }

    if (message.type === "system") {
        return (
            <div className={styles.messageContainer} id={`message-${message.messageId}`}>
                <div className={styles.systemMessageContainer}>{renderSystemMessage()}</div>
            </div>
        )
    }

    const renderContent = () => {
        if (
            message.jsonData?.email?.editorType === Communication.EditorType.Html ||
            message.jsonData?.source?.editorType === Communication.EditorType.Html
        ) {
            return <PreviewPane markdown={message.content} />
        }

        if (message.type === "poll" && message.poll) {
            return (
                <>
                    <div className={styles.content}>
                        <LinkifyContent content={message.content} />
                    </div>
                    <PollV2 poll={message.poll} messageId={message.messageId} />
                </>
            )
        }

        /**
         * NOTE
         * New lib "linkify-string" was used to fix showing special characters, issue reported at
         * https://github.com/Hypercontext/linkifyjs/issues/338
         */

        const linkifyText =
            message.withEmail || message.withSms || message.jsonData?.replyFromEmail || isDirectMessage
                ? linkifyHtml(message.content || "")
                : linkifyStr(message.content || "")

        return (
            <>
                <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(replaceVideoLinksWithIframes(linkifyText), {
                            ADD_TAGS: ["iframe"],
                            ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"]
                        })
                    }}
                />
                {message.editedAt && (
                    <Tooltip title={moment(message.editedAt).format(userDateTimeFormat)}>
                        <span className={styles.edited}>(edited)</span>
                    </Tooltip>
                )}
            </>
        )
    }

    return (
        <div className={styles.messageContainer} id={`message-${message.messageId}`}>
            {renderTimestampHeader()}
            <div
                className={cx(
                    styles.container,
                    {[styles.navigateWrap]: shouldNavigate, [styles.highlightWrap]: highlightStudentMessage},
                    containerStyle,
                    {[styles.animatePulse]: foundMessageId === message.messageId}
                )}
                onClick={() => navigate?.(message)}
                onMouseEnter={contextMenu.open}
                onMouseLeave={contextMenu.close}>
                <div className={styles.heading}>
                    {renderInfo()}
                    <div className={styles.rightHeading}>
                        {message.isPinned && (
                            <div className={styles.attContainer}>
                                <PinIcon className={styles.attIcon} />
                            </div>
                        )}
                        {!message.deletedAt && showOptions && contextMenu.isVisible && (
                            <MessageOptions onClose={contextMenu.close} items={messageOptionItems} />
                        )}
                        {showFollowUp && (
                            <>
                                <div className={styles.followUpTag}>
                                    <span className={styles.followUpTagTitle}>Followup reminder</span>
                                    <RemindIcon className={styles.tagIcon} />
                                </div>
                                <div
                                    className={styles.followUpAction}
                                    onClick={() => onDoneFollowUp(followUpMessage?.followUpId)}>
                                    <DoneIcon className={styles.followUpIcon} />
                                </div>
                                <div className={styles.followUpAction}>
                                    <Dropdown overlay={followUpRemindMenu} trigger={["hover"]} placement="bottomRight">
                                        <RemindIcon className={styles.followUpIcon} />
                                    </Dropdown>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.main}>
                    {message.replyTo && message.replyTo.messageId && (
                        <ReplyMessage
                            message={message.replyTo}
                            containerStyle={styles.replyContainer}
                            onClick={(e) => {
                                e.stopPropagation()
                                onClickReplyMessage?.(message.replyTo)
                            }}
                        />
                    )}
                    {message.title && (
                        <span className={styles.title}>
                            <LinkifyContent content={message.title} />
                        </span>
                    )}
                    {renderContent()}
                    {renderAttachment()}
                </div>
                {renderReactions()}
                {isUpdating && <BaseLoading isShow={isUpdating} />}
            </div>
            {message.file && message.file.url && (
                <MediaViewer isShow={mediaViewerPopup.isVisible} onClose={mediaViewerPopup.close} file={message.file} />
            )}
            <EmojiPicker
                isShow={emojiPopup.isVisible}
                onClose={emojiPopup.close}
                onSelect={onAddReaction}
                className={cx(styles.emojiPicker)}
            />
        </div>
    )
}
