/* eslint-disable react-hooks/exhaustive-deps */
import {AppliedFilter, FieldType, FilterKey} from "types/filter"
import {ViewGridType} from "types/settings/documentType"
import cx from "classnames"
import styles from "./DocumentFilter.module.css"
import {BaseButton, Icon, IconType, KlassDropdown} from "components"
import {Tooltip} from "antd"
import {FilterField, KlassappFilters} from "uiKit"
import {useMemo} from "react"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {checkDocumentDepartmentPermission, checkPermission, getEnableSubunitsByDepartmentPermission} from "helpers"
import {useModel} from "hooks"
import {Permissions, PermissionType} from "types/permission"
import {Auth} from "types/auth"
import {UserDocumentStatus} from "types/user-documents"
import ProgramSelect from "components/ProgramSelect"
import TermSelect from "components/TermSelect"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import UserSelectV3 from "components/UserSelectV3"

type Props = {
    filterKey?: FilterKey
    viewTypes?: ViewGridType[]
    onApplyFilter: (payload: AppliedFilter) => void
    initialFilter?: AppliedFilter
    onClearFilter: () => void
    viewType?: ViewGridType
    onChangeViewType?: Function
    userId?: number
    onClickAdd?: () => void
}

const VIEW_TYPE_LABELS: Record<ViewGridType, {label: string; iconName: IconType}> = {
    blocks: {
        label: "Blocks",
        iconName: "VIEW_BLOCKS"
    },
    list: {
        label: "List",
        iconName: "VIEW_LIST"
    }
}

export function DocumentFilter(props: Props) {
    const model = useModel()
    const {
        filterKey,
        viewTypes,
        viewType,
        onChangeViewType,
        initialFilter,
        onApplyFilter,
        onClearFilter,
        userId,
        onClickAdd
    } = props
    const accessibleSubunitIds = useMemo(() => {
        return getEnableSubunitsByDepartmentPermission(PermissionType.View, model, "Documents")
    }, [])

    const checkCanAddDocument = () => {
        if (model.isAdmin()) {
            return true
        }
        if (model.isStaff()) {
            return model.departments.some((department) =>
                department.subunits.some((subunit) =>
                    checkDocumentDepartmentPermission(
                        PermissionType.Add,
                        model,
                        department.departmentId,
                        subunit.subunitId
                    )
                )
            )
        }
        return checkPermission({student: [Permissions.Student.Documents.Add]}, model)
    }

    const availableFilters = useMemo(() => {
        let filters: FilterField[] = [
            {
                field: "departmentSubunit",
                label: "Department",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <DepartmentSubunitSelect
                            subunitIds={accessibleSubunitIds}
                            isMulti
                            isClearable
                            placeholder="Department"
                            {...props}
                        />
                    )
                }
            },
            {
                field: "program",
                label: "Program",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <ProgramSelect isMulti placeholder="Program" {...props} />
                }
            },
            {
                field: "startingTerm",
                label: "Starting Term",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <TermSelect isMulti placeholder="Starting Term" {...props} />
                }
            },
            {
                field: "date",
                label: "Document Date",
                type: FieldType.Date
            },
            {
                field: "status",
                label: "Document Status",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={[
                                {id: UserDocumentStatus.Approved, name: "Approved"},
                                {id: UserDocumentStatus.Rejected, name: "Rejected"}
                            ]}
                            isMulti
                            placeholder="Document Status"
                            {...props}
                        />
                    )
                }
            },
            {
                field: "startDateRange",
                label: "Student Start Date",
                type: FieldType.Date
            },
            {
                field: "linkedTasks",
                label: "Linked with Tasks",
                type: FieldType.Flag
            },
            {
                field: "linkedChecklists",
                label: "Linked with Checklists",
                type: FieldType.Flag
            },
            {
                field: "linkedActivities",
                label: "Linked with Activities",
                type: FieldType.Flag
            }
        ]

        if (!userId) {
            if (model.clientSetting.isNewStudentStatusesVisible) {
                filters.unshift({
                    field: "studentStatusIds",
                    label: "Student Status",
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <NewStudentStatusSelectFilter isMulti placeholder="Student Status" {...props} />
                    }
                })
            }

            filters.unshift({
                field: "students",
                label: "Students",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <UserSelectV3 type={[Auth.UserProfileType.Student]} isMulti placeholder="Students" {...props} />
                    )
                }
            })
        }

        return filters
    }, [accessibleSubunitIds, userId])

    const renderAdditionalFilter = () => {
        const canAdd = checkCanAddDocument()

        return (
            <div className={styles.actionWrap}>
                {(["blocks", "list"] as ViewGridType[])
                    .filter((viewType) => viewTypes?.includes(viewType))
                    .map((v) => {
                        const i = VIEW_TYPE_LABELS[v]
                        return (
                            <Tooltip key={v} title={i.label}>
                                <span
                                    className={cx(styles.viewTypeIconContainer, {
                                        [styles.viewTypeIconContainerSelected]: viewType === v
                                    })}
                                    onClick={() => onChangeViewType(v)}>
                                    <Icon className={styles.viewTypeIcon} icon={i.iconName} />
                                </span>
                            </Tooltip>
                        )
                    })}
                {canAdd && (
                    <BaseButton
                        title="Add"
                        icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                        onClick={onClickAdd}
                    />
                )}
            </div>
        )
    }

    return (
        <KlassappFilters
            filterKey={filterKey}
            renderAdditionalFilter={renderAdditionalFilter}
            availableFilters={availableFilters}
            initialFilter={initialFilter}
            onApply={onApplyFilter}
            onClear={onClearFilter}
        />
    )
}
