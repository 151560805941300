import React, {useState} from "react"
import moment from "moment"
import {BaseLoading} from "components"
import {CreatedInfo} from "components/ui"
import {TaskStatus} from "types/tasks"
import {getFullName, handleError} from "helpers"
import {taskService} from "services"
import {TaskForm, TaskAnswerHeader} from "../"
import {ResponsesTable, RejectedTasks} from "../Responses/parts"
import styles from "./StudentViewDetail.module.css"
import {useModel} from "hooks"
import {RejectedTaskUsers} from "../Responses/parts/RejectedTaskUsers"
import {pick} from "lodash"

export function StudentViewDetail(props) {
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const [isLoading, setIsLoading] = useState(false)
    const [isReget, setIsReget] = useState(false)
    const {
        taskData,
        type,
        departments,
        reminders,
        signerUser,
        userId,
        openedSubTaskId,
        canAnswerOpenedSubTask,
        onChangeData,
        onSearchChange,
        onChangeSignerUsers,
        onChangeOpenedSubTaskId,
        taskId,
        signerUsers
    } = props

    const updateTaskUserStatus = async () => {
        try {
            setIsLoading(true)
            const {data} = await taskService.getSubTasks(+taskData.id, {
                range: {
                    page: 1,
                    pageSize: 1000
                }
            })
            let signerUsers = []
            data.forEach((subTask) => {
                let taskUserOverdueIndex = -1
                const signerUsersItem = subTask.users
                    .filter((taskUser, index) => {
                        if (taskUser.status === TaskStatus.OVERDUE && taskUserOverdueIndex === -1) {
                            taskUserOverdueIndex = index
                        }
                        const isTaskCurrentUserOverdue =
                            subTask.status === TaskStatus.OVERDUE &&
                            taskUser.status === TaskStatus.OVERDUE &&
                            taskUserOverdueIndex === index &&
                            taskUser.userId === userId
                        return taskUser.status === TaskStatus.TODO || isTaskCurrentUserOverdue
                    })
                    .map((item) =>
                        pick(item, ["id", "userId", "profileId", "subTaskId", "taskId", "encodedSecurityLevelWeb"])
                    )
                signerUsers = [...signerUsers, ...signerUsersItem]
            })
            if (onChangeSignerUsers) {
                onChangeSignerUsers(signerUsers)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onCloseTaskAnswerPopup = () => {
        updateTaskUserStatus()
        setIsReget(!isReget)
    }

    return (
        <div className={styles.wrap}>
            <BaseLoading isShow={isLoading} />
            {!!signerUser && canAnswerOpenedSubTask && (
                <TaskAnswerHeader
                    taskData={taskData}
                    signerUser={signerUser}
                    openedSubTaskId={openedSubTaskId}
                    onClose={onCloseTaskAnswerPopup}
                />
            )}
            <div className={styles.studentViewHeader}>
                <div className={styles.studentViewHeaderItem}>
                    <p className={styles.studentViewHeaderItem__title}>Assigned By</p>
                    <p className={styles.studentViewHeaderItem__value}>{getFullName(taskData.createdByUser)}</p>
                </div>
                <div className={styles.studentViewHeaderItem}>
                    <p className={styles.studentViewHeaderItem__title}>Requested On</p>
                    <p className={styles.studentViewHeaderItem__value}>
                        {moment(taskData.createdAt).format(dateFormat)}
                    </p>
                </div>
            </div>
            <TaskForm
                taskData={taskData}
                type={type}
                departments={departments}
                reminders={reminders}
                isStudentView
                onChangeData={onChangeData}
                onSearchChange={onSearchChange}
                onCheckTaskCode={() => {}}
                getNewLibraryNextCode={() => {}}
                taskId={taskId}
                signerUsers={signerUsers}
            />
            <CreatedInfo data={taskData} className={styles.createdInfo} userInfoKey="createdByUser" />
            <RejectedTasks taskData={taskData} />
            <RejectedTaskUsers taskData={taskData} />
            <ResponsesTable
                taskData={taskData}
                onChangeSignerUsers={onChangeSignerUsers}
                userId={userId}
                isReget={isReget}
                type={type}
                openedSubTaskId={openedSubTaskId}
                onChangeOpenedSubTaskId={onChangeOpenedSubTaskId}
            />
        </div>
    )
}
