import {difference, get, isEmpty, isNumber, isString} from "lodash"
import {YesNo, KeyDownOption} from "types/common"
import {camelCase, pascalCase, snakeCase} from "change-case"
import {i18n} from "config"
import {Auth} from "types/auth"
import {PermissionUserType, PermissionType, DepartmentPermission, BaseDepartmentPermission} from "types/permission"
import {Model} from "Model"
import {DepartmentPermissionTarget, PermissionType as EdularPermissionType, Permissions} from "@edular/permissions"
import {BaseDepartmentId} from "types/departments"
import {AwardedDegree, CredentialLevel, DegreeLevel} from "types/degreeLevel"
import moment from "moment-timezone"

export const yesNoToBool = (value: YesNo) => value === YesNo.Yes
export const ensureBoolean = (value: any): boolean => {
    if (typeof value === "number") {
        return value === 1
    }
    return value as boolean
}
export const roundTwoDigits = (num: number) => {
    if (typeof num === "number" && num !== 0) {
        return Math.round(num * 100) / 100
    }
    return 0
}

export const roundMinutesToNearestIntervalWithGracePeriod = (
    minutes: number,
    interval: number = 30,
    gracePeriod: number = 10
) => {
    let remainder = minutes % interval
    if (interval - remainder < gracePeriod) {
        return minutes - (remainder % gracePeriod) + gracePeriod
    } else {
        return minutes - (remainder % gracePeriod)
    }
}

export const formatSsn = (value) => {
    const numbers = value.replace(/[^0-9]/g, "")
    let ssnValue: string = numbers.split("-").join("")
    if (value.length > 3) {
        ssnValue = [ssnValue.slice(0, 3), "-", ssnValue.slice(3)].join("")
    }
    if (ssnValue.length > 6) {
        ssnValue = [ssnValue.slice(0, 6), "-", ssnValue.slice(6)].join("")
    }
    return ssnValue
}

export function convertTZ(date, tzString) {
    return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
            timeZone: tzString ?? moment.tz.guess()
        })
    )
}

export const getBaseUrl = () => window.location.protocol + "//" + window.location.host

export const sleep = async (ms: number): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, ms))

export const boolToYesNo = (value: boolean | null | undefined, defaultValue: YesNo = YesNo.No): YesNo => {
    if (value === true) {
        return YesNo.Yes
    }
    if (value === false) {
        return YesNo.No
    }

    return defaultValue
}

export const boolToString = (value: boolean | null | undefined) => {
    return typeof value === "boolean" ? value.toString() : ""
}

export const isSpaceKey = (value: KeyDownOption.Space | KeyDownOption.SpaceCode) =>
    value === KeyDownOption.Space || value === KeyDownOption.SpaceCode

export const DEFAULT_SPACE = ""

export const replaceSpaces = (objectKey) => objectKey.replace(/\s\s+/g, DEFAULT_SPACE)

type ChangeCaseFn = (key: string) => string

export const changeObjectKeysCase = (target: object, changeCaseFn: ChangeCaseFn): object =>
    Object.entries(target).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [changeCaseFn(key)]:
                value !== null && typeof value === "object" && !Array.isArray(value)
                    ? changeObjectKeysCase(value, changeCaseFn)
                    : value
        }),
        {}
    )

export const convertObjectKeysToSnakeCase = (target: object) => changeObjectKeysCase(target, snakeCase)

export const convertObjectKeysToCamelCase = (target: object) => changeObjectKeysCase(target, camelCase)

export const checkPermission = (permissions: PermissionUserType, model: Model): boolean => {
    const userProfileType = get(model, "myPermissions.type")
    if (userProfileType === Auth.UserProfileType.Admin) {
        return true
    }
    const permissionsByType = get(permissions, [userProfileType], null)
    if (!permissionsByType) {
        return false
    }
    const permissionIds = get(model, "myPermissions.enabledPermissionIds", [])
    const diffPermissions = difference(permissionsByType, permissionIds)
    return diffPermissions.length !== permissionsByType.length
}

export const checkDepartmentPermission = (
    model: Model,
    departmentId: number,
    subunitId: number,
    target: DepartmentPermissionTarget,
    type: EdularPermissionType
): boolean => {
    const userProfileType = get(model, "myPermissions.type")
    if (userProfileType === Auth.UserProfileType.Admin) {
        return true
    }
    const permissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    return permissionIds.includes(
        get(
            model.departmentPermissions,
            [departmentId, subunitId, ...target.split(".").map((group) => pascalCase(group)), pascalCase(type)].join(
                "."
            )
        )
    )
}

export function checkHasPermissionToUpdateAdvisor(departmentId: number, model: Model): boolean {
    switch (departmentId) {
        case BaseDepartmentId.Admissions:
            return checkPermission({staff: [Permissions.Staff.Admissions.Students.UpdateAdvisor.Edit]}, model)
        case BaseDepartmentId.Academics:
            return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.UpdateAdvisor.Edit]}, model)
        case BaseDepartmentId.FinancialAid:
            return checkPermission({staff: [Permissions.Staff.FinancialAid.Students.UpdateAdvisor.Edit]}, model)
        case BaseDepartmentId.StudentAccount:
            return checkPermission({staff: [Permissions.Staff.StudentAccount.Students.UpdateAdvisor.Edit]}, model)
        case BaseDepartmentId.StudentServices:
            return checkPermission(
                {
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.UpdateAdvisor.Edit
                    ]
                },
                model
            )
        case BaseDepartmentId.CareerServices:
            return checkPermission({staff: [Permissions.Staff.CareerServices.Edit]}, model)
        default:
            return false
    }
}

export function getDocumentPermissionIdsByType(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number
): number {
    switch (permissionType) {
        case PermissionType.ListView:
            return get(model.departmentPermissions, [departmentId, subunitId, "Documents", "ListView", "View"])
        case PermissionType.View:
            return get(model.departmentPermissions, [departmentId, subunitId, "Documents", "DetailView", "View"])
        case PermissionType.Add:
            return get(model.departmentPermissions, [departmentId, subunitId, "Documents", "DetailView", "Add"])
        case PermissionType.Edit:
            return get(model.departmentPermissions, [departmentId, subunitId, "Documents", "DetailView", "Edit"])
        case PermissionType.Delete:
            return get(model.departmentPermissions, [departmentId, subunitId, "Documents", "DetailView", "Delete"])
        default:
            return null
    }
}

export function getActivitiesPermissionIdsByType(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number
): number {
    switch (permissionType) {
        case PermissionType.View:
            return get(model.departmentPermissions, [departmentId, subunitId, "Activities", "View"])
        case PermissionType.Add:
            return get(model.departmentPermissions, [departmentId, subunitId, "Activities", "Add"])
        case PermissionType.Edit:
            return get(model.departmentPermissions, [departmentId, subunitId, "Activities", "Edit"])
        case PermissionType.Delete:
            return get(model.departmentPermissions, [departmentId, subunitId, "Activities", "Delete"])
        default:
            return null
    }
}

export function getTaskPermissionIdsByType(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number
): number {
    switch (permissionType) {
        case PermissionType.View:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "View"])
        case PermissionType.Add:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "Add"])
        case PermissionType.Edit:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "Edit"])
        case PermissionType.Delete:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "Delete"])
        default:
            return null
    }
}

export function getBaseDepartmentPermissionIdsByType(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    value: BaseDepartmentPermission
): number {
    switch (permissionType) {
        case PermissionType.View:
            return get(model.departmentPermissions, [departmentId, DepartmentPermission.BasePermission, value, "View"])
        case PermissionType.Add:
            return get(model.departmentPermissions, [departmentId, DepartmentPermission.BasePermission, value, "Add"])
        case PermissionType.Edit:
            return get(model.departmentPermissions, [departmentId, DepartmentPermission.BasePermission, value, "Edit"])
        case PermissionType.Delete:
            return get(model.departmentPermissions, [
                departmentId,
                DepartmentPermission.BasePermission,
                value,
                "Delete"
            ])
        default:
            return null
    }
}

export function getTaskOtherAccessPermissionIdsByType(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number
): number {
    switch (permissionType) {
        case PermissionType.View:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "AccessOthers", "View"])
        case PermissionType.Edit:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "AccessOthers", "Edit"])
        case PermissionType.Delete:
            return get(model.departmentPermissions, [departmentId, subunitId, "Tasks", "AccessOthers", "Delete"])
        default:
            return null
    }
}

export function checkDocumentDepartmentPermission(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number = null
): boolean {
    const userProfileType = get(model, "myPermissions.type")
    if (
        userProfileType === Auth.UserProfileType.Admin ||
        userProfileType === Auth.UserProfileType.Student ||
        userProfileType === Auth.UserProfileType.Others
    ) {
        return true
    }
    let permissionIds = []
    if (subunitId) {
        permissionIds = [getDocumentPermissionIdsByType(permissionType, model, departmentId, subunitId)]
    } else {
        const subUnitInfos = get(model.departmentPermissions, [departmentId], {})
        Object.keys(subUnitInfos).forEach((subunitId) => {
            const subPermissionId = getDocumentPermissionIdsByType(
                permissionType,
                model,
                departmentId,
                parseInt(subunitId)
            )
            permissionIds.push(subPermissionId)
        })
    }
    const enabledDepartmentPermissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    const diffPermissions = difference(permissionIds, enabledDepartmentPermissionIds)
    return diffPermissions.length !== permissionIds.length
}

export function checkTaskDepartmentPermission(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number = null
): boolean {
    const userProfileType = get(model, "myPermissions.type")
    if (
        userProfileType === Auth.UserProfileType.Admin ||
        userProfileType === Auth.UserProfileType.Student ||
        userProfileType === Auth.UserProfileType.Others
    ) {
        return true
    }
    let permissionIds = []
    if (subunitId) {
        permissionIds = [getTaskPermissionIdsByType(permissionType, model, departmentId, subunitId)]
    } else {
        const subUnitInfos = get(model.departmentPermissions, [departmentId], {})
        Object.keys(subUnitInfos).forEach((subunitId) => {
            const subPermissionId = getTaskPermissionIdsByType(permissionType, model, departmentId, parseInt(subunitId))
            permissionIds.push(subPermissionId)
        })
    }
    const enabledDepartmentPermissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    const diffPermissions = difference(permissionIds, enabledDepartmentPermissionIds)
    return diffPermissions.length !== permissionIds.length
}

export function checkBaseDepartmentPermission(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    value: BaseDepartmentPermission
): boolean {
    const userProfileType = get(model, "myPermissions.type")
    if (
        userProfileType === Auth.UserProfileType.Admin ||
        userProfileType === Auth.UserProfileType.Student ||
        userProfileType === Auth.UserProfileType.Others
    ) {
        return true
    }
    const permissionIds = [getBaseDepartmentPermissionIdsByType(permissionType, model, departmentId, value)]
    const enabledDepartmentPermissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    const diffPermissions = difference(permissionIds, enabledDepartmentPermissionIds)
    return diffPermissions.length !== permissionIds.length
}

export function checkDepartmentActivitiesPermission(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number = null
): boolean {
    const userProfileType = get(model, "myPermissions.type")

    if (userProfileType === Auth.UserProfileType.Student || userProfileType === Auth.UserProfileType.Others) {
        return false
    }

    if (userProfileType === Auth.UserProfileType.Admin) {
        return true
    }

    let permissionIds = []
    if (subunitId) {
        permissionIds = [getActivitiesPermissionIdsByType(permissionType, model, departmentId, subunitId)]
    } else {
        const subUnitInfos = get(model.departmentPermissions, [departmentId], {})
        Object.keys(subUnitInfos).forEach((subunitId) => {
            const subPermissionId = getActivitiesPermissionIdsByType(
                permissionType,
                model,
                departmentId,
                parseInt(subunitId)
            )
            permissionIds.push(subPermissionId)
        })
    }
    const enabledDepartmentPermissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    const diffPermissions = difference(permissionIds, enabledDepartmentPermissionIds)
    return diffPermissions.length !== permissionIds.length
}

export function checkDepartmentTasksOtherAccessPermission(
    permissionType: PermissionType,
    model: Model,
    departmentId: number,
    subunitId: number = null
): boolean {
    const userProfileType = get(model, "myPermissions.type")
    if (userProfileType === Auth.UserProfileType.Admin) {
        return true
    }
    if (userProfileType === Auth.UserProfileType.Student || userProfileType === Auth.UserProfileType.Others) {
        return false
    }
    let permissionIds = []
    if (subunitId) {
        permissionIds = [getTaskOtherAccessPermissionIdsByType(permissionType, model, departmentId, subunitId)]
    } else {
        const subUnitInfos = get(model.departmentPermissions, [departmentId], {})
        Object.keys(subUnitInfos).forEach((subunitId) => {
            const subPermissionId = getTaskOtherAccessPermissionIdsByType(
                permissionType,
                model,
                departmentId,
                parseInt(subunitId)
            )
            permissionIds.push(subPermissionId)
        })
    }
    const enabledDepartmentPermissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    const diffPermissions = difference(permissionIds, enabledDepartmentPermissionIds)
    return diffPermissions.length !== permissionIds.length
}

export function getEnableSubunitsByDepartmentPermission(
    permissionType: PermissionType,
    model: Model,
    module: "Tasks" | "Documents" | "Activities"
): number[] {
    const enableSubunitIds = []
    const enabledDepartmentPermissionIds = get(model, "myPermissions.enabledDepartmentPermissionIds", [])
    const userProfileType = get(model, "myPermissions.type")
    let isStaffType = true
    if (
        userProfileType === Auth.UserProfileType.Admin ||
        userProfileType === Auth.UserProfileType.Student ||
        userProfileType === Auth.UserProfileType.Others
    ) {
        isStaffType = false
    }
    Object.keys(model.departmentPermissions).forEach((departmentId) => {
        const subUnitInfos = get(model.departmentPermissions, [departmentId], {})
        Object.keys(subUnitInfos).forEach((subunitId) => {
            let subPermissionId = null
            switch (module) {
                case "Tasks":
                    subPermissionId = getTaskPermissionIdsByType(
                        permissionType,
                        model,
                        +departmentId,
                        parseInt(subunitId)
                    )
                    break

                case "Documents":
                    subPermissionId = getDocumentPermissionIdsByType(
                        permissionType,
                        model,
                        +departmentId,
                        parseInt(subunitId)
                    )
                    break
                case "Activities":
                    subPermissionId = getActivitiesPermissionIdsByType(
                        permissionType,
                        model,
                        +departmentId,
                        parseInt(subunitId)
                    )
                    break
                default:
                    break
            }
            if (
                (!isStaffType || enabledDepartmentPermissionIds.includes(subPermissionId)) &&
                !enableSubunitIds.includes(+subunitId)
            ) {
                enableSubunitIds.push(+subunitId)
            }
        })
    })
    return enableSubunitIds
}

export const translate = (key: string, data: any = {}) => {
    return i18n.t(key, data)
}

export const generateNumericCode = (length: number) =>
    [...new Array(length)].map((it) => Math.floor(Math.random() * 10)).join("")

export const formatCurrency = (
    value = 0,
    currency = "usd",
    options?: Intl.NumberFormatOptions,
    removeEndingZeros = false
): string => {
    const formatter = new Intl.NumberFormat(undefined /* en-US */, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
        currency: currency.toUpperCase(),
        ...options

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const valueText = formatter.format(value)
    if (!removeEndingZeros) return valueText
    return valueText.replace(/([.,])00$/g, "") // remove ending `.00`
}

export const getObjKey = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value)
}

export const isPositiveFloat = (input) => {
    return !isNaN(input) && Number(input) >= 0
}
export const preventMinus = (e) => {
    if (e.code === "Minus") {
        e.preventDefault()
    }
}

export const isNotNegativeInteger = (input) => {
    return !isNaN(input) && Number(input) >= 0 && `${input}`.indexOf(".") < 0
}

export const getFieldLabel = (model: Model, fieldKey: string, defaultValue: string = "") => {
    const locale = model.getLocale()
    const fieldLabels = get(model, "fieldLabels", [])
    const labelKey = locale === "en" ? "english" : "spanish"
    return get(fieldLabels, [fieldKey, labelKey]) || defaultValue
}

export const isFieldActive = (model: Model, fieldKey: string) => {
    const fieldLabels = get(model, "fieldLabels", [])
    return get(fieldLabels, [fieldKey, "isActive"])
}

export const formatCodeName = (option) => {
    if (!option) return ""
    return option.code ? `[${option.code}] ${option.name}` : option.name
}

export const formatCodeTitle = (option) => {
    if (!option) return ""
    return option.code ? `[${option.code}] ${option.title}` : option.title
}

export const formatNameWithId = (option) => {
    if (!option) return ""
    return option.id ? `(${option.id}) ${option.name}` : option.name
}

export function truncateWithEllipses(text: string, maxLength: number, preLength: number, postLength?: number) {
    if (text.length > maxLength) {
        if ((postLength ?? 0) > 0) {
            return `${text.substr(0, preLength - 1)}...${text.substr(-postLength)}`
        }

        return `${text.substr(0, preLength - 1)}...`
    }

    return text
}

export const PREFERRED_DAYS_OF_CONTACT_OPTIONS = [
    {id: 0, name: "Monday"},
    {id: 1, name: "Tuesday"},
    {id: 2, name: "Wednesday"},
    {id: 3, name: "Thursday"},
    {id: 4, name: "Friday"},
    {id: 5, name: "Saturday"},
    {id: 6, name: "Sunday"}
]

export const HIGHEST_LEVEL_OF_FORMAL_EDUCATION_ACHIEVED_OPTIONS = [
    {id: "college", name: "College"},
    {id: "college-graduate-associates", name: "College Graduate Associates"},
    {id: "college-graduate-bachelors", name: "College Graduate Bachelors"},
    {id: "ged", name: "GED"},
    {id: "ged-plus-some-college", name: "GED plus some college"},
    {id: "high-school", name: "High School"},
    {id: "high-school-plus-some-college", name: "High School plus some college"}
]

export const hasTranslate3DSupport = () => {
    const isSsr = typeof window === "undefined"
    const css = !isSsr && window.CSS
    return css && css.supports && css.supports("transform", "translate3d(0,0,0)")
}

export enum ItemStatus {
    completed = "completed",
    rejected = "rejected",
    pending = "pending"
}

export const isImageLink = (url: string) =>
    new Promise((resolve) => {
        const img = new Image()

        img.src = url
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
    })

export const embedVideoLink = (url: string) => {
    const youtubeRegex =
        // eslint-disable-next-line no-useless-escape
        /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/

    const vimeoRegex =
        /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/

    const dailymotionRegex =
        /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:dailymotion\.com\/(?:video|hub)\/([^_]+))|(?:dai\.ly\/([^_]+))/

    let matches = url.match(youtubeRegex)
    if (matches != null) {
        // the first regex match will contain the entire url,
        // the second will contain the first capture group which is our video id
        const [_, videoId] = matches
        return `https://www.youtube.com/embed/${videoId}`
    }
    matches = url.match(vimeoRegex)
    if (matches != null) {
        return `https://player.vimeo.com/video/${matches[6]}`
    }
    matches = url.match(dailymotionRegex)
    if (matches != null) {
        // Extracting the first non-null match group (either from dailymotion.com or dai.ly)
        const videoId = matches[1] || matches[2]
        return `https://www.dailymotion.com/embed/video/${videoId}`
    }
    return null
}

export const replaceVideoLinksWithIframes = (text) => {
    // Regular expression to match existing iframe tags for YouTube, Vimeo, and Dailymotion
    const iframeRegex =
        /<iframe[^>]*src="(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com)[^>]*><\/iframe>/gi

    // Regular expression to match YouTube video URLs
    const youtubeRegex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g

    // Regular expression to match Vimeo URLs
    const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/g

    // Regular expression to match Dailymotion URLs
    const dailymotionRegex = /(?:https?:\/\/)?(?:www\.)?dailymotion\.com\/video\/([a-zA-Z0-9]+)/g

    // Function to create iframe for YouTube
    const createYouTubeIframe = (videoId) => {
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    }

    // Function to create iframe for Vimeo
    const createVimeoIframe = (videoId) => {
        return `<iframe width="560" height="315" src="https://player.vimeo.com/video/${videoId}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
    }

    // Function to create iframe for Dailymotion
    const createDailymotionIframe = (videoId) => {
        return `<iframe width="560" height="315" src="https://www.dailymotion.com/embed/video/${videoId}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
    }

    // Function to safely replace video URLs outside of existing iframes
    return text
        .replace(iframeRegex, (iframe) => {
            // Temporarily replace each iframe with a placeholder
            return `[[IFRAME_PLACEHOLDER_${Math.random().toString(36).substr(2, 9)}]]`
        })
        .replace(youtubeRegex, (_, videoId) => createYouTubeIframe(videoId))
        .replace(vimeoRegex, (_, videoId) => createVimeoIframe(videoId))
        .replace(dailymotionRegex, (_, videoId) => createDailymotionIframe(videoId))
        .replace(/\[\[IFRAME_PLACEHOLDER_[^\]]+\]\]/g, (placeholder) => {
            // After replacing URLs, restore the original iframes
            return text.match(iframeRegex).shift()
        })
}

export const checkIfProfileHasPermissionsForTheirSignatureAndInitials = (
    model: Model,
    permissionType: PermissionType
): boolean => {
    const userProfileType = get(model, "myPermissions.type") as Auth.UserProfileType
    const isStudentOrOthers = [Auth.UserProfileType.Student, Auth.UserProfileType.Others].includes(userProfileType)
    if (isStudentOrOthers) return true

    return checkPermission(
        {
            staff: [Permissions.Staff.Users.Self.Personal.SignatureAndInitials[permissionType]]
        },
        model
    )
}

export const checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType = (
    model: Model,
    targetProfileType: Auth.UserProfileType,
    permissionType: PermissionType
): boolean => {
    const userProfileType = get(model, "myPermissions.type") as Auth.UserProfileType
    const isStudentOrOthers = [Auth.UserProfileType.Student, Auth.UserProfileType.Others].includes(userProfileType)
    if (isStudentOrOthers) return false

    if (targetProfileType === Auth.UserProfileType.Staff) {
        return checkPermission(
            {
                staff: [Permissions.Staff.Users.Staff.Personal.SignatureAndInitials[permissionType]]
            },
            model
        )
    }

    if (targetProfileType === Auth.UserProfileType.Student) {
        return checkPermission(
            {
                staff: [Permissions.Staff.Users.Students.Personal.SignatureAndInitials[permissionType]]
            },
            model
        )
    }

    if (targetProfileType === Auth.UserProfileType.Others) {
        return checkPermission(
            {
                staff: [Permissions.Staff.Users.Others.Personal.SignatureAndInitials[permissionType]]
            },
            model
        )
    }
}

export const checkIfProfileHasPermissionsForTheirContacts = (model: Model, permissionType: PermissionType): boolean => {
    return checkPermission(
        {
            staff: [Permissions.Staff.Users.Self.Contacts[permissionType]],
            student: [Permissions.Student.Users.Contacts[permissionType]],
            others: [Permissions.Others.Users.Contacts[permissionType]]
        },
        model
    )
}

export const checkIfProfileHasPermissionsForContactsOfAnotherProfileOfType = (
    model: Model,
    targetProfileType: Auth.UserProfileType,
    permissionType: PermissionType
): boolean => {
    const userProfileType = get(model, "myPermissions.type") as Auth.UserProfileType
    const isStudentOrOthers = [Auth.UserProfileType.Student, Auth.UserProfileType.Others].includes(userProfileType)
    if (isStudentOrOthers) return false

    if (targetProfileType === Auth.UserProfileType.Staff) {
        return checkPermission(
            {
                staff: [Permissions.Staff.Users.Staff.Contacts[permissionType]]
            },
            model
        )
    }

    if (targetProfileType === Auth.UserProfileType.Student) {
        return checkPermission(
            {
                staff: [Permissions.Staff.Users.Students.Contacts[permissionType]]
            },
            model
        )
    }

    if (targetProfileType === Auth.UserProfileType.Others) {
        return checkPermission(
            {
                staff: [Permissions.Staff.Users.Others.Contacts[permissionType]]
            },
            model
        )
    }
}

export const checkIfProfileHasPermissionsToEditStudentStatus = (
    model: Model,
    studentState?: Auth.StudentState | string
): boolean => {
    const studentStatePermissionsName = {
        [Auth.StudentState.Alumni]: "Alumni",
        [Auth.StudentState.Applicant]: "Applicant",
        [Auth.StudentState.Prospect]: "Prospect",
        [Auth.StudentState.Student]: "Student",
        [Auth.StudentState.Enrollment]: "Enrollment",
        [Auth.StudentState.TemporaryOut]: "TemporaryOut",
        [Auth.StudentState.PermanentOut]: "PermanentOut",
        [Auth.StudentState.NeverAttended]: "NeverAttended"
    }

    return checkPermission(
        {
            staff: [Permissions.Staff.Users.Students.StatusesOf[studentStatePermissionsName[studentState]]?.Edit]
        },
        model
    )
}

export const getDegreeLevelName = (item: DegreeLevel.DegreeLevel) => `${item.code} - ${item.name}`

export const getCredentialLevelName = (item: CredentialLevel) => `${item.code} - ${item.name}`

export const getAwardedDegreeName = (item: AwardedDegree) => `${item.code} - ${item.name}`

export const StatusStateShortName = {
    [Auth.StudentState.Alumni]: "ALU",
    [Auth.StudentState.Applicant]: "APP",
    [Auth.StudentState.Prospect]: "PROS",
    [Auth.StudentState.Student]: "STU",
    [Auth.StudentState.Enrollment]: "ENR",
    [Auth.StudentState.TemporaryOut]: "LOA",
    [Auth.StudentState.PermanentOut]: "PMO",
    [Auth.StudentState.NeverAttended]: "NA"
}

export const StatusStateName = {
    [Auth.StudentState.Alumni]: "ALUMNI",
    [Auth.StudentState.Applicant]: "APPLICANT",
    [Auth.StudentState.Prospect]: "PROSPECT",
    [Auth.StudentState.Student]: "STUDENT",
    [Auth.StudentState.Enrollment]: "ENROLLMENT",
    [Auth.StudentState.TemporaryOut]: "TEMPORARY OUT",
    [Auth.StudentState.PermanentOut]: "PERMANENT OUT",
    [Auth.StudentState.NeverAttended]: "NEVER ATTENDED"
}

export const formatPhoneNumber = (phoneNumberString: string) => {
    const _phoneNumberString = phoneNumberString[0] === "+" ? phoneNumberString : `+${phoneNumberString}`
    const PNF = require("google-libphonenumber").PhoneNumberFormat
    const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance()
    const phoneNumber = phoneUtil.parseAndKeepRawInput(_phoneNumberString, "US")
    return phoneUtil.format(phoneNumber, PNF.INTERNATIONAL)
}

export const formatInputPhoneNumber = (phoneNumberString: string) => {
    const onlyNumbers = phoneNumberString.replace(/\D/g, "")
    if (onlyNumbers.length <= 3) {
        return `(${onlyNumbers}`
    }

    if (onlyNumbers.length <= 6) {
        return `(${onlyNumbers.slice(0, 3)})-${onlyNumbers.slice(3)}`
    }

    return `(${onlyNumbers.slice(0, 3)})-${onlyNumbers.slice(3, 6)}-${onlyNumbers.slice(6, 10)}`
}

export const renderList = (list?: any[], field?: string) => {
    return list?.map((item) => (field ? item[field] : item)).join(", ") ?? ""
}

export const renderStatus = (statusId: number, statusName?: string, statuses: any[] = []) => {
    let studentStatus: any

    statuses.forEach((status) => {
        if (status.statusId === statusId) {
            studentStatus = status
        }
    })
    return studentStatus?.name || statusName || ""
}

export const renderLeadSource = (admissionsLeadSourceId: number, leadSources: any[]) => {
    const selectedLeadSource = (leadSources || []).find((v) => v.id === admissionsLeadSourceId)
    return selectedLeadSource?.name || ""
}

export const renderLeadType = (leadTypeId: number, leadTypes: any[]) => {
    return leadTypes.find((type) => (type.id || type.leadTypeId) === leadTypeId)?.name || ""
}

export const formatDecimal = (value: any, fractionDigits: number = 2) => {
    const isValid = isString(value) ? !isEmpty(value) && isNumber(Number(value)) : isNumber(value)
    if (!isValid) {
        return value
    }
    return (Math.round(Number(value) * 100) / 100).toFixed(fractionDigits)
}

export const formatDecimalWithoutRound = (value: any) => {
    const isValid = isString(value) ? !isEmpty(value) && isNumber(Number(value)) : isNumber(value)
    if (!isValid) {
        return value
    }

    const [integerPart, decimalPart = ""] = value.toString().split(".")

    const formattedDecimal = decimalPart.slice(0, 2).padEnd(2, "0")
    return `${integerPart}.${formattedDecimal}`
}

export const getUnreadCountBadge = (count: number) => (count > 99 ? "99+" : count)
