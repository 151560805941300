/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col} from "antd"
import cx from "classnames"
import {studentService} from "services"
import styles from "./StatsChecklist.module.css"
import {handleError} from "helpers"
import {BaseButton} from "components/buttons"
import {useHistory} from "react-router-dom"
import {BaseDepartmentId} from "types/departments"

export enum StatsChecklistTitle {
    financialAidChecklist = "financialAidChecklist",
    admissionChecklist = "admissionChecklist",
    studentServiceChecklist = "studentServiceChecklist"
}

const BackUrlFromModule = {
    [BaseDepartmentId.Academics]: "academics/registrar/staff?tab=workspace",
    [BaseDepartmentId.Admissions]: "admissions/staff?tab=1"
}

type StatsChecklistProps = {
    departmentId: number
    title: StatsChecklistTitle
    form: any
}

export function StatsChecklist(props: StatsChecklistProps) {
    const history = useHistory()
    const [summary, setSummary] = useState({
        completion: 0,
        pendingItems: 0,
        pendingStudents: 0,
        totalStudents: 0
    })

    const {t} = useTranslation(["common"])

    const dataSeq = React.useRef(0)

    useEffect(() => {
        const filter = getFilter(props.form)
        getOverallSummary(filter)
    }, [])

    useEffect(() => {
        if (props.form.applySearch) {
            const filter = getFilter(props.form)
            getOverallSummary(filter)
        }
    }, [props.form])

    function getFilter(form) {
        const {campus, program, advisor, term} = form
        let filter: any = {}
        if (term) {
            filter.termsIds = term.map(({id}) => id)
        }
        if (campus) {
            filter.campusIds = campus.map(({id}) => id)
        }
        if (program) {
            filter.programIds = program.map(({id}) => id)
        }
        if (advisor) {
            filter.advisorProfileIds = (advisor || []).map((item) => item?.profileId).filter((item) => item)
        }

        return filter
    }

    const getOverallSummary = async (filter = {}) => {
        try {
            dataSeq.current += 1
            const currentSeq = dataSeq.current
            const summary = await studentService.getStudentChecklistListOverallSummary({
                departmentId: props.departmentId,
                ...filter
            })
            if (dataSeq.current !== currentSeq) return
            setSummary(summary)
        } catch (error) {
            handleError(error)
        }
    }

    const redirectToChecklistReport = () => {
        history.push(`/scrub-reports?departmentId=${props.departmentId}`, {
            backUrl: BackUrlFromModule[props.departmentId]
        })
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <div className={styles.headerLeft}>
                    <div className={styles.headerLeftTitleWrap}>
                        <p className={styles.headerLeft__title}>{t(`statsChecklist.${props.title}`).toUpperCase()}</p>
                        <BaseButton
                            title={t("common:action.report")}
                            variant="secondary"
                            onClick={redirectToChecklistReport}
                        />
                    </div>
                </div>
            </div>
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <div className={styles.cardItem}>
                        <p className={styles.cardItem__number}>{summary.totalStudents}</p>
                        <p className={styles.cardItem__title}>{t("statsChecklist.students").toUpperCase()}</p>
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.cardItem}>
                        <p className={styles.cardItem__number}>{Math.ceil(summary.completion * 100)}%</p>
                        <p className={styles.cardItem__title}>{t("statsChecklist.completion").toUpperCase()}</p>
                    </div>
                </Col>
                <Col span={8}>
                    <div className={cx(styles.cardItem, styles.cardItem__pending)}>
                        <div className={styles.pendingWrap}>
                            <div className={styles.pendingCard}>
                                <p className={styles.pendingCard__number}>{summary.pendingStudents}</p>
                                <p className={styles.pendingCard__title}>
                                    {t("statsChecklist.students").toUpperCase()}
                                </p>
                            </div>
                            <div className={styles.pendingCard}>
                                <p className={styles.pendingCard__number}>{summary.pendingItems}</p>
                                <p className={styles.pendingCard__title}>{t("statsChecklist.items").toUpperCase()}</p>
                            </div>
                        </div>
                        <p className={styles.pending__title}>{t("statsChecklist.overdue").toUpperCase()}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
