export enum FilterKey {
    TermCourseScheduleFilter = "term_course_schedule_filter",
    RegistrarTermAndCourses = "registrar_term_and_courses_courses",
    RegistrarTermAndMajors = "registrar_term_and_courses_majors",
    InstructionalCourses = "instructional_courses_filter",
    TermCourseFilterTerm = "instructional_term_course_filter_term",
    InstructionalFilterTerms = "instructional_filter_terms",
    InstructionalStudents = "instructional_students_filter",
    LessonAttendanceAll = "lessonAttendanceAll",
    LessonAttendanceAbsent = "lessonAttendanceAbsent",
    LessonAttendancePresent = "lessonAttendancePresent",
    UsersPage_Student = "usersPage_student_filter",
    UsersPage_Staff = "usersPage_staff_filter",
    UsersPage_Other = "usersPage_other_filter",
    AdmissionPage_Students = "admissionPage_students_filter",
    AdmissionPage_Students_Priority = "admissionPage_students_priority_filter",
    FinAidPage_Students = "finAidPage_students_filter",
    FinAidPage_Students_Priority = "finAidPage_students_priority_filter",
    FinAidPage_Verifications = "finAidPage_verifications_filter",
    FinAidPage_Packagings = "finAidPage_packagings_filter",
    AcademicsPage_Students = "academicsPage_students_filter",
    AcademicsPage_Students_Priority = "academicsPage_students_priority_filter",
    StudentServicesPage_Students = "studentServicesPage_students_filter",
    StudentServicesPage_Students_Priority = "studentServicesPage_students_priority_filter",
    StudentAccount_Students = "studentAccount_students_filter",
    StudentAccountStudentLedger = "sa_student_ledger",
    StudentAccountStudentCharges = "sa_student_charges",
    StudentAccountAssignPayments = "sa_assign_payments",
    StudentAccountStudentPayments = "sa_student_payments",
    StudentAccount9010 = "sa_90_10",
    StudentAccount9010Stats = "sa_90_10_stats",
    AcademicsPracticalPage_Students = "academicsPracticalPage_students_filter",
    GradesFameFilter = "grades_fame_filter",
    GradesGenesisFilter = "grades_genesis_filter",
    TasksPage = "tasksPage_filter",
    TasksLibraryPage = "tasksLibraryPage_filter",
    AdmissionPage_Analytics = "admissionPage_analytics_filter",
    FinAidPage_Analytics = "finAidPage_Analytics_filter",
    ActivityTable = "activityTable_filter",
    ChartOfAccounts = "chart_of_accounts_filter",
    CourseSettings = "courseSettings_filter",
    CampusSettings = "campusSettings_filter",
    MajorSettings = "majorSettings_filter",
    TermSettings = "termSettings_filter",
    MediaLibrary = "mediaLibrary_filter",
    TasksUserPage = "tasksUserPage_filter",
    PracticalSubmission = "practicalSubmission_filter",
    TemporaryOut = "temporary_out",
    TermCourseScheduleReportFilter = "term_course_schedule_report_filter",
    TermRosterReportFilter = "term_roster_report_filter",
    StudentLdaReport = "student_lda_report",
    UnofficialReport = "unofficial_report",
    PermanentOut = "permanent_out",
    MissedClassroomAttendance = "missed_classroom_attendance",
    ClassroomAttendance = "classroom_attendance",
    DailyAttendance = "daily_attendance",
    DailyAttendanceExportFrame = "daily_attendance_export_frame",
    DailyMakeupAttendance = "daily_makeup_attendance",
    ActiveClassroomMakeupAttendance = "active_classroom_makeup_attendance",
    CompletedClassroomMakeupAttendance = "completed_classroom_makeup_attendance",
    ClassroomMakeupAttendance = "classroom_makeup_attendance",
    RegistrarLessonList = "registrar_lesson_list",
    MissedDailyAttendance = "missed_daily_attendance",
    SapAction = "sap_action_filter",
    SapAlert = "sap_alert_filter",
    LeadSources = "lead_sources_filter",
    LeadTypes = "lead_types_filter",
    Activity = "activity_filter",
    TermCourseScheduleSetting = "term_course_schedule_setting_filter",
    LegacyTestList = "legacy_test_list",
    RetentionPage_Students = "retentionPage_students_filter",
    AcademicsPage_Analytics = "academicsPage_analytics_filter",
    Externship_Students = "externship_students",
    Externship_Docs_Students = "externship_docs_students",
    AcademicsPage_Analytics_Students = "academicsPage_analytics_student_filter",
    ExternshipAttendance = "externship_attendance",
    DocumentHub = "document_hub_filter",
    StudentDocument = "student_document_filter"
}

export type FieldPayload = Partial<{
    operator: FieldOperator
    value: any
    // extractedValue: any <- TODO: enhance extracting filter value
}>

export type FilterList = Record<string, FieldPayload>

export type AppliedFilter = {
    filters?: FilterList
    search?: string
}

export enum FieldType {
    Date = "date",
    List = "list",
    Single = "single",
    Number = "number",
    Text = "text",
    Flag = "flag"
}

export enum FieldOperator {
    Equal = "equal",
    NotEqual = "not_equal",
    Between = "between",
    LowerThan = "lower",
    LessThan = "less",
    LessThanOrEqual = "less_or_equal",
    GreaterThan = "greater",
    GreaterThanOrEqual = "greater_or_equal",
    Before = "before",
    After = "after",
    Like = "like",
    In = "in",
    NotIn = "not_in",
    IsNull = "null",
    IsNotNull = "not_null"
}

export const FieldTypeAvailableOperators = {
    [FieldType.Date]: [FieldOperator.Between, FieldOperator.Before, FieldOperator.After],
    [FieldType.List]: [FieldOperator.Equal, FieldOperator.NotEqual],
    [FieldType.Single]: [FieldOperator.Equal, FieldOperator.NotEqual],
    [FieldType.Text]: [FieldOperator.Like],
    [FieldType.Number]: [
        FieldOperator.Equal,
        FieldOperator.NotEqual,
        FieldOperator.Between,
        FieldOperator.LowerThan,
        FieldOperator.GreaterThan
    ]
}

export type ViewType = "standard" | "kanban" | "priority"

export enum Advisor {
    finAidAdvisor = "financialAidAdvisor",
    admissionAdvisor = "admissionAdvisor",
    academicAdvisor = "academicAdvisor"
}

export enum AdvisorFilter {
    isAcademicAdvisor = "isAcademicAdvisor",
    isAdmissionsAdvisor = "isAdmissionsAdvisor",
    isCareerServicesAdvisor = "isCareerServicesAdvisor",
    isFinancialAidAdvisor = "isFinancialAidAdvisor",
    isStudentAccountAdvisor = "isStudentAccountAdvisor",
    isStudentServicesAdvisor = "isStudentServicesAdvisor"
}

export enum Status {
    finAidStatus = "finAidStatus",
    admissionStatus = "admissionStatus",
    academicStatus = "academicStatus"
}

export const FIELD_OPERATOR_LABELS: {id: FieldOperator; name: string}[] = [
    {
        id: FieldOperator.Equal,
        name: "is"
    },
    {
        id: FieldOperator.NotEqual,
        name: "is not"
    },
    {
        id: FieldOperator.Between,
        name: "between"
    },
    {
        id: FieldOperator.LowerThan,
        name: "<="
    },
    {
        id: FieldOperator.GreaterThan,
        name: ">="
    },
    {
        id: FieldOperator.Before,
        name: "before"
    },
    {
        id: FieldOperator.After,
        name: "after"
    },
    {
        id: FieldOperator.In,
        name: "in"
    },
    {
        id: FieldOperator.NotIn,
        name: "not in"
    },
    {
        id: FieldOperator.IsNull,
        name: "is not defined"
    },
    {
        id: FieldOperator.IsNotNull,
        name: "is defined"
    },
    {
        id: FieldOperator.Like,
        name: "contains"
    }
]

export enum StatusDepartments {
    finAidStatus = 2,
    admissionStatus = 1,
    academicStatus = 3
}

export type SavedFilter = {
    fitlerId: number
    name: string
    createdBy: string
    createdAt: string
    isPublic: boolean
    content: string | FilterList
}
