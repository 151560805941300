export async function getBlobFromBase64String(base64String) {
    const res = await fetch(base64String)
    return await res.blob()
}

export async function convertSignatureTextToImageString(text, configData = {}) {
    const myFont = new FontFace("AlexBrush", "url(/fonts/AlexBrush-Regular.woff)")
    const font = await myFont.load()
    document.fonts.add(font)

    const textCanvas = document.createElement("canvas")
    const ctx = textCanvas.getContext("2d")
    const width = ctx.measureText(text).width
    const defaultConfig = {
        width: width * 11,
        height: 150,
        color: "#000",
        fontSize: 80
    }
    const config = {...defaultConfig, ...configData}
    const canvasElement = document.createElement("canvas")
    canvasElement.width = config.width
    canvasElement.height = config.height
    const canvas = canvasElement.getContext("2d")
    canvas.fillStyle = config.color
    canvas.textAlign = "center"
    canvas.textBaseline = "middle"
    canvas.font = `${config.fontSize}px AlexBrush`
    canvas.fillText(text, canvasElement.width / 2, canvasElement.height / 2)
    return canvas.canvas.toDataURL()
}

export const getPreviewUnavailableImage = (slug) => {
    return slug === "ogleschool" ? "/image/preview_unavailable_ogle.png" : "/image/preview_unavailable.png"
}

export const onErrorImageLoad = (slug, {currentTarget}) => {
    currentTarget.onError = null
    currentTarget.onerror = null
    currentTarget.src = getPreviewUnavailableImage(slug)
}

export function convertImgToBase64(url): Promise<string> {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
            // wait 2 seconds to simulate loading time
            setTimeout(() => {
                let canvas = document.createElement("canvas")
                const ctx = canvas.getContext("2d")
                canvas.height = img.height
                canvas.width = img.width
                ctx.drawImage(img, 0, 0)
                const dataURL = canvas.toDataURL()
                canvas = null
                resolve(dataURL)
            }, 2000)
        }
        img.onerror = (err) => {
            reject(err)
        }
        img.setAttribute("crossOrigin", "anonymous")
        img.src = url
    })
}

export function loadImage(imageObj, key = "url") {
    return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = imageObj[key]
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
            setTimeout(() => {
                resolve(imageObj[key])
            }, 2000)

        loadImg.onerror = (err) => {
            reject(err)
        }
    })
}

export async function downloadPDF(dataResponse, name) {
    const file = new Blob([dataResponse], {type: "application/pdf"})
    const fileURL = URL.createObjectURL(file)
    const downloadLink = document.createElement("a")
    const fileName = `${new Date().valueOf()}_${name}.pdf`
    downloadLink.href = fileURL
    downloadLink.download = fileName
    downloadLink.click()
}

export const downloadFile = (fileName: string, data: string): void => {
    const elDownload = document.createElement("a")
    elDownload.download = fileName
    elDownload.href = URL.createObjectURL(new Blob([data]))
    document.body.appendChild(elDownload)
    elDownload.click()
    document.body.removeChild(elDownload)
}

export const openLinkInNewTab = (url: string): void => {
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("target", "_blank")
    link.setAttribute("rel", "noopener")
    link.style.visibility = "hidden"

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
