import {get} from "lodash"
import BaseService from "./Base"

class MediaLibraryService extends BaseService {
    async getAll(params) {
        const response = await this.post("/v1/media-library/list", params)
        return get(response, "data", [])
    }

    async delete(params) {
        const response = await this.post(`/v1/media-library/delete`, params)
        return get(response, "data", {})
    }

    async removeFavorite(params) {
        const response = await this.post(`/v1/media-library/my-favorites/remove`, params)
        return get(response, "data", {})
    }

    async togglePinnedItem(params) {
        const response = await this.post(`/v1/media-library/toggle-pinned-item`, params)
        return get(response, "data", {})
    }

    async addFavorite(params) {
        const response = await this.post(`/v1/media-library/my-favorites/add`, params)
        return get(response, "data", {})
    }

    async create(data = {}) {
        const response = await this.post("/v1/media-library/create", data)
        return get(response, "data.data", {})
    }

    async update(id, data, params = {}) {
        data.itemId = id
        const response = await this.post(`/v1/media-library/edit`, data, params)
        return get(response, "data", {})
    }

    async extractVideoDetails(params) {
        const response = await this.post("/v1/media-library/extract-video-details", params)
        return get(response, "data", [])
    }

    async getLinkPreview(params) {
        const response = await this.post("/v1/media-library/get-link-preview", params)
        return get(response, "data", [])
    }
}

export default MediaLibraryService
