import React, {useEffect} from "react"
import {Iframe} from "components/Iframe"
import {Permissions} from "types/permission"
import {RouteWithPermissionsRequired} from "./RouteWithPermissionsRequired"
import TaskSubmit from "sections/Tasks/TaskSubmit"
import {BaseDepartmentId} from "types/departments"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {BaseService} from "services"
import ProgressReportScreen from "sections/academics/student/DegreeAudit/ProgressReport/ProgressReportScreen"
import GenerateTranscript from "sections/academics/student/DegreeAudit/ProgressReport/GenerateTranscript"

const AdmissionStudent = React.lazy(() => import("sections/Admission/AdmissionStudent"))
const StudentServicesStudent = React.lazy(() => import("sections/StudentServices/StudentServicesStudent"))
const FinancialAidStudent = React.lazy(() => import("sections/FinancialAid/FinancialAidStudent"))
const ExternshipStudentDocs = React.lazy(() => import("sections/Externship/ExternshipStudentDocs"))
const Communication = React.lazy(() => import("sections/communication"))
const UserPage = React.lazy(() => import("sections/users/Detail"))
const ListSignatures = React.lazy(() => import("sections/users/ListSignatures"))
const ListInitials = React.lazy(() => import("sections/users/ListInitials"))
const Calendar = React.lazy(() => import("sections/calendar"))
const CalendarEventDetail = React.lazy(() => import("sections/calendar/CalendarEventDetail"))
const AddCalendar = React.lazy(() => import("sections/development/tickets/parts/AddCalendar"))
const EventType = React.lazy(() => import("sections/development/tickets/parts/AddCalendar/parts/eventCalendar/parts"))
const Instructional = React.lazy(() => import("sections/academics/instructional/Instructional"))
const InstructionalCourse = React.lazy(() => import("sections/academics/instructional/InstructionalCourse"))
const InstructionalLessonDetail = React.lazy(() => import("sections/academics/instructional/InstructionalLessonDetail"))
const CourseGradesDetail = React.lazy(
    () => import("sections/academics/instructional/InstructionalLessonDetail/CourseGradesDetail")
)

const NewCommunication = React.lazy(() => import("sections/NewCommunication"))
const SupportHub = React.lazy(() => import("sections/SupportHub"))
const Notifications = React.lazy(() => import("sections/Notifications"))

const InstructionalManualGradeDetail = React.lazy(
    () => import("sections/academics/instructional/InstructionalLessonDetail/InstructionalManualGradeDetail")
)
const ActivityManualGradeDetail = React.lazy(
    () => import("sections/academics/instructional/InstructionalLessonDetail/ActivityManualGradeDetail")
)
const ActivityDetail = React.lazy(() => import("sections/academics/student/Terms/ClassActivityDetail"))
const InstructionalDetails = React.lazy(() => import("sections/academics/instructional/InstructionalDetails"))
const AcademicsStudent = React.lazy(() => import("sections/academics/student/AcademicsStudent"))
const AcademicsReports = React.lazy(() => import("sections/academics/ReportsList"))
const FinancialAidReports = React.lazy(() => import("sections/FinancialAid/ReportsList"))
const FinAidSAPReport = React.lazy(() => import("sections/FinancialAid/reports/FASAPReport"))
const FinAidAwardSummaryReport = React.lazy(
    () => import("sections/FinancialAid/reports/FAAwardSummaryReport/FinAidAwardSummaryReport")
)
const FinAidDisbursementReport = React.lazy(() => import("sections/FinancialAid/reports/FADisbursementReport"))
const ScrubReport = React.lazy(() => import("sections/ScrubReport"))
const NpsasReport = React.lazy(() => import("sections/NpsasReport"))
const AdmissionEnrollmentReport = React.lazy(() => import("sections/AdmissionEnrollmentReport"))
const AdmissionLeadReport = React.lazy(() => import("sections/AdmissionLeadReport"))
const AdmissionLeadSummaryReport = React.lazy(() => import("sections/AdmissionLeadSummaryReport"))
const FinAidStudentReport = React.lazy(() => import("sections/FinAidStudentReport"))
const FinAidDemographicsReport = React.lazy(() => import("sections/FinAidDemographicsReport"))
const FinAidEnrollmentReport = React.lazy(() => import("sections/FinAidEnrollmentReport"))
const FinAidDropAddSBLReport = React.lazy(() => import("sections/FinAidDropAddSBLReport"))
const FinAidCensusReport = React.lazy(() => import("sections/FinAidCensusReport"))
const FinAidTermCloseoutReport = React.lazy(() => import("sections/FinAidTermCloseoutReport"))
const COA = React.lazy(() => import("sections/COA/coa"))
const StudentAccountStaff = React.lazy(() => import("sections/StudentAccount/Staff/StudentAccountStaff"))
const StudentAccountBillingDetail = React.lazy(
    () => import("sections/StudentAccount/Staff/parts/BillingsTab/parts/StudentBillingDetail")
)
const StudentAccountChargeDetail = React.lazy(
    () => import("sections/StudentAccount/Student/parts/StudentLedgerTab/parts/ChargesTab/StudentChargeDetail")
)
const StudentAccountPaymentDetail = React.lazy(
    () => import("sections/StudentAccount/Student/parts/StudentLedgerTab/parts/PaymentsTab/StudentPaymentDetail")
)
const StudentAccountStudent = React.lazy(() => import("sections/StudentAccount/Student/StudentAccountStudent"))
const StudentAccountNinetyTenReportByRevenueSource = React.lazy(
    () => import("sections/StudentAccount/reports/NinetyTenReportByRevenueSource")
)
const StudentAccountStudentTransactions = React.lazy(
    () => import("sections/StudentAccount/reports/StudentTransactions")
)
const StudentAccountStudentDetail = React.lazy(() => import("sections/StudentAccount/reports/StudentDetail"))
const StudentAccountBalanceSummary = React.lazy(() => import("sections/StudentAccount/reports/BalanceSummary"))
const StudentAccountBalanceDetail = React.lazy(() => import("sections/StudentAccount/reports/BalanceDetail"))
const StudentAccountARAgingSummary = React.lazy(() => import("sections/StudentAccount/reports/ARAgingSummary"))
const StudentAccountARAgingDetail = React.lazy(() => import("sections/StudentAccount/reports/ARAgingDetail"))
const CareerServicesStudentDetail = React.lazy(() => import("sections/CareerServicesStudentDetail"))
const Tasks = React.lazy(() => import("sections/Tasks"))
const TaskDetail = React.lazy(() => import("sections/Tasks/TaskDetail"))
const TaskSubmissions = React.lazy(() => import("sections/Tasks/TaskSubmission"))
const TaskObservations = React.lazy(() => import("sections/Tasks/TaskObservations"))
const DocumentDetail = React.lazy(() => import("sections/Documents/DocumentDetail"))
const HomePage = React.lazy(() => import("sections/Homepage"))
const CareerServicesEmployerBoard = React.lazy(() => import("sections/CareerServicesEmployerBoard"))
const MakePaymentPage = React.lazy(() => import("sections/MakePaymentPage"))
const DocumentsPage = React.lazy(() => import("sections/Documents"))

function StudentPassport() {
    return <Iframe title="Fin Aid" iframe_url="http://client.passport.edular.com/" />
}

export function CommonRoutes(props) {
    const {model} = props

    const {initialize, isLoading} = useEdularModulesContext()

    useEffect(() => {
        if (!model.profileId) {
            return
        }
        initialize({
            profileId: model.profileId,
            token: model.authToken,
            baseUrl: BaseService.getBaseUrl()
        })
    }, [initialize, model.profileId, model.authToken])

    if (isLoading) {
        return null
    }

    return (
        <>
            <RouteWithPermissionsRequired exact path="/student-passport*" component={StudentPassport} />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/student/:profileId"
                model={model}
                component={FinancialAidStudent}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/student/:profileId"
                model={model}
                component={AdmissionStudent}
                module={Modules.Admissions}
                permissions={{
                    staff: [Permissions.Staff.Admissions.AdmissionsStaff.View],
                    student: [Permissions.Student.Admissions.View],
                    others: [Permissions.Others.Admissions.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-services/student/:profileId"
                model={model}
                component={StudentServicesStudent}
                module={Modules.StudentServices}
                departmentId={BaseDepartmentId.StudentServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academic-affairs/student/:profileId"
                model={model}
                component={StudentServicesStudent}
                module={Modules.AcademicAffair}
                departmentId={BaseDepartmentId.Academics}
            />
            <RouteWithPermissionsRequired
                exact
                path="/externship/student/docs"
                model={model}
                component={ExternshipStudentDocs}
                module={Modules.AcademicExternship}
            />
            <RouteWithPermissionsRequired
                exact
                path="/legacy-communication"
                model={model}
                module={Modules.Chat}
                component={Communication}
            />
            <RouteWithPermissionsRequired
                exact
                path="/communication"
                model={model}
                module={Modules.Chat}
                component={NewCommunication}
            />
            <RouteWithPermissionsRequired exact path="/supports" model={model} component={SupportHub} />
            <RouteWithPermissionsRequired exact path="/notifications" model={model} component={Notifications} />
            <RouteWithPermissionsRequired exact path="/users/:id/signature" model={model} component={ListSignatures} />
            <RouteWithPermissionsRequired exact path="/users/:id/initials" model={model} component={ListInitials} />
            <RouteWithPermissionsRequired exact path="/users/:id" model={model} component={UserPage} />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/users/:id"
                model={model}
                component={UserPage}
            />
            <RouteWithPermissionsRequired
                exact
                path="/calendar"
                model={model}
                module={Modules.Calendar}
                component={Calendar}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/calendar"
                model={model}
                module={Modules.Academic}
                component={Calendar}
            />
            <RouteWithPermissionsRequired
                exact
                path="/calendar-event/detail"
                model={model}
                module={Modules.Calendar}
                component={CalendarEventDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/add-calendar"
                model={model}
                module={Modules.Calendar}
                component={AddCalendar}
            />
            <RouteWithPermissionsRequired
                exact
                path="/event-type"
                model={model}
                module={Modules.Calendar}
                component={EventType}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional"
                model={model}
                module={Modules.Academic}
                component={Instructional}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/term/:termId/course/:courseId"
                model={model}
                module={Modules.Academic}
                component={InstructionalCourse}
                fromRoute="registrar"
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/term/:termId/course/:courseId/student/:profileId"
                model={model}
                component={StudentServicesStudent}
                module={Modules.Academic}
                departmentId={BaseDepartmentId.Academics}
                fromRoute="registrar"
                academicMode="instructional"
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/term/:termId/course/:courseId/lessons/:lessonId"
                model={model}
                module={Modules.Academic}
                fromRoute="registrar"
                component={InstructionalLessonDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/schedule/:scheduleId"
                model={model}
                module={Modules.Academic}
                component={InstructionalCourse}
                fromRoute="instructional"
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId"
                model={model}
                module={Modules.Academic}
                component={InstructionalCourse}
                fromRoute="instructional"
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/student/:profileId"
                model={model}
                component={StudentServicesStudent}
                module={Modules.Academic}
                departmentId={BaseDepartmentId.Academics}
                academicMode="instructional"
                fromRoute="instructional"
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/lessons/:lessonId"
                model={model}
                module={Modules.Academic}
                component={InstructionalLessonDetail}
                fromRoute="instructional"
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/schedule/:scheduleId/lessons/:lessonId"
                model={model}
                module={Modules.Academic}
                component={InstructionalLessonDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/grades/term/:termId/course/:courseId/lessons/:lessonId"
                model={model}
                module={Modules.Academic}
                component={CourseGradesDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/manual-grade"
                model={model}
                module={Modules.Academic}
                component={InstructionalManualGradeDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/schedule/:scheduleId/manual-grade"
                model={model}
                module={Modules.Academic}
                component={InstructionalManualGradeDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/activities/term/:termId/course/:courseId/manual-grade"
                model={model}
                module={Modules.Academic}
                component={ActivityManualGradeDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/activities/term/:termId/course/:courseId/schedule/:scheduleId/manual-grade"
                model={model}
                module={Modules.Academic}
                component={ActivityManualGradeDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/student/:studentProfileId/term/:termId/course/:courseId/activity"
                model={model}
                module={Modules.Academic}
                component={ActivityDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/student/:studentProfileId/term/:termId/course/:courseId/schedule/:scheduleId/activity"
                model={model}
                module={Modules.Academic}
                component={ActivityDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/instructional/term/:termId/course/:courseId/lessons"
                model={model}
                module={Modules.Academic}
                component={InstructionalDetails}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/student/:profileId"
                model={model}
                module={Modules.Academic}
                component={AcademicsStudent}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/student/:profileId/progress-report/:departmentId/:type/:transcriptType"
                model={model}
                module={Modules.Academic}
                component={ProgressReportScreen}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/student/:profileId/generate-transcript/:departmentId/:type/:transcriptType"
                model={model}
                module={Modules.Academic}
                component={GenerateTranscript}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/reports"
                model={model}
                module={Modules.Academic}
                component={AcademicsReports}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/reports"
                model={model}
                component={FinancialAidReports}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/reports/fa-sap-report"
                model={model}
                component={FinAidSAPReport}
                module={Modules.ReportsFinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Reports.FinancialAid.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/reports/fa-award-summary-report"
                model={model}
                component={FinAidAwardSummaryReport}
                module={Modules.ReportsFinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Reports.FinancialAid.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/reports/fa-disbursement-report"
                model={model}
                component={FinAidDisbursementReport}
                module={Modules.ReportsFinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Reports.FinancialAid.General.View]
                }}
            />
            <RouteWithPermissionsRequired exact path="/scrub-reports" model={model} component={ScrubReport} />
            <RouteWithPermissionsRequired
                exact
                path="/reports/admissions/npsas"
                model={model}
                component={NpsasReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admission-enrollment-reports"
                model={model}
                component={AdmissionEnrollmentReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admission-lead-reports"
                model={model}
                component={AdmissionLeadReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admission-lead-summary-reports"
                model={model}
                component={AdmissionLeadSummaryReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/fin-aid-student-reports"
                model={model}
                component={FinAidStudentReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/fin-aid-demographics-reports"
                model={model}
                component={FinAidDemographicsReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/fin-aid-enrollment-reports"
                model={model}
                component={FinAidEnrollmentReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/fin-aid-drop-add-sbl-reports"
                model={model}
                component={FinAidDropAddSBLReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/fin-aid-census-reports"
                model={model}
                component={FinAidCensusReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/fin-aid-term-closeout-reports"
                model={model}
                component={FinAidTermCloseoutReport}
            />
            <RouteWithPermissionsRequired exact path="/coa*" model={model} component={COA} />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/staff"
                model={model}
                component={StudentAccountStaff}
                module={Modules.StudentAccount}
                permissions={{
                    staff: [Permissions.Staff.StudentAccount.Workspace.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/staff/billings/:id"
                model={model}
                component={StudentAccountBillingDetail}
                module={Modules.StudentAccount}
                permissions={{
                    staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/student/:profileId"
                model={model}
                module={Modules.StudentAccount}
                component={StudentAccountStudent}
                permissions={{
                    staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View],
                    student: [Permissions.Student.StudentAccount.StudentAccount.View],
                    others: [Permissions.Others.StudentAccount.StudentAccount.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/student/:profileId/charges/:id"
                model={model}
                module={Modules.StudentAccount}
                component={StudentAccountChargeDetail}
                permissions={{
                    staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/student/:profileId/payments/:id"
                model={model}
                module={Modules.StudentAccount}
                component={StudentAccountPaymentDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/90-10-by-revenue-source"
                model={model}
                component={StudentAccountNinetyTenReportByRevenueSource}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/student-transactions"
                model={model}
                component={StudentAccountStudentTransactions}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/student-detail"
                model={model}
                component={StudentAccountStudentDetail}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/balance-summary"
                model={model}
                component={StudentAccountBalanceSummary}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/balance-detail"
                model={model}
                component={StudentAccountBalanceDetail}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/ar-aging-summary"
                model={model}
                component={StudentAccountARAgingSummary}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/reports/ar-aging-detail"
                model={model}
                component={StudentAccountARAgingDetail}
                module={Modules.ReportsStudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Reports.StudentAccounts.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/tasks"
                model={model}
                module={Modules.Tasks}
                component={Tasks}
                // permissions={[Permissions.Tasks.Assigned.View, Permissions.Tasks.Library.View]}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/tasks"
                departmentId={BaseDepartmentId.Academics}
                model={model}
                component={Tasks}
                module={Modules.Tasks}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/tasks"
                departmentId={BaseDepartmentId.Admissions}
                model={model}
                module={Modules.Tasks}
                component={Tasks}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/tasks"
                departmentId={BaseDepartmentId.FinancialAid}
                model={model}
                module={Modules.Tasks}
                component={Tasks}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/tasks"
                departmentId={BaseDepartmentId.StudentAccount}
                model={model}
                module={Modules.Tasks}
                component={Tasks}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-services/tasks"
                departmentId={BaseDepartmentId.StudentServices}
                model={model}
                module={Modules.Tasks}
                component={Tasks}
            />
            <RouteWithPermissionsRequired
                exact
                path="/tasks/detail"
                model={model}
                module={Modules.Tasks}
                component={TaskDetail}
                // permissions={[Permissions.Tasks.Assigned.View, Permissions.Tasks.Library.View]}
            />
            <RouteWithPermissionsRequired
                exact
                path="/tasks/submissions"
                model={model}
                module={Modules.Tasks}
                component={TaskSubmissions}
                // permissions={[Permissions.Tasks.Assigned.View]}
            />
            <RouteWithPermissionsRequired
                exact
                path="/tasks/remarks"
                model={model}
                module={Modules.Tasks}
                component={TaskObservations}
            />
            <RouteWithPermissionsRequired
                exact
                path="/tasks/submit"
                model={model}
                module={Modules.Tasks}
                component={TaskSubmit}
            />
            <RouteWithPermissionsRequired exact path="/documents/detail" model={model} component={DocumentDetail} />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/student/:profileId"
                backUrl="/career-services"
                module={Modules.CareerServices}
                component={CareerServicesStudentDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/employer/:profileId"
                backUrl="/career-services"
                module={Modules.CareerServices}
                component={CareerServicesEmployerBoard}
            />
            <RouteWithPermissionsRequired exact path="/make-payment" component={MakePaymentPage} />
            <RouteWithPermissionsRequired exact path="/document-hub" component={DocumentsPage} />
            <RouteWithPermissionsRequired exact path="/" model={model} component={HomePage} />
        </>
    )
}
