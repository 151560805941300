/* eslint-disable react-hooks/exhaustive-deps */
import {BaseDepartmentId} from "types/departments"
import {useContext} from "react"
import {CommunicationContext} from "context/CommunicationContext"
import {Communication} from "types/communication"
import {useTranslation} from "react-i18next"
import {checkDepartmentPermission, getUnreadCountBadge, handleError, toastError} from "helpers"
import {useQuery} from "@tanstack/react-query"
import {useModel} from "hooks"
import {intersection} from "lodash"
import {chatServiceV2} from "services"
import {convertRoom} from "helpers/chat"
import {Tabs} from "antd"
import {TabHeader} from "components/Tab"
import {DirectChat} from "sections/NewCommunication/parts/DirectChat"
import {BaseLoading} from "components"
import styles from "./StudentChat.module.css"
import {DepartmentPermissionTarget, PermissionType} from "@edular/permissions"
import cx from "classnames"

type Props = {
    user: any
    departmentId?: BaseDepartmentId
    userIdField: string
    isSidebarChat?: boolean
}

export function StudentChat(props: Props) {
    const {user, departmentId, userIdField, isSidebarChat = false} = props
    const {changeRoom, activeRoom} = useContext(CommunicationContext)
    const {t} = useTranslation(["chat", "user"])
    const model = useModel()

    const onChangeRoom = () => {
        if (activeRoom.type === Communication.RoomTypes.Department) {
            changeRoom(directRoom)
        } else {
            if (!departmentRoom) {
                const availableDepartmentalChats = model.departmentsChats.filter(
                    (dept) =>
                        checkDepartmentPermission(
                            model,
                            dept.id,
                            0,
                            DepartmentPermissionTarget.Chat,
                            PermissionType.Add
                        ) && dept.isDepartmentalChatEnabled
                )
                const isDepartmentalChatAvailable = availableDepartmentalChats
                    .map((x) => x.departmentId)
                    .includes(departmentId)
                if (!isDepartmentalChatAvailable) {
                    toastError(`${t(`common:moduleName.${departmentId}`)} chat is currently not available.`)
                    return
                }

                toastError(
                    `Please assign campus to this student to start ${t(`common:moduleName.${departmentId}`)} chat`
                )
                return
            }
            changeRoom(departmentRoom)
        }
    }

    const {data: departmentRoom, isLoading: isDepartmentRoomLoading} = useQuery<Communication.Room>(
        ["department-room", user?.[userIdField]],
        async () => {
            const studentCampusIds: number[] = user?.campusIds || []
            const currentUser = await model.getOneCompleteUser(model.user.id)
            const userCampuses = currentUser.profiles.map(({campuses}) => campuses.map(({id}) => id))
            const userCampusIds = userCampuses.flatMap((id) => id)
            const sameCampusIds = intersection(userCampusIds, studentCampusIds)
            if (sameCampusIds.length) {
                const _departmentRoom = await chatServiceV2.addDepartmentCampusRoom(
                    departmentId,
                    sameCampusIds[0],
                    user?.[userIdField]
                )
                return _departmentRoom
            }
            return undefined
        },
        {
            enabled: !!user && !!user[userIdField],
            onError: (error) => {
                handleError(error)
            }
        }
    )

    const {data: directRoom, isLoading: isDirectRoomLoading} = useQuery<Communication.Room>(
        ["direct-room", user?.[userIdField]],
        async () => {
            const data = await chatServiceV2.addRoom({
                type: "private",
                userIds: [user[userIdField]]
            })
            const directChatRoom = convertRoom(Communication.RoomTypes.DirectChat, data)
            changeRoom(directChatRoom)
            return directChatRoom
        },
        {
            enabled: !!user && !!user[userIdField],
            onError: (error) => {
                handleError(error)
            }
        }
    )

    if (!user) return null

    return (
        <>
            <Tabs
                className="fullwidth klassSubTab"
                activeKey={activeRoom.roomId.toString()}
                onChange={onChangeRoom}
                tabBarStyle={isSidebarChat ? {margin: "6px 6px 2px 6px"} : {}}>
                <Tabs.TabPane
                    className={cx(styles.tabContainer, {[styles.sidebarContainer]: isSidebarChat})}
                    key={directRoom?.roomId}
                    tab={
                        <TabHeader
                            title="Direct"
                            indicator={
                                directRoom?.unread > 0 ? (
                                    <span className={styles.unreadCount}>{getUnreadCountBadge(directRoom.unread)}</span>
                                ) : undefined
                            }
                        />
                    }>
                    {activeRoom.roomId === directRoom?.roomId && <DirectChat hideHeading />}
                </Tabs.TabPane>
                <Tabs.TabPane
                    className={cx(styles.tabContainer, {[styles.sidebarContainer]: isSidebarChat})}
                    key={departmentRoom?.roomId ?? "department"}
                    tab={
                        <TabHeader
                            title="Department"
                            indicator={
                                departmentRoom?.unread > 0 ? (
                                    <span className={styles.unreadCount}>
                                        {getUnreadCountBadge(departmentRoom.unread)}
                                    </span>
                                ) : undefined
                            }
                        />
                    }>
                    {activeRoom.roomId === departmentRoom?.roomId && <DirectChat hideHeading />}
                </Tabs.TabPane>
            </Tabs>
            <BaseLoading isShow={isDirectRoomLoading || isDepartmentRoomLoading} />
        </>
    )
}
