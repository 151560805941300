import {useQuery} from "@tanstack/react-query"
import {userService} from "services"

const queryCacheKey = "user"

type UserProps = {
    userId?: number
}

export const useUser = ({userId}: UserProps) => {
    const query = useQuery(
        [queryCacheKey, userId],
        async () => {
            const user = await userService.getUserById({filter: {id: [userId]}, linkedObjects: false})
            return user
        },
        {
            enabled: !!userId
        }
    )

    return {
        user: query.data,
        isLoading: query.isFetching
    }
}
