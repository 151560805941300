/* eslint-disable react-hooks/exhaustive-deps */
import {checkDocumentDepartmentPermission, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useModel, useVisible} from "hooks"
import moment from "moment"
import {useCallback, useEffect, useMemo, useState} from "react"
import {documentHubService} from "services"
import {KlassappTableProps} from "types/common"
import {BaseDepartmentId} from "types/departments"
import {FilterList} from "types/filter"
import styles from "./DocumentListView.module.css"
import {PermissionType} from "types/permission"
import {Icon} from "components"
import {useHistory} from "react-router-dom"
import {ConfirmPopup, KlassappTable, KlassappTableHeader} from "uiKit"
import {useDocumentActions} from "hooks/"
import {stringifyUrl} from "query-string"
import {DocumentAction, UserDocument, UserDocumentStatus} from "types/user-documents"
import update from "immutability-helper"
import PrintPreparePopup from "sections/Documents/parts/PrintPreparePopup"

type Props = KlassappTableProps & {
    filterParams?: {
        filters: FilterList
        search?: string
        userId?: number
        profileId?: number
        onlyVisibleForUser: boolean
    }
    departmentId?: BaseDepartmentId
    backUrl?: string
}

function DocumentList(props: Props) {
    const model = useModel()
    const history = useHistory()
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        orderField,
        isLoadedTableFuncs,
        dispatch,
        dispatchFunc,
        getCurrentData,
        isShowTableHeaderAction,
        tableHeaderActions,
        isHideMenuActions,
        menuActions
    } = props
    const {filterParams, backUrl, departmentId} = props
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {deleteDocuments, updateStatus, print, isSubmitting} = useDocumentActions()
    const showConfirmationPopup = useVisible(false)
    const [actionDocuments, setActionDocuments] = useState<{
        action?: DocumentAction
        userDocumentIds?: number[]
    }>({})
    const [documentsForPrint, setDocumentsForPrint] = useState<UserDocument[]>([])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickEdit", func: onClickEdit}
        ])
    }, [])

    useEffect(() => {
        dispatchFunc([
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDelete", func: onClickDelete},
            {key: "getMenuActions", func: getMenuActions}
        ])
    }, [filterParams])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order, filterParams])

    const getData = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const payload = {
                range: {
                    pageSize: pageSize,
                    page: page
                },
                ...filterParams,
                sort: orderField?.field
                    ? {
                          orderBy: orderField.field,
                          orderDir: orderField.order
                      }
                    : undefined
            }
            const {data, total} = await documentHubService.listDocuments(payload)
            const formattedDocuments = data.map((item: any) => {
                item.id = item.userDocumentId
                item.statusHtml = renderStatusHtml(item)
                return item
            })
            dispatch({data: formattedDocuments, total})
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }, [filterParams, page, pageSize, orderField])

    const getFields = () => {
        const fields = ["ID", "Name", "Description", "Department"]
        if (!filterParams.profileId) {
            fields.push("Student")
        }

        fields.push(...["Date", "Approved"])
        return fields
    }

    const getColumns = () => {
        const cols: any = [
            {
                title: "ID",
                field: "code",
                style: {
                    minWidth: "70px",
                    maxWidth: "80px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Name",
                field: "name",
                style: {
                    minWidth: "90px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Description",
                field: "description",
                style: {
                    minWidth: "120px",
                    maxWidth: "130px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Department",
                field: "departmentName",
                style: {
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            }
        ]

        if (!filterParams.profileId) {
            cols.push({
                title: "Student",
                field: "ownerFullName",
                style: {minWidth: "120px", maxWidth: "120px"},
                sortable: true,
                orderField: "ownerFullName"
            })
        }

        cols.push(
            ...[
                {
                    title: "Date",
                    field: "createdAt",
                    fieldType: "date",
                    format: model.getUserDateFormat(),
                    style: {minWidth: "100px", maxWidth: "110px"},
                    sortable: true
                },
                {
                    title: "Approved",
                    field: "statusHtml",
                    style: {
                        minWidth: "100px",
                        maxWidth: "110px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }
                }
            ]
        )

        return cols
    }

    const canShowDeleteIcon = (data) => {
        return data.status !== UserDocumentStatus.Approved
    }

    const getMenuActions = () => {
        return [
            {
                title: "Edit",
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: "Delete",
                icon: "DELETE",
                action: onClickDelete,
                canShow: canShowDeleteIcon
            }
        ]
    }

    const getTableHeaderActions = () => {
        const actions: any[] = []
        const data = getCurrentData()

        const canDelete = data
            .filter((item) => item.isChecked)
            .every((item) => item.status !== UserDocumentStatus.Approved)
        const canDeleteByPermission = checkDocumentDepartmentPermission(PermissionType.Delete, model, departmentId)
        if (canDeleteByPermission && canDelete) {
            actions.push({
                title: "Delete",
                icon: "DELETE",
                action: () => onConfirmAction(DocumentAction.Delete)
            })
        }

        const canApprove =
            model.isStaffOrAdmin() &&
            data.filter((item) => item.isChecked).every((item) => item.ownerUserId !== model.user.id)
        if (canApprove) {
            actions.push(
                ...[
                    {
                        title: "Approve",
                        icon: "CHECKMARK_CIRCLE",
                        iconClassName: styles.approveIcon,
                        action: () => onConfirmAction(DocumentAction.Approve)
                    },
                    {
                        title: "Reject",
                        icon: "CROSS_CIRCLE_FILL",
                        iconClassName: styles.rejectIcon,
                        action: () => onConfirmAction(DocumentAction.Reject)
                    }
                ]
            )
        }
        const canPrint =
            model.isStaffOrAdmin() ||
            data.filter((item) => item.isChecked).every((item) => item.ownerUserId === model.user.id)
        if (canPrint) {
            actions.push({
                title: "Print",
                icon: "DOCUMENT",
                iconClassName: styles.printIcon,
                action: onClickPrint
            })
        }
        return actions
    }

    const onConfirmAction = (action: DocumentAction) => {
        const data = getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)

        setActionDocuments({
            action,
            userDocumentIds: checkedItems.map((item) => item.id)
        })
        showConfirmationPopup.open()
    }

    const onHandleAction = async () => {
        if (!actionDocuments.action) return
        showConfirmationPopup.close()
        const {action, userDocumentIds} = actionDocuments
        switch (action) {
            case DocumentAction.Delete:
                return deleteDocuments(userDocumentIds, {
                    onSuccess: async () => {
                        getData()
                        dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
                    }
                })
            case DocumentAction.Approve:
            case DocumentAction.Reject:
                const payload = userDocumentIds.map((id) => ({
                    userDocumentId: id,
                    status:
                        action === DocumentAction.Approve ? UserDocumentStatus.Approved : UserDocumentStatus.Rejected
                }))
                return updateStatus(payload, {
                    onSuccess: async () => {
                        getData()
                        dispatch({isShowTableHeaderAction: false})
                    }
                })
        }
    }

    const onClickRowItem = (data) => {
        const documentDetailsUrl = stringifyUrl(
            {
                url: `/documents/detail`,
                query: {
                    id: data.id,
                    backUrl,
                    userProfileId: data.ownerUserProfileId
                }
            },
            {skipNull: true}
        )
        history.push(documentDetailsUrl)
    }

    const onClickEdit = (editedItem) => {
        onClickRowItem(editedItem)
    }

    const onClickDelete = async (deletedItem) => {
        setActionDocuments({
            action: DocumentAction.Delete,
            userDocumentIds: [deletedItem.userDocumentId]
        })
        showConfirmationPopup.open()
    }

    const onClickPrint = async () => {
        const data = getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)
        setDocumentsForPrint(checkedItems)
    }

    const renderStatusHtml = ({status, statusUpdatedBy, statusUpdatedAt}) => {
        return (
            <div className={styles.statusWrap}>
                <div className={styles.status}>
                    {status === UserDocumentStatus.Approved ? (
                        <Icon icon="CHECKMARK_CIRCLE" color="#18A957" />
                    ) : status === UserDocumentStatus.Rejected ? (
                        <Icon icon="CROSS_CIRCLE_FILL" color="#DF1642" />
                    ) : (
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#E6E6E6" />
                    )}
                </div>
                {!!status && status !== UserDocumentStatus.Pending && (
                    <div className={styles.statusInfo}>
                        <p className={styles.statusInfo__text}>{statusUpdatedBy}</p>
                        <p className={styles.statusInfo__text}>{moment(statusUpdatedAt).format(userDateTimeFormat)}</p>
                    </div>
                )}
            </div>
        )
    }

    const confirmationTitle = useMemo(() => {
        if (!actionDocuments.action) return ""
        return `Are you sure you want to ${actionDocuments.action.toLowerCase()} ${
            actionDocuments.userDocumentIds.length
        } item(s)?`
    }, [actionDocuments])

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setDocumentsForPrint((prevState) =>
            update(prevState, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevState[dragIndex]]
                ]
            })
        )
    }, [])

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading || isSubmitting}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <ConfirmPopup
                isVisible={showConfirmationPopup.isVisible}
                title={confirmationTitle}
                onClose={showConfirmationPopup.close}
                onConfirm={onHandleAction}
            />
            <PrintPreparePopup
                visible={!!documentsForPrint.length}
                onClose={() => setDocumentsForPrint([])}
                documents={documentsForPrint}
                moveItem={moveItem}
            />
        </>
    )
}

export const DocumentListView = KlassappTableHOC(DocumentList)
