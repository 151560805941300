import {keyBy} from "lodash"
import {translate} from "helpers"
import {Auth} from "./auth"

export const STATES_OPTIONS = [
    {id: Auth.StudentState.Prospect, name: "Prospect"},
    {id: Auth.StudentState.Applicant, name: "Applicant"},
    {id: Auth.StudentState.Enrollment, name: "Enrollment"},
    {id: Auth.StudentState.Student, name: "Student"},
    {id: Auth.StudentState.Alumni, name: "Alumni"},
    {id: Auth.StudentState.NeverAttended, name: "Never Attended"},
    {id: Auth.StudentState.TemporaryOut, name: "Temporary Out"},
    {id: Auth.StudentState.PermanentOut, name: "Permanent Out"}
]

export enum EnrollmentStatus {
    FullTime = "full_time",
    Hybrid = "hybrid",
    ThreeQuarterTime = "three_quarter_time",
    HalfTime = "half_time",
    LessThanHalfTime = "less_than_half_time"
}

export const ENROLLMENT_STATUS_OPTIONS = [
    {id: EnrollmentStatus.FullTime, name: "Full Time"},
    {id: EnrollmentStatus.Hybrid, name: "Hybrid"},
    {id: EnrollmentStatus.ThreeQuarterTime, name: "3/4 Time"},
    {id: EnrollmentStatus.HalfTime, name: "1/2 Time (Part Time)"},
    {id: EnrollmentStatus.LessThanHalfTime, name: "< 1/2 Time"}
]

export const ENROLLMENT_TYPE_OPTIONS = [
    {id: "new_student", name: "New Student"},
    {id: "new_student_graduate", name: "New Student: Graduate"},
    {id: "personal_enrichment_undergrad", name: "Personal Enrichment: Undergrad"},
    {id: "personal_enrichment_graduate", name: "Personal Enrichment: Graduate"},
    {id: "re_enroll_undergrad", name: "Re-Enroll: Undergrad"},
    {id: "re_enroll_graduate", name: "Re-Enroll: Graduate"},
    {id: "returning_student_graduate", name: "Returning Student - Graduate"},
    {id: "returning_student_reentry", name: "Returning Student - Reentry"},
    {id: "teach_certification", name: "Teach Certification"}
]

export const ENROLLMENT_CITIZENSHIP_STATUS_OPTIONS = [
    {id: "international_student", name: "International Student"},
    {id: "us_citizen", name: "US Citizen"},
    {id: "unknown", name: "Unknown"}
]

export enum DependencyStatus {
    Dependent = "dependent",
    Independent = "independent",
    DependentRejected = "dependentRejected",
    DependentWithDenial = "dependent_denial",
    IndependentRejected = "independentRejected",
    IndependentWithDependents = "independentWithDependents",
    IndependentWithoutDependents = "independentWithoutDependents"
}

export const DEPENDENCY_STATUS_OPTIONS = [
    {id: DependencyStatus.Dependent, name: translate("financialAid:financialAidPackage.dependencyStatuses.dependent")},
    {
        id: DependencyStatus.Independent,
        name: translate("financialAid:financialAidPackage.dependencyStatuses.independent")
    },
    {
        id: DependencyStatus.DependentRejected,
        name: translate("financialAid:financialAidPackage.dependencyStatuses.dependentRejected")
    },
    {
        id: DependencyStatus.IndependentRejected,
        name: translate("financialAid:financialAidPackage.dependencyStatuses.independentRejected")
    }
]

export const CITIZENSHIP_STATUS_OPTIONS = [
    {id: "us-citizen", name: "US Citizen"},
    {id: "resident-alien", name: "Resident Alien"},
    {id: "non-resident-alien", name: "Non-Resident Alien"},
    {id: "asylum", name: "Asylum"}
]

export const MILITARY_VETERAN_STATUS_OPTIONS = [
    {id: "dependent", name: "Dependent"},
    {id: "none", name: "None"},
    {id: "national-guard", name: "National Guard"},
    {id: "honorably-discharged", name: "Honorably Discharged"},
    {id: "not-a-veteran", name: "Not a Veteran"},
    {id: "other", name: "Other"},
    {id: "reservist", name: "Reservist"},
    {id: "retired", name: "Retired"},
    {id: "unknown", name: "Unknown"},
    {id: "veteran", name: "Veteran"},
    {id: "active-duty", name: "Active Duty"}
]

const preferredTimeOfContactOptions = [
    {id: "morning", name: "Morning"},
    {id: "noon", name: "Noon"},
    {id: "evening", name: "Evening"}
]

export const USER_TYPE_OPTIONS = [
    {id: Auth.UserProfileType.Staff, name: Auth.UserProfileType.Staff},
    {id: Auth.UserProfileType.Student, name: Auth.UserProfileType.Student},
    {id: Auth.UserProfileType.Others, name: Auth.UserProfileType.Others}
]
export const PREFERRED_TIME_OF_CONTACT_OPTIONS_KEY_BY_ID = keyBy(preferredTimeOfContactOptions, "id")

export type StudentStatus = {
    statusId: number
    name: string
    state: Auth.StudentState
    hexColor: string
    priority: number
    isSEV: boolean | 0 | 1
    isActive: boolean | 0 | 1
    isLocked: boolean | 0 | 1
    userProfilesCount?: number
}

export type AssignStudentStatus = {
    statusId: number
    studentProfileId: number
}

export const REASON_FOR_WITHDRAWAL_OPTIONS = [
    {id: Auth.ReasonForWithdrawal.Deceased, name: "Deceased"},
    {id: Auth.ReasonForWithdrawal.Disabled, name: "Temporarily or permanently disabled"},
    {id: Auth.ReasonForWithdrawal.MilitaryDuty, name: "Deployed for military service/duty"},
    {
        id: Auth.ReasonForWithdrawal.TransferredSameSchool,
        name: "Transferred between similar programs at this school or 100% of credits/hours were transferred to new program"
    },
    {
        id: Auth.ReasonForWithdrawal.TransferredOtherSchool,
        name: "Transferred to another school and accredited or remained in equivilant program"
    },
    {id: Auth.ReasonForWithdrawal.EarlyWithdrawal, name: "Early withdrawal"},
    {id: Auth.ReasonForWithdrawal.Covid19, name: "Dropped due to Covid 19"},
    {id: Auth.ReasonForWithdrawal.Expelled, name: "Expelled"},
    {id: Auth.ReasonForWithdrawal.Incarcerated, name: "Incarcerated"}
]

export type StudentRanking = {
    profileId: number
    firstName: string
    lastName: string
    photo?: string
    rank: number
}

export type StudentGpaRanking = StudentRanking & {
    gpa: number
}

export type StudentCgpaRanking = StudentRanking & {
    cgpa: number
}

export type StudentDaysOffRanking = StudentRanking & {
    daysOff: number
}

export type StudentLeaderboard = {
    cgpaLeaderboard: StudentCgpaRanking[]
    gpaLeaderboard: StudentGpaRanking[]
    daysOffLeaderboard: StudentDaysOffRanking[]
    attendancePercentageLeaderboard: StudentAttendancePercentageRanking[]
}

export type StudentAttendancePercentageRanking = StudentRanking & {
    totalAttendedHours: number
    attendancePercentage: number
    actualAttendancePercentage?: number
}

export type StudentAttendanceStreakRanking = StudentRanking & {
    attendanceStreak: number
}

export type AttendancePerformanceOfWeek = {
    profileId: number
    weekNumber: number
    totalAttendedHours: number
    totalMissedHours: number
    totalAvailableHours: number
}

export type AttendanceLeaderboard = {
    attendanceStreakLeaderboard: StudentAttendanceStreakRanking[]
    todayAttendance: {
        totalAttendedHours: number
        totalAvailableHours?: number
    }
    attendancePerformanceOfWeek?: AttendancePerformanceOfWeek
}

export type TotalHoursData = {
    totalHours: number
    totalHoursCompleted: number
    totalHoursRemaining: number
    totalHoursAttempted: number
    totalDistEdHours: number
    totalTransferHours: number
    hoursScheduled: number
    averageAttendancePercentage?: number
    missedAttendanceHours?: number
    makeUpHours?: number
    cgpa?: number
}

export enum TermEnrollStatus {
    New = "new",
    Continuing = "continuing",
    ReEntry = "re-entry"
}

export const TERM_ENROLL_STATUS_OPTIONS = [
    {id: TermEnrollStatus.New, name: "New"},
    {id: TermEnrollStatus.Continuing, name: "Continuing"},
    {id: TermEnrollStatus.ReEntry, name: "ReEntry"}
]

export enum TermBillingMethod {
    Default = "default",
    GENLV1 = "genlv1",
    GENLV2 = "genlv2",
    GENLV3 = "genlv3",
    GENLV4 = "genlv4",
    ALUMLV1 = "alumlv1",
    ALUMLV2 = "alumlv2",
    ALUMLV3 = "alumlv3",
    ALUMLV4 = "alumlv4",
    ALUMTUIT = "alumltuit"
}

export const TERM_BILLING_METHOD_OPTIONS = [
    {id: TermBillingMethod.Default, name: "Default"},
    {id: TermBillingMethod.GENLV1, name: "GENLV1"},
    {id: TermBillingMethod.GENLV2, name: "GENLV2"},
    {id: TermBillingMethod.GENLV3, name: "GENLV3"},
    {id: TermBillingMethod.GENLV4, name: "GENLV4"},
    {id: TermBillingMethod.ALUMLV1, name: "ALUMLV1"},
    {id: TermBillingMethod.ALUMLV2, name: "ALUMLV2"},
    {id: TermBillingMethod.ALUMLV3, name: "ALUMLV3"},
    {id: TermBillingMethod.ALUMLV4, name: "ALUMLV4"},
    {id: TermBillingMethod.ALUMTUIT, name: "ALUMTUIT"}
]

export const EVENT_STUDENT_STATUS_UPDATED = "EVENT_STUDENT_STATUS_UPDATED"
export const EVENT_FINANCIAL_AID_STATUS_UPDATED = "EVENT_FINANCIAL_AID_STATUS_UPDATED"

export const CAREER_SERVICE_STATES_OPTIONS = [
    {id: Auth.StudentState.Student, name: "Student"},
    {id: Auth.StudentState.Alumni, name: "Alumni"},
    {id: Auth.StudentState.PermanentOut, name: "Permanent Out"}
]
