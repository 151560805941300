import {Auth} from "types/auth"
import {Major} from "./major"

export namespace Course {
    export enum Unit {
        Hour = "hours",
        Each = "each"
    }

    export enum AttendanceTrackingType {
        ClassAttendance = "class_attendance",
        ClockHour = "clock_hour",
        Activity = "activity"
    }
    export enum ProgramAttendanceTrackingType {
        Course = "course",
        Daily = "daily"
    }

    export enum ClassroomAttendanceType {
        Attendance = "attendance",
        Activity = "activity"
    }

    export enum CourseType {
        Clock = "clock",
        Credit = "credit"
    }

    export enum CourseModuleType {
        TheoryOrClassroom = "theory_or_classroom",
        Lab = "lab",
        Test = "test",
        Others = "others"
    }

    export enum Status {
        Active = "active",
        Inactive = "inactive"
    }

    export type CourseCampus = {
        id: number
        name: string
        code: string
    }

    export type CourseDepartment = {
        departmentId: number
        name: string
        code: string
    }

    export type Submodule = {
        submoduleId: number | string
        courseId?: number
        moduleType: CourseModuleType
        title: string
        quantity: number
        units?: Unit
        createdAt?: string
        updatedAt?: string
        deletedAt?: string
    }

    export type UnitType = Partial<Unit | UnitValue>

    export type AttendanceType = Partial<AttendanceTrackingType | UnitValue>

    export enum AcademicPlanCourseRole {
        ClassAssistance = "ca",
        Student = "student"
    }
    export type CourseTableItem = {
        code: string
        id: number
        courseId: number
        totalStudentRegistered?: number
        attendedStudentsCount?: number
        totalStudentDropped?: number
        numberOfMakeupStudent?: number
        totalLessons?: number
        totalCompletedLessons?: number
        courseName: string
        role?: AcademicPlanCourseRole
        termCourseCode: string
        type?: Major.Unit
        timezone?: string
        projectedEndDate?: string
        attendanceTrackingType: Course.ProgramAttendanceTrackingType
        startDate?: string
        instructor: string
        gradingTemplateId?: number
        attendanceTracking: AttendanceType
        useActivityToTrackAttendance: boolean
        isAlert: boolean
        name: string
        studentsCount: number
        total: number
        campuses: string
        campusIds?: number[]
        status: Status
        instructors: string
        isExternshipCourse?: boolean
    }

    export type InstructionalCourse = {
        courseCode: string
        type: Major.Unit
        courseId: number
        courseName: string
        lastDayOfCourse: string
        firstDayOfCourse: string
        instructor_profile_id: number
        instructors: string
        numberOfMakeupStudent: number
        numberOfStudent: number
        scheduleId: number
        scheduleSuffix: string
        termCourseCode: string
        termId: number
        termName: string
        isExternshipCourse?: boolean
        timezone?: string
        name?: string
        gradingTemplateId?: number
    }

    export type Course = {
        courseId: number
        name: string
        description: string
        type: string
        code: string
        codeForTranscript: string
        nameForTranscript: string
        status: Status
        createdByProfileId: number
        updatedByProfileId: number
        gradingTemplateId?: number
        createdAt: string
        updatedAt: string
        deletedAt: string
        totalQuantity: number
        totalUnits: UnitType
        attendanceTracking:
            | {
                  id: AttendanceType
                  name: string
              }
            | AttendanceType
        numberOfDays: number
        minNumberOfDays: number
        theoryOrClassroomModuleTitle: string
        theoryOrClassroomModuleQuantity: number
        theoryOrClassroomModuleUnits: UnitType
        labModuleTitle: string
        labModuleQuantity: number
        labModuleUnits: UnitType
        testModuleTitle: string
        testModuleQuantity: number
        testModuleUnits: UnitType
        othersModuleTitle: string
        othersModuleQuantity: number
        othersModuleUnits: UnitType
        leadInstructorProfileId?: number
        distEdHours: number
        totalActualClockHours: number
        includeDistEdHours: boolean
        campusIds: number[]
        campuses: CourseCampus[]
        departmentId?: number
        department?: CourseDepartment
        submodules: Submodule[]
        leadInstructorProfile?: Auth.UpdatedByProfile
        instructorProfile?: Auth.UpdatedByProfile
        createdByProfile: Auth.UpdatedByProfile
        updatedByProfile: Auth.UpdatedByProfile
        prerequisiteCourseIds: any[]
        prerequisiteCourses: any[]
        corequisiteCourseIds: any[]
        corequisiteCourses: any[]
        crossList: any[]
        crossListIds: any[]
        numberOfRepeatsPermitted: number
        includeInGpaCalculation: boolean
        isPracticalOrClinicalServices: boolean
        allowStudentUploadMedia: boolean
        includeGrades: boolean
        useActivityToTrackAttendance: boolean
        selfStudy?: string
        prerequisites?: any[]
        corequisites?: any[]
        courseLevelId?: number
        courseLevel?: any
        lmsTemplateCode?: string
        isExternshipCourse?: boolean
    }

    export type UnitValue = {
        id: Unit
        name: string
    }

    export type CourseModule = {
        id: number
        title: string
        quantity: number
        units: UnitType
        submodules: Submodule[]
        type: CourseModuleType
    }

    export type EditSubmodule = {
        moduleType: CourseModuleType
        title: string
        quantity: number
        units: UnitType
    }

    export type GradingTemplate = {
        title: string
        id: number
    }

    export type EditCourseData = {
        code?: string
        name?: string
        gradingTemplate?: GradingTemplate
        gradingTemplateId?: number
        description?: string
        department?: CourseDepartment
        departmentId?: number
        leadInstructorProfileId?: number
        leadInstructorProfile?: Auth.UpdatedByProfile
        instructorProfileId?: number
        instructorProfile?: Auth.UpdatedByProfile
        type?: CourseModuleType
        status?: Status
        totalQuantity?: number
        faCreditHours?: number
        totalUnits?: UnitType
        theoryOrClassroomModuleTitle?: string
        theoryOrClassroomModuleQuantity?: number
        theoryOrClassroomModuleUnits?: UnitType
        labModuleTitle?: string
        labModuleQuantity?: number
        labModuleUnits?: UnitType
        testModuleTitle?: string
        testModuleQuantity?: number
        testModuleUnits?: UnitType
        othersModuleTitle?: string
        othersModuleQuantity?: number
        othersModuleUnits?: UnitType
        campusIds?: number[]
        campuses?: any[]
        courseLevelId?: number[]
        courseLevel?: any
        submodules?: EditSubmodule[]
        prerequisiteCourseIds?: number[]
        prerequisiteCourses?: any[]
        crossList?: any[]
        crossListIds?: any[]
        attendanceTracking?: AttendanceTrackingType
        numberOfDays?: number
        minNumberOfDays?: number
        numberOfRepeatsPermitted?: number
        includeInGpaCalculation?: boolean
        isExternshipCourse?: boolean
        isPracticalOrClinicalServices?: boolean
        allowStudentUploadMedia?: boolean
        includeGrades?: boolean
        includeDistEdHours?: boolean
        distEdHours?: number
        totalActualClockHours?: number
        useActivityToTrackAttendance?: boolean
        corequisiteCourseIds?: number[]
        corequisiteCourses?: any[]
        lmsTemplateCode?: string
    }
}

export type GetTableCoursesPayload = {
    filter?: Partial<{
        search: string
        campusIds: number[]
        status: Course.Status
        isPracticalOrClinicalServices?: boolean
    }>
    range?: {page: number; pageSize: number}
    sort?: {
        orderBy: keyof Course.Course
        orderDir: "asc" | "desc" | "ASC" | "DESC"
    }
}
