/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {Icon} from "components/Icon"
import {BaseButton} from "components/buttons"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {KlassappTableHOC} from "HOC"
import styles from "./FinAidWorksheet.module.css"
import {finAidService} from "services"
import {useModel} from "hooks"
import {Col, Row} from "antd"
import {routing} from "helpers/routing"
import {getFullName} from "helpers"
import {KlassappTableProps} from "types/common"
import {Auth} from "types/auth"

interface FinAidWorksheetProps extends KlassappTableProps {
    studentId: number
    departmentId: number
    awardYear: number
    student?: Auth.DepartmentStudent
}

function FinAidWorksheets(props: FinAidWorksheetProps) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        menuActions,
        isHideMenuActions,
        isShowTableHeaderAction,
        getCurrentData,
        getCurrentPage,
        getCurrentPageSize,
        studentId,
        awardYear
    } = props
    const history = useHistory()
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {t} = useTranslation(["financialAid", "common"])

    const getFields = () => {
        return [
            t("finAidWorksheet.column.code"),
            t("finAidWorksheet.column.awardYear"),
            t("finAidWorksheet.column.revision"),
            t("finAidWorksheet.column.createdBy"),
            t("finAidWorksheet.column.lastUpdated"),
            t("finAidWorksheet.column.status")
        ]
    }

    const getPageTitle = () => {
        return t("finAidWorksheet.tableTitle")
    }

    const getColumns = () => {
        return [
            {
                title: t("finAidWorksheet.column.code"),
                field: "code",
                style: {minWidth: "100px"}
            },
            {
                title: t("finAidWorksheet.column.awardYear"),
                field: "awardYear",
                style: {minWidth: "80px"},
                render: (value) => `${value} - ${value + 1}`
            },
            {
                title: t("finAidWorksheet.column.revision"),
                field: "revision",
                style: {minWidth: "80px"},
                render: (revision) =>
                    !revision ? t("financialAidPackage.original") : t("financialAidPackage.revision", {revision})
            },
            {
                title: t("finAidWorksheet.column.createdBy"),
                field: "createdAt",
                style: {minWidth: "200px"},
                render: (value, record) => (
                    <div className={styles.statusInfo}>
                        <div className={styles.statusInfo__text}>{getFullName(record.createdBy)}</div>
                        <div className={styles.statusInfo__text}>{moment(value).format(userDateTimeFormat)}</div>
                    </div>
                )
            },
            {
                title: t("finAidWorksheet.column.lastUpdated"),
                field: "updatedAt",
                style: {minWidth: "200px"},
                render: (value, record) => (
                    <div className={styles.statusInfo}>
                        <div className={styles.statusInfo__text}>{getFullName(record.updatedBy)}</div>
                        <div className={styles.statusInfo__text}>{moment(value).format(userDateTimeFormat)}</div>
                    </div>
                )
            },
            {
                title: t("finAidWorksheet.column.status"),
                field: "status",
                style: {minWidth: "80px"},
                render: (value) => (
                    <div className={cx(styles.status, value)}>{t(`finAidWorksheet.status.${value}`)}</div>
                )
            }
        ]
    }

    const getData = async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data} = await finAidService.getFinAidWorksheets({
            filter: {studentProfileId: [+studentId], awardYear},
            range: {
                page,
                pageSize
            },
            linkedObjects: true
        })
        dispatch({total, data: data.map((item) => ({...item, id: item.finAidWorksheetId})), isLoading: false})
    }

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            if (checkedData.length > 1) {
                return []
            }
            return [
                // {
                //     title: t("common:action.delete"),
                //     icon: "DELETE",
                //     action: () => onClickShowConfirmModal("DELETE"),
                // },
                {
                    title: t("finAidWorksheet.createRevision"),
                    icon: "DUPLICATE",
                    action: () => onClickCreateRevision()
                }
            ]
        },
        [t]
    )

    const getMenuActions = React.useCallback(() => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            }
            // {
            //     title: t("common:action.delete"),
            //     icon: "DELETE",
            //     action: () => onClickShowConfirmModal("DELETE"),
            // }
        ]
    }, [])

    const onClickRowItem = ({studentProfileId, finAidWorksheetId}) => {
        history.push(routing.finAid.student.worksheetDetail(studentProfileId, finAidWorksheetId))
    }

    const onClickAdd = () => {
        history.push(routing.finAid.student.worksheetDetail(studentId, "new") + `?awardYear=${awardYear}`)
    }

    const onClickDeleteMulti = () => {}

    const onClickDuplicateMulti = () => {}

    const onClickCreateRevision = React.useCallback(async () => {
        const data = getCurrentData()
        const [checkedItem] = data.filter((item) => item.isChecked)
        if (checkedItem) {
            await finAidService.createFinAidWorksheetRevision(checkedItem.finAidWorksheetId)
            await getData()
        }
    }, [])

    const onClickEdit = ({studentProfileId, finAidWorksheetId}) => {
        history.push(routing.finAid.student.worksheetDetail(studentProfileId, finAidWorksheetId))
    }

    const onClickDelete = async ({finAidWorksheetId}) => {
        await finAidService.deleteFinAidWorksheet(finAidWorksheetId)
        await getData()
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    const [, setSearch] = React.useState("")
    const [filter, setFilter] = React.useState<Record<string, any>>({})

    const onApplyFilter = () => {}

    const onClearFilter = () => {
        setFilter({})
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <PermissionsRequired
                        permissions={{staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Add]}}>
                        <div className={styles.actionWrap}>
                            <BaseButton
                                title="CREATE"
                                icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                onClick={onClickAdd}
                            />
                        </div>
                    </PermissionsRequired>
                )}>
                <Row gutter={[24, 24]}>
                    <Col span={12}></Col>
                    <Col span={12}></Col>
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(FinAidWorksheets)
