import React, {useRef, useState, useEffect} from "react"
import {Input, Tooltip} from "antd"
import cx from "classnames"
import styles from "./BaseInput.module.css"
import {Icon} from "components/Icon"
import {useTranslation} from "react-i18next"
import {preventMinusCharacter} from "helpers/inputHelper"

let timeout = null

type InputComponentsProps = {[key in string]: any}

export type BaseInputProps = InputComponentsProps & {
    title?: string
    value?: string | number
    defaultValue?: string | number
    className?: string
    placeholder?: string
    error?: boolean
    copy?: boolean
    hide?: boolean
    mask?: (str: string) => string
    icon?: JSX.Element
    borderless?: boolean
    readOnly?: boolean
    disabled?: boolean
    onKeyDown?: any
    onChange?: any
    prefix?: JSX.Element
    suffix?: JSX.Element
    min?: number | string
    max?: number | string
    maxLength?: number
    disabledArrow?: boolean
    type?:
        | "button"
        | "checkbox"
        | "color"
        | "date"
        | "datetime-local"
        | "email"
        | "file"
        | "hidden"
        | "image"
        | "month"
        | "number"
        | "password"
        | "radio"
        | "range"
        | "reset"
        | "search"
        | "submit"
        | "tel"
        | "text"
        | "time"
        | "url"
        | "week"
        | "credit-card"
}

export function BaseInput(props: BaseInputProps) {
    const inputRef = useRef(null)
    const {
        title,
        onChange,
        className,
        error,
        copy,
        borderless,
        onKeyDown,
        hide,
        disabledArrow,
        mask,
        icon,
        type,
        ...rest
    } = props
    const {t} = useTranslation(["user", "common"])
    const [textCopy, setTextCopy] = useState("Copy")
    const [textHide, setTextHide] = useState(t("common:message.show"))
    const [textType, setTextType] = useState("password")

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    const onChangeValue = React.useCallback(
        (e) => {
            const inputValue = e.target.value
            const numberRegex = /^-?\d*(\.\d*)?$/
            const isValidNumber =
                type === "number" && (numberRegex.test(inputValue) || inputValue === "" || inputValue === "-")
            if (type !== "number" || isValidNumber) {
                const text = mask ? mask(inputValue) : inputValue
                onChange && onChange(text)
            }
        },
        [type, onChange, mask]
    )

    const copyText = React.useCallback(() => {
        inputRef.current.select()
        document.execCommand("copy")
        setTextCopy("Copied to clipboard")
        inputRef.current.focus()
    }, [])

    const onVisibleChange = React.useCallback((visible) => {
        if (!visible) {
            timeout = setTimeout(() => {
                setTextCopy("Copy")
            }, 100)
        }
    }, [])

    const onHideChange = React.useCallback(() => {
        if (textType === "password") {
            setTextHide(t("common:message.hide"))
            setTextType("text")
        } else {
            setTextHide(t("common:message.show"))
            setTextType("password")
        }
    }, [t, textType])

    const onkeyDownValue = React.useCallback((event) => onKeyDown && onKeyDown(event), [onKeyDown])

    return (
        <Input
            ref={inputRef}
            className={cx(styles.baseInput, className, {
                error: error,
                [styles.borderless]: borderless,
                [styles.hideText]: textType === "password" && hide,
                [styles.disabledArrow]: !!disabledArrow
            })}
            type={type}
            onWheel={disabledArrow ? () => inputRef.current?.blur() : undefined}
            onChange={onChangeValue}
            onKeyDown={(event) => {
                props.type === "number" && Number(props.min) === 0
                    ? onkeyDownValue(preventMinusCharacter(event))
                    : onkeyDownValue(event)
            }}
            suffix={
                (icon && (
                    <span className={styles.suffixIconWrap} onClick={copyText}>
                        {icon}
                    </span>
                )) ||
                (copy && (
                    <Tooltip title={textCopy} onVisibleChange={onVisibleChange}>
                        <span className={styles.suffixIconWrap} onClick={copyText}>
                            <Icon icon="COPY" className={styles.suffixIcon} color="rgba(0,0,0,.45)" />
                        </span>
                    </Tooltip>
                )) ||
                (hide && (
                    <Tooltip title={textHide}>
                        <span className={styles.suffixIconWrap} onClick={onHideChange}>
                            <Icon icon="EYE" className={styles.suffixIcon} color="rgba(0,0,0,.45)" />
                        </span>
                    </Tooltip>
                ))
            }
            {...rest}
        />
    )
}
