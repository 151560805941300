import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"
import {userServiceV3} from "services"
import {Auth} from "types/auth"

const queryCacheKey = "autocomplete-user"

type AutocompleteUsersProps = {
    type?: Auth.UserProfileType[]
    search?: string
}

export const useAutocompleteUsers = ({type, search}: AutocompleteUsersProps) => {
    const query = useQuery(
        [queryCacheKey, type, search],
        async () => {
            const {data} = await userServiceV3.getAllAutocomplete({
                filter: {
                    type,
                    search,
                    belongsToMyCampuses: true,
                    withProfiles: true
                }
            })
            return data
        },
        {
            onError: (err) => {
                handleError(err)
            },
            initialData: []
        }
    )

    return {
        users: query.data,
        isLoading: query.isFetching
    }
}
