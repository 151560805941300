import {FAStatusBy} from "types/fin-aid/fin-aid"

export enum GeneralSettingField {
    SapGpaUseFACredits = "sap_gpa_use_fa_credits",
    SapGpaByPaymentPeriod = "sap_gpa_by_payment_period",
    SaPaymentCurrency = "sa_payment_currency",
    SaFiscalYearStart = "sa_fiscal_year_start",
    SaFiscalYearEnd = "sa_fiscal_year_end",
    SaAcademicYearStart = "sa_acad_year_start",
    SaAcademicYearEnd = "sa_acad_year_end",
    FaStatusBy = "fa_status_by",
    AcadActivityShowUnread = "acad_activity_show_unread",
    FaActivityShowUnread = "fa_activity_show_unread",
    AdmActivityShowUnread = "adm_activity_show_unread",
    SaActivityShowUnread = "sa_activity_show_unread",
    SsActivityShowUnread = "ss_activity_show_unread",
    ProgressReportSystemDefault = "progress_report_system_default",
    CalendarDisplayTermStartAndEndDates = "calendar_display_term_start_and_end_dates",
    ProgressReportSubmodulesBreakDown = "progress_report_submodules_break_down"
}

export type GeneralSetting = {
    field: GeneralSettingField
    value: string
    updatedAt: string
}

export const faStatusByOptions: Array<{id: FAStatusBy; name: string}> = [
    {id: FAStatusBy.AwardYear, name: "Award Year"},
    {id: FAStatusBy.Term, name: "Term"}
]
